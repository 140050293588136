<shared-subheader class="with-search-bar"></shared-subheader>
<div class="content-container">
    <app-account-list-filter
        [countries$]="countries$"
        [partnerStatuses$]="partnerStatuses$"
        (filterRequest)="onFilterRequest($event)"></app-account-list-filter>
    <div class="download-csv-link-wrapper">
        <span class="text-link" (click)="downloadCsv()">{{
            'account-list.downloadCsv' | translate
        }}</span>
    </div>
    <div
        class="account-list position-relative"
        ngxUiLoaderBlurred
        [loaderId]="'account-list-filter'">
        <ngx-ui-loader [loaderId]="'account-list-filter'"></ngx-ui-loader>
        <shared-table
            *ngIf="tableData$ | async as data"
            [data]="data"
            [columnDefs]="columnDefs"
            [emptyIcon]="emptyIcon"
            emptyText="account-list.noAccounts"
            [areRowsClickable]="true"
            (rowClick)="goToAccountDetails($event)"
            (matSortChange)="onSortChange($event)"
            [paginationLength]="totalElements"
            [pageIndex]="pageIndex"
            (page)="onPagingChange($event)"></shared-table>
    </div>
</div>
