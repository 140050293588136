import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AccountDTO, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { AccountService } from '../../../../services/account.service';
import { UserService } from '../../../../services/user.service';
import { UserRoleService } from '../../../../services/user-role.service';
import {
    AccountLogoDialogComponent,
    AccountLogoDialogData,
} from '../account-logo-dialog/account-logo-dialog.component';

@Component({
    selector: 'app-account-header',
    templateUrl: './account-header.component.html',
    styleUrls: ['./account-header.component.scss'],
})
export class AccountHeaderComponent implements OnInit {
    @Input() account: AccountDTO;

    accountLogoLoadError = false;
    rolesBelowDealerAdmin$: Observable<string[]>;

    constructor(
        private matDialog: MatDialog,
        private accountService: AccountService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private userRoleService: UserRoleService,
    ) {}

    ngOnInit(): void {
        this.rolesBelowDealerAdmin$ = this.userRoleService.getRolesBelowDealerAdmin$();
    }

    onImageLoadError() {
        this.accountLogoLoadError = true;
    }

    uploadLogo() {
        this.onImageLoadError();
        this.matDialog
            .open(AccountLogoDialogComponent, {
                width: '600px',
                data: {
                    accountNumber: this.account.accountNumber,
                } as AccountLogoDialogData,
            })
            .afterClosed()
            .pipe(
                filter((image: File) => Boolean(image)),
                switchMap((image: File) =>
                    this.accountService.putLogo(this.account.accountNumber, image),
                ),
            )
            .subscribe(
                () => {
                    setTimeout(() => (this.accountLogoLoadError = false), 3000);
                    const message = this.translateService.instant('account-logo.successMsg');
                    this.dialogService.openSnackbar(message, SnackbarType.SUCCESS, 5);
                },
                (error: HttpErrorResponse) => {
                    const message = this.translateService.instant('account-logo.errorMsg');
                    this.dialogService.openSnackbar(message, SnackbarType.ERROR, 7);
                },
            );
    }

    enableGhostMode() {
        this.accountService.enableGhostMode(
            `${this.account.name} ${this.account.name2}`,
            this.account.accountNumber,
        );
    }
}
