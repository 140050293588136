import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectOption } from '../components/user/user-details/user-details.component';
import { Role } from '../models/user/role';
import { UpdateUserRequest } from '../models/user/update-user-request';
import { formatUserPhoneNumbers, UserDTO } from '../models/user/user-dto';
import { CustomURLEncoder } from '../utils/custom-url-encoder';

@Injectable()
export class UserService {
    backendUrl = '/authentication/api/user';

    constructor(private http: HttpClient, private loadingSpinnerService: LoadingSpinnerService) {}

    getSupportedLanguages(): Observable<string[]> {
        return this.http.get<string[]>(`${this.backendUrl}/languages`);
    }

    getSupportedLanguagesByAccount(accountNumber: string): Observable<string[]> {
        const params = new HttpParams().set('account', accountNumber);
        return this.http.get<string[]>(`${this.backendUrl}/languages`, { params });
    }

    getLoggedInUserDetails(): Observable<UserDTO> {
        return this.http.get<UserDTO>(`${this.backendUrl}/details`);
    }

    getUserDetails(username: string): Observable<UserDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<UserDTO>(`${this.backendUrl}/${username}`),
        );
    }

    getContactDetails(contactId: string): Observable<UserDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<UserDTO>(`${this.backendUrl}/contact/${contactId}`),
        );
    }

    createUserFromContact(contactId: string, role: string): Observable<UserDTO> {
        const body = { role };
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<UserDTO>(`${this.backendUrl}/contact/${contactId}`, body),
        );
    }

    getAllowedRolesCreate(): Observable<Role[]> {
        return this.http
            .get<string[]>(`${this.backendUrl}/allowedRoles`)
            .pipe(map(roles => (!roles || roles.length === 0 ? [] : roles.map(r => Role[r]))));
    }

    getRolesBelow(role: string): Observable<string[]> {
        return this.http
            .get<string[]>(`${this.backendUrl}/rolesBelow/${role}`)
            .pipe(map(rolesBelow => rolesBelow.filter(roleBelow => roleBelow !== role)));
    }

    saveNewUser(source: UserDTO, withPartnernetLogin: boolean): Observable<UserDTO> {
        const params = new HttpParams().set('withPartnernetLogin', withPartnernetLogin);
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<UserDTO>(`${this.backendUrl}`, source, { params }),
        );
    }

    updateUser(username: string, request: UpdateUserRequest) {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.patch<UserDTO>(`${this.backendUrl}/${username}`, request),
        );
    }

    updateUserEmail(username: string, newEmail: string) {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<UserDTO>(`${this.backendUrl}/${username}/email`, { email: newEmail }),
        );
    }

    setEarlyAdopter(username: string, earlyAdopterValue: boolean): Observable<UserDTO> {
        const params = new HttpParams().append('enabled', earlyAdopterValue);
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<UserDTO>(`${this.backendUrl}/${username}/earlyAdopter`, null, {
                params,
            }),
        );
    }

    enablePartnernetAccess(username: string, request): Observable<UserDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<UserDTO>(`${this.backendUrl}/${username}/accessDetail`, request),
        );
    }

    getUsersOfAccount(accountNumber: string): Observable<UserDTO[]> {
        return this.loadingSpinnerService.withLoader(
            this.http
                .get<UserDTO[]>(`${this.backendUrl}/account/${accountNumber}`)
                .pipe(map(users => users.map(formatUserPhoneNumbers))),
            'user-list',
        );
    }

    changePassword(pw: string, newPw: string): Observable<void> {
        const params = {
            pw,
            newPw,
        };
        return this.http.post<void>(`${this.backendUrl}/password`, null, { params });
    }

    enableDisableUser(username: string, enabled: boolean): Observable<UserDTO> {
        const action = enabled ? 'enable' : 'disable';
        return this.http.patch<UserDTO>(`${this.backendUrl}/${action}/${username}`, null);
    }

    deleteUser(username: string): Observable<void> {
        return this.http.delete<void>(`${this.backendUrl}/${username}`);
    }

    deleteContactUser(contactId: string): Observable<void> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.delete<void>(`${this.backendUrl}/contact/${contactId}`),
        );
    }

    triggerEmailVerification(username: string): Observable<unknown> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<unknown>(`${this.backendUrl}/${username}/email-verification`, null),
        );
    }

    getCsv() {
        this.http
            .get(`${this.backendUrl}/csv`, { responseType: 'arraybuffer' })
            .subscribe(response => {
                const blob = new Blob([new Uint8Array(response)], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', 'user-id.csv');
                document.body.appendChild(link);
                link.click();
            });
    }

    getUserFunctions(): Observable<SelectOption[]> {
        return this.http.get<SelectOption[]>(`${this.backendUrl}/attributes/function`);
    }

    getDepartments(): Observable<SelectOption[]> {
        return this.http.get<SelectOption[]>(`${this.backendUrl}/attributes/department`);
    }

    getContactLanguages(): Observable<SelectOption[]> {
        return this.http.get<SelectOption[]>(`${this.backendUrl}/attributes/contactLanguage`);
    }

    checkEmail(email: string): Observable<unknown> {
        const params = new HttpParams({ encoder: new CustomURLEncoder() }).append('mail', email);
        return this.http.get<void>(`${this.backendUrl}/mail`, { params });
    }
}
