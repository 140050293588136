import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MyStobagBasketSharedModule } from '@stobag/mystobag-basket-shared';
import { MystobagHeaderModule } from '@stobag/mystobag-header';
import {
    AuthenticationService,
    CONFIG_SERVICE_CONFIGURATION,
    ConfigModule,
    ConfigService,
    ConfigServiceConfiguration,
    CustomDatePipe,
    GlobalErrorHandler,
    HttpInterceptorService,
    initialize,
    MystobagSharedModule,
} from '@stobag/mystobag-shared';
import { NgxMatIntlTelInputModule } from '@stobag/shared-mat-intl-tel-input';
import { CookieService } from 'ngx-cookie-service';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccountListComponent } from './components/account/account-list/account-list.component';
import { AccountListFilterComponent } from './components/account/account-list/account-list-filter/account-list-filter.component';
import { AccountHeaderComponent } from './components/account/company-settings/account-header/account-header.component';
import { AccountLogoDialogComponent } from './components/account/company-settings/account-logo-dialog/account-logo-dialog.component';
import { AccountStatusLabelComponent } from './components/account/company-settings/account-status-label/account-status-label.component';
import { AccountTabDescriptionComponent } from './components/account/company-settings/account-tab-description/account-tab-description.component';
import { CompanySettingsComponent } from './components/account/company-settings/company-settings.component';
import { DeliveryAddressComponent } from './components/account/company-settings/delivery-address/delivery-address.component';
import { DeliveryAddressConfirmationComponent } from './components/account/company-settings/delivery-address/delivery-address-confirmation/delivery-address-confirmation.component';
import { DeliveryAddressCreateComponent } from './components/account/company-settings/delivery-address/delivery-address-create/delivery-address-create.component';
import { DeliveryAddressDeleteComponent } from './components/account/company-settings/delivery-address/delivery-address-delete/delivery-address-delete.component';
import { DeliveryAddressEditComponent } from './components/account/company-settings/delivery-address/delivery-address-edit/delivery-address-edit.component';
import { DeliveryAddressListComponent } from './components/account/company-settings/delivery-address-list/delivery-address-list.component';
import { UserListComponent } from './components/account/company-settings/user-list/user-list.component';
import { VacationComponent } from './components/account/company-settings/vacation/vacation.component';
import { AddVacationDialogComponent } from './components/account/company-settings/vacation/vacation-list/add-vacation-dialog/add-vacation-dialog.component';
import { VacationCardComponent } from './components/account/company-settings/vacation/vacation-list/vacation-card/vacation-card.component';
import { VacationListComponent } from './components/account/company-settings/vacation/vacation-list/vacation-list.component';
import { VacationListStandaloneLayoutComponent } from './components/account/company-settings/vacation/vacation-list/vacation-list-standalone-layout.component';
import { AuthenticationLayoutComponent } from './components/authentication/authentication-layout.component';
import { ContentCardComponent } from './components/authentication/content-card/content-card.component';
import { EmailVerificationComponent } from './components/authentication/email-verification/email-verification.component';
import { LanguageSelectorComponent } from './components/authentication/login/language-selector/language-selector.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { VideoPlayerComponent } from './components/authentication/login/video-player/video-player.component';
import { PasswordChangeComponent } from './components/authentication/password-change/password-change.component';
import { PasswordChangeFormComponent } from './components/authentication/password-change-form/password-change-form.component';
import { PasswordResetComponent } from './components/authentication/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './components/authentication/password-reset-request/password-reset-request.component';
import { PasswordSentComponent } from './components/authentication/password-sent/password-sent.component';
import { PasswordSetComponent } from './components/authentication/password-set/password-set.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LogoComponent } from './components/logo/logo.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { AccessDetailsCardComponent } from './components/user/profile/access-details-card/access-details-card.component';
import { ChangeEmailDialogComponent } from './components/user/profile/login-data-card/change-email-dialog/change-email-dialog.component';
import { ChangePasswordDialogComponent } from './components/user/profile/login-data-card/change-password-dialog/change-password-dialog.component';
import { LoginDataCardComponent } from './components/user/profile/login-data-card/login-data-card.component';
import { NotificationSettingsCardComponent } from './components/user/profile/notification-settings-card/notification-settings-card.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { UserDeletionCardComponent } from './components/user/profile/user-deletion-card/user-deletion-card.component';
import { UserDeletionConfirmationDialogComponent } from './components/user/profile/user-deletion-card/user-deletion-confirmation-dialog/user-deletion-confirmation-dialog.component';
import { UserDetailsCardComponent } from './components/user/profile/user-details-card/user-details-card.component';
import { UserSettingsCardComponent } from './components/user/profile/user-settings-card/user-settings-card.component';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { UserRoleInfoDialogComponent } from './components/user/user-role-selector/user-role-info-hint/user-role-info-dialog/user-role-info-dialog.component';
import { UserRoleInfoHintComponent } from './components/user/user-role-selector/user-role-info-hint/user-role-info-hint.component';
import { UserRoleSelectorComponent } from './components/user/user-role-selector/user-role-selector.component';
import { DateHttpInterceptor } from './interceptors/date-http-interceptor';
import { MaterialModule } from './material.module';
import { AccountService } from './services/account.service';
import { ContactService } from './services/contact.service';
import { NotificationSettingsService } from './services/notification-settings.service';
import { PasswordResetService } from './services/password-reset.service';
import { UserService } from './services/user.service';
import { UserRoleService } from './services/user-role.service';
import { UserSettingsService } from './services/user-settings.service';
import { VerificationService } from './services/verification.service';
import { translateLoaderFactory } from './utils/translate-loader-factory';

@NgModule({
    declarations: [
        AppComponent,
        // Authentication
        AuthenticationLayoutComponent,
        LoginComponent,
        PasswordResetRequestComponent,
        PasswordResetComponent,
        VideoPlayerComponent,
        EmailVerificationComponent,
        LanguageSelectorComponent,
        ContentCardComponent,
        PasswordSentComponent,
        PasswordSetComponent,
        PasswordChangeComponent,
        PasswordChangeFormComponent,
        // Account
        CompanySettingsComponent,
        AccountListComponent,
        AccountListFilterComponent,
        UserListComponent,
        AccountLogoDialogComponent,
        VacationComponent,
        VacationListComponent,
        VacationCardComponent,
        VacationListStandaloneLayoutComponent,
        DeliveryAddressComponent,
        DeliveryAddressCreateComponent,
        DeliveryAddressEditComponent,
        DeliveryAddressConfirmationComponent,
        DeliveryAddressListComponent,
        DeliveryAddressDeleteComponent,
        AccountTabDescriptionComponent,
        // User
        ProfileComponent,
        ChangePasswordDialogComponent,
        UserDetailsCardComponent,
        UserSettingsCardComponent,
        LoginDataCardComponent,
        AddUserComponent,
        UserDetailsComponent,
        AccessDetailsCardComponent,
        UserDeletionCardComponent,
        UserDeletionConfirmationDialogComponent,
        ChangeEmailDialogComponent,
        // Forbidden
        ForbiddenComponent,
        NotificationSettingsCardComponent,
        AccountHeaderComponent,
        AddVacationDialogComponent,
        LogoComponent,
        UserRoleSelectorComponent,
        UserRoleInfoHintComponent,
        UserRoleInfoDialogComponent,
        AccountStatusLabelComponent,
    ],
    imports: [
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, ConfigService],
            },
        }),
        MystobagHeaderModule,
        MystobagSharedModule,
        MyStobagBasketSharedModule,
        ConfigModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        ScrollingModule,
        NgxUiLoaderModule,
        NgxPermissionsModule.forRoot(),
        NgxUiLoaderRouterModule.forRoot({
            excludeRegexp: ['/account$'],
        }),
        NgxDropzoneModule,
        NgxMatIntlTelInputModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AuthenticationService,
        AccountService,
        UserService,
        UserSettingsService,
        UserRoleService,
        PasswordResetService,
        CookieService,
        VerificationService,
        ContactService,
        CustomDatePipe,
        NotificationSettingsService,

        // http interceptor
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initialize,
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: CONFIG_SERVICE_CONFIGURATION,
            useFactory: () => {
                return {
                    serviceName: environment.serviceName,
                    verifyToken: false,
                } as ConfigServiceConfiguration;
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
