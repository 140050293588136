<app-content-card>
    <div class="content-block" fxLayout="column">
        <h1>{{ 'passwordSent.title' | translate }}</h1>
        <p>{{ 'passwordSent.description' | translate }}</p>
        <div fxLayout="row" fxLayoutAlign="start center" fxFill>
            <shared-icon
                matPrefix
                class="icon-prefix-padding"
                [fontSet]="'material-icons-outlined'"
                icon="user"></shared-icon>
            <span data-cy="codeSentSuccessLabel">{{ username$ | async }}</span>
        </div>
        <div fxFlex="auto">
            <div class="textlink" (click)="cancel()">{{ 'backToLogin' | translate }}</div>
        </div>
        <div class="secondary-text">{{ 'passwordSent.noEmailInformation' | translate }}</div>
    </div>
</app-content-card>
