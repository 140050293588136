<mat-card loaderId="partnernet-access-loader">
    <ngx-ui-loader loaderId="partnernet-access-loader"></ngx-ui-loader>
    <h3 fxLayout="row" fxLayoutAlign="start center">
        {{ 'profile.partnerNetAccess' | translate }}
    </h3>
    <form [formGroup]="formGroup" class="form">
        <ng-container *ngIf="!isLoggedInAccount && _user.userSource === UserSource.Contact">
            <div class="info">{{ 'profile.partnerNetAccessInfo' | translate }}</div>
            <mat-slide-toggle
                color="primary"
                class="activate-slider"
                formControlName="partnerNetAccess"
                [disabled]="!uniqueContactFound">
                {{ 'profile.activatePartnerNetAccess' | translate }}
            </mat-slide-toggle>
            <mat-error
                *ngIf="uniqueContactFound !== undefined && !uniqueContactFound && _user.email">
                {{ 'addUser.multipleContactsError' | translate }}
            </mat-error>
            <mat-error *ngIf="!_user.email">
                {{ 'addUser.setAnEmail' | translate }}
            </mat-error>
        </ng-container>
        <ng-container *ngIf="formGroup.get('partnerNetAccess').value">
            <div class="form-table">
                <mat-form-field id="dealerAccount">
                    <mat-label>{{ 'profile.account' | translate }}</mat-label>
                    <input matInput type="text" formControlName="dealerAccount" />
                </mat-form-field>
                <app-user-role-selector
                    [roleControl]="$any(formGroup.controls['role'])"
                    [allowedRoles]="allowedRoles"></app-user-role-selector>
                <mat-form-field id="preferredLanguage">
                    <mat-label>{{ 'profile.locale' | translate }}</mat-label>
                    <mat-select
                        panelClass="drop-down-filters"
                        disableOptionCentering
                        formControlName="locale">
                        <mat-option
                            *ngFor="let language of supportedLanguages$ | async"
                            [value]="language"
                            >{{ 'language.' + language | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.get('locale').hasError('required')">
                        {{ 'shared.stateChangeMessages.requiredError' | translate }}
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="!isUserReadOnly">
                    <mat-slide-toggle
                        class="early-adopter-toggle"
                        *ngxPermissionsOnly="['ROLE_STOBAG_ADMIN', 'ROLE_STOBAG_SUPERADMIN']"
                        featureHighlight
                        color="primary"
                        id="active"
                        formControlName="earlyAdopter"
                        (click)="enableDisableEarlyAdopter()">
                        {{ 'account-list.enableDisableEarlyAdopter' | translate }}
                    </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="formGroup.get('bDealerDeliveryAddress')?.value">
                    <mat-form-field id="deliveryAddress">
                        <mat-label>{{ 'profile.deliveryAddress' | translate }}</mat-label>
                        <input matInput type="text" formControlName="bDealerDeliveryAddress" />
                    </mat-form-field>
                </ng-container>
            </div>
            <div
                class="padded-buttons"
                fxLayout="row"
                fxLayoutAlign="flex-end"
                *ngxPermissionsOnly="canEdit">
                <button
                    *ngIf="!isUserReadOnly"
                    mat-flat-button
                    type="button"
                    class="primary-button"
                    (click)="save()">
                    {{
                        (_user.userSource === UserSource.Contact
                            ? 'profile.activateAccess'
                            : 'profile.save'
                        ) | translate
                    }}
                </button>
            </div>
        </ng-container>
    </form>
</mat-card>
