<ng-container *ngIf="userServiceAvailable">
    <app-account-tab-description>
        <span>{{ 'user-list.description' | translate }}</span>
        <button
            *ngxPermissionsOnly="stobagOrDealerAdminPermissions"
            mat-flat-button
            id="createUserForAccount"
            class="primary-button create-user-button"
            color="primary"
            (click)="navigateToCreateUser()">
            <shared-icon
                class="create-user-icon"
                icon="plus"></shared-icon>
            {{ 'account.createUser' | translate }}
        </button>
    </app-account-tab-description>
    <div class="position-relative table-loader" ngxUiLoaderBlurred loaderId="user-list">
        <ngx-ui-loader loaderId="user-list"></ngx-ui-loader>
        <ng-container *ngIf="users$ | async as users">
            <shared-table
                *ngIf="areRowsClickable$ | async; else readOnlyTable"
                class="table"
                [data]="users"
                [columnDefs]="columnDefs$ | async"
                [areRowsClickable]="true"
                [emptyIcon]="emptyIcon"
                emptyText="user-list.noUsers"
                (rowClick)="navigateToUser($event)"></shared-table>
            <ng-template #readOnlyTable>
                <shared-table
                    class="table"
                    [data]="users"
                    [columnDefs]="columnDefs$ | async"
                    [areRowsClickable]="false"
                    [emptyIcon]="emptyIcon"
                    emptyText="user-list.noUsers"></shared-table>
            </ng-template>
        </ng-container>
    </div>
</ng-container>
<div id="userServiceUnavailable" *ngIf="!userServiceAvailable">
    {{ userServiceError | translate }}
    {{ 'user-list.userServiceUnavailable' | translate }}
</div>
