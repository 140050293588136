<shared-dialog-frame title="account-logo.uploadButtonTitle">
    <div class="account-logo-container">
        <p class="logo-explanation-text">
            {{ 'account-logo.logoUse' | translate }}
        </p>
        <div class="account-logo">
            <img
                *ngIf="logoExists && accountLogoUrl"
                [src]="accountLogoUrl"
                (error)="logoExists = false" />
        </div>
        <p *ngIf="!logoExists || !accountLogoUrl" class="logo-explanation-text">
            {{ 'account-logo.pleaseUploadLogo' | translate }}<br />
            {{ 'account-logo.needSupport' | translate }}
        </p>
    </div>
    <div
        class="upload-logo-container"
        fxLayout="column"
        fxLayoutAlign="center center"
        [accept]="acceptedFileTypes"
        (change)="onSelect($event)"
        ngx-dropzone
        #drop>
        <ngx-dropzone-label>
            <shared-icon
                class="upload-icon"
                icon="camera"></shared-icon>
            <p>
                {{ 'account-logo.optimalDimension' | translate
                }}{{ ': ' + optimalWidth + ' x ' + optimalHeight + ' ' }}
                {{ 'account-logo.pixels' | translate }}
                <br />
                {{ 'account-logo.supportedFormats' | translate }}
            </p>
            <button
                mat-stroked-button
                id="uploadBtn"
                class="secondary-button"
                (click)="onClick($event)">
                {{ 'account-logo.uploadButtonTitle' | translate }}
            </button>
        </ngx-dropzone-label>
    </div>
</shared-dialog-frame>
