import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { SetNewPasswordRequest } from '../models/authentication/set-new-password-request';

export interface PasswordResetRequest {
    username: string;
}

export interface PasswordResetResponse {
    username: string;
}

@Injectable()
export class PasswordResetService {
    backendUrl = '/authentication/forgotten-password';

    constructor(private http: HttpClient, private loadingSpinnerService: LoadingSpinnerService) {}

    requestConfirmationCode(request: PasswordResetRequest): Observable<PasswordResetResponse> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<PasswordResetResponse>(`${this.backendUrl}`, request),
        );
    }

    setNewPassword(request: SetNewPasswordRequest): Observable<void> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<void>(`${this.backendUrl}/new-password`, request),
        );
    }
}
