import { AccountDTO, Permission } from '@stobag/mystobag-shared';
import { parsePhoneNumber } from 'libphonenumber-js';

import { UserSource } from '../../enums/user-source';

export interface UserDTO {
    titleCode: string;
    username: string;
    name: string;
    fullName: string;
    email: string;
    role: Permission;
    locale: string;
    accountNumber: string;
    parentAccountNumber: string;
    lastLogin: Date;
    userCreateDate: Date;
    userLastModifiedDate: Date;
    enabled: boolean;
    userStatus: string;
    account: AccountDTO;
    earlyAdopter: boolean;
    emailVerified: boolean;
    firstName: string;
    lastName: string;
    phone: string;
    mobile: string;
    contactLanguage: string;
    functionCode: string;
    departmentCode: string;
    contactObjectId: string;
    userSource: UserSource;
}

export function formatUserPhoneNumbers(user: UserDTO): UserDTO {
    return {
        ...user,
        phone: parseAndFormatPhoneNumber(user.phone),
        mobile: parseAndFormatPhoneNumber(user.mobile),
    };
}

function parseAndFormatPhoneNumber(phoneNumber: string | undefined): string {
    return phoneNumber ? parsePhoneNumber(phoneNumber).formatInternational() : '';
}
