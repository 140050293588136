import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Subscription } from 'rxjs';

import { SetNewPasswordRequest } from '../../../models/authentication/set-new-password-request';
import { PasswordResetService } from '../../../services/password-reset.service';
import { passwordMatchValidator, regexValidator } from '../../../utils/form-validators';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    username: string;
    confirmationCode: string;
    passwordResetForm: FormGroup;

    private subscription = new Subscription();

    constructor(
        activatedRoute: ActivatedRoute,
        private passwordResetService: PasswordResetService,
        private router: Router,
        private translate: TranslateService,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {
        this.username = activatedRoute.snapshot.paramMap.get('username');
        this.confirmationCode = activatedRoute.snapshot.paramMap.get('confirmationCode');
    }

    ngOnInit() {
        this.passwordResetForm = new FormGroup(
            {
                username: new FormControl(
                    { value: this.username, disabled: true },
                    Validators.required,
                ),
                confirmationCode: new FormControl(
                    { value: this.confirmationCode, disabled: true },
                    Validators.required,
                ),
                newPassword: new FormControl('', [
                    Validators.minLength(8),
                    regexValidator('\\d', 'NUMBER'),
                    regexValidator('[A-Z]', 'CAPITAL_LETTER'),
                    regexValidator('[a-z]', 'SMALL_LETTER'),
                    regexValidator('[~!@#$%^&*()_\\-\\=/.,?><+\\[\\]{}\\\\]', 'SPECIAL_CHARACTER'),
                    Validators.required,
                ]),
                newPasswordAgain: new FormControl('', Validators.required),
            },
            {
                validators: passwordMatchValidator,
            },
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setNewPassword(newPassword: string): void {
        if (!newPassword) {
            return;
        }

        const request = new SetNewPasswordRequest(
            this.username,
            this.confirmationCode,
            newPassword,
        );
        this.subscription.add(
            this.passwordResetService.setNewPassword(request).subscribe(
                () => this.router.navigateByUrl('password-set'),
                (error: HttpErrorResponse) => this.handleError(error),
            ),
        );
    }

    private handleError(error: HttpErrorResponse): void {
        const snackBarMessage: string =
            error.error === 'EXPIRED_CODE'
                ? this.translate.instant('passwordReset.expiredCodeError')
                : this.translate.instant('passwordReset.unknownError');
        this.dialogService.openSnackbar(snackBarMessage, SnackbarType.ERROR, 7);
    }

    cancel(): void {
        this.router.navigateByUrl('login');
    }

    hasError(error: string): boolean {
        if (this.passwordResetForm.controls.newPassword.value) {
            return this.passwordResetForm.controls.newPassword.hasError(error) as boolean;
        }
        return true;
    }
}
