import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthenticationService,
    DialogService,
    LoadingSpinnerService,
    SnackbarType,
} from '@stobag/mystobag-shared';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { UserDTO } from '../../../../models/user/user-dto';
import { UserService } from '../../../../services/user.service';
import { UserDeletionConfirmationDialogComponent } from './user-deletion-confirmation-dialog/user-deletion-confirmation-dialog.component';

@Component({
    selector: 'app-user-deletion-card',
    templateUrl: './user-deletion-card.component.html',
    styleUrls: ['./user-deletion-card.component.scss'],
})
export class UserDeletionCardComponent implements OnInit {
    isNotOwnProfile = false;
    isContactUser = false;
    _user: UserDTO;

    @Input() set user(user: UserDTO) {
        if (user) {
            this._user = user;
            this.isNotOwnProfile = this.authService.getUserId() !== user.username;
        }
    }

    constructor(
        private dialog: MatDialog,
        private dialogService: DialogService,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {}

    ngOnInit() {
        this.isContactUser = Boolean(this.activatedRoute.snapshot.paramMap.get('contactId'));
    }

    deleteUser() {
        this.dialog
            .open(UserDeletionConfirmationDialogComponent)
            .afterClosed()
            .pipe(
                filter(response => response === true),
                switchMap(() =>
                    this.isContactUser
                        ? this.deleteContactUser(this._user.contactObjectId)
                        : this.deleteUserByUsername(this._user.username),
                ),
                catchError((error: unknown) => {
                    this.openErrorSnackbar();
                    throw error;
                }),
            )
            .subscribe(() => {
                this.openSuccessSnackbar();
                this.router.navigate([
                    `/account/${this._user.parentAccountNumber ?? this._user.accountNumber}`,
                ]);
            });
    }

    private deleteUserByUsername(username: string) {
        return this.loadingSpinnerService.withMasterLoader(this.userService.deleteUser(username));
    }

    private deleteContactUser(contactObjectId: string) {
        return this.userService.deleteContactUser(contactObjectId);
    }

    private openSuccessSnackbar() {
        const msg = this.translate.instant('user-deletion.deleteSuccess');
        this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
    }

    private openErrorSnackbar() {
        const msg = this.translate.instant('user-deletion.deleteError');
        this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
    }
}
