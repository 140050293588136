import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DeliveryAddressDTO } from '../../../../../models/account/delivery-address-dto';
import { AccountService } from '../../../../../services/account.service';

@Component({
    selector: 'app-delivery-address-delete',
    templateUrl: './delivery-address-delete.component.html',
    styleUrls: ['./delivery-address-delete.component.scss'],
})
export class DeliveryAddressDeleteComponent implements OnInit {
    deliveryAddress$: Observable<DeliveryAddressDTO>;

    private accountNumber: string;
    private subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private router: Router,
        private location: Location,
        private dialogService: DialogService,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    ngOnInit() {
        this.accountNumber = this.activatedRoute.parent.snapshot.paramMap.get('accountNumber');
        this.deliveryAddress$ = this.activatedRoute.params.pipe(
            map(({ shipToPartyId }) => shipToPartyId),
            switchMap(shipToPartyId =>
                this.accountService.getOneDeliveryAddress(this.accountNumber, shipToPartyId),
            ),
        );
        this.subheaderService.showBackButton(
            'shared.companySettings',
            `/account/${this.accountNumber}/delivery-address-list`,
            false,
        );
    }

    requestDeliveryAddressDelete(shipToPartyId: string): void {
        this.subscription.add(
            this.accountService.deleteDeliveryAddress(this.accountNumber, shipToPartyId).subscribe(
                () => this.navigateToConfirmationPage(),
                (error: HttpErrorResponse) =>
                    this.dialogService.openSnackbar(error.message, SnackbarType.ERROR, 7),
            ),
        );
    }

    navigateBack(): void {
        this.location.back();
    }

    private navigateToConfirmationPage() {
        this.router.navigate([
            `/account/${this.accountNumber}/delivery-address/delete-confirmation`,
        ]);
    }
}
