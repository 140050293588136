<shared-dialog-frame title="user-deletion.confirmationTitle">
    <div class="action-buttons" #actions (keydown.enter)="close()">
        <button class="secondary-button" mat-dialog-close mat-stroked-button>
            {{ 'user-deletion.cancel' | translate }}
        </button>
        <button
            class="primary-button"
            [mat-dialog-close]="true"
            cdkFocusInitial
            color="primary"
            mat-flat-button
            data-cy="confirm-delete-button">
            {{ 'user-deletion.delete' | translate }}
        </button>
    </div>
</shared-dialog-frame>
