import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap } from 'rxjs/operators';

import { DeliveryAddressDTO } from '../../../../../models/account/delivery-address-dto';
import { VacationDTO } from '../../../../../models/account/vacation-dto';
import { AccountService } from '../../../../../services/account.service';
import { AddVacationDialogComponent } from './add-vacation-dialog/add-vacation-dialog.component';

export interface VacationWithAffectedAddresses extends VacationDTO {
    affectedAddresses: string[];
}

@Component({
    selector: 'app-vacation-list',
    templateUrl: './vacation-list.component.html',
    styleUrls: ['./vacation-list.component.scss'],
})
export class VacationListComponent implements OnInit {
    @Input() addEvent$: Observable<void>;

    vacations$: Observable<VacationWithAffectedAddresses[]>;
    deliveryAddresses: DeliveryAddressDTO[];
    loadError = false;

    private accountNumber: string;
    private vacationListRefresh = new Subject<void>();
    private subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private accountService: AccountService,
        private matDialog: MatDialog,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.accountNumber = this.route.parent.snapshot.paramMap.get('accountNumber');
        if (this.addEvent$) {
            this.subscription.add(this.addEvent$.subscribe(() => this.onAddVacation()));
        }
        this.vacations$ = this.vacationListRefresh.asObservable().pipe(
            switchMap(() => this.accountService.getVacationsOfAccount(this.accountNumber)),
            map((vacations: VacationDTO[]) =>
                vacations
                    .sort(
                        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
                    )
                    .reduce<VacationWithAffectedAddresses[]>((prev, curr) => {
                        const dAddr = this.deliveryAddresses.find(
                            da => da.shipToPartyId === curr.customerId,
                        );
                        const affectedAddress = `${dAddr.name}, ${dAddr.address.street} ${dAddr.address.houseNumber}, ${dAddr.address.postalCode} ${dAddr.address.city}`;
                        const vacationWithAffectedAddresses = prev.find(
                            vacation =>
                                vacation.endDate === curr.endDate &&
                                vacation.startDate === curr.startDate,
                        );
                        if (vacationWithAffectedAddresses) {
                            vacationWithAffectedAddresses.affectedAddresses.push(affectedAddress);
                        } else {
                            prev.push({ ...curr, affectedAddresses: [affectedAddress] });
                        }
                        return prev;
                    }, []),
            ),
            catchError((error: unknown) => {
                this.loadError = true;
                return throwError(error);
            }),
            shareReplay(1),
        );
        this.subscription.add(
            this.accountService
                .getDeliveryAddresses(this.accountNumber)
                .subscribe(deliveryAddresses => {
                    this.deliveryAddresses = deliveryAddresses;
                    this.vacationListRefresh.next();
                }),
        );
    }

    onDeleteVacation(vacation: VacationWithAffectedAddresses) {
        const message = this.translateService.instant('vacation-list.removeVacationConfirmation');
        this.subscription.add(
            this.dialogService
                .openConfirmationDialog(message)
                .afterClosed()
                .pipe(
                    filter(confirmation => Boolean(confirmation)),
                    switchMap(() =>
                        this.accountService.removeVacation(this.accountNumber, vacation),
                    ),
                )
                .subscribe(
                    () => {
                        this.openSuccessSnackbar();
                        this.vacationListRefresh.next();
                    },
                    () => {
                        this.openErrorSnackbar();
                    },
                ),
        );
    }

    openSuccessSnackbar() {
        const msg = this.translateService.instant('vacation-list.updatedSuccessfully');
        this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
    }

    openErrorSnackbar() {
        const msg = this.translateService.instant('vacation-list.failure');
        this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
    }

    private onAddVacation(): void {
        this.subscription.add(
            this.matDialog
                .open(AddVacationDialogComponent, {
                    data: { accountNumber: this.accountNumber, vacations$: this.vacations$ },
                })
                .afterClosed()
                .pipe(
                    filter(vacations => Boolean(vacations)),
                    switchMap(vacations =>
                        this.accountService.addVacations(this.accountNumber, vacations),
                    ),
                )
                .subscribe({
                    next: () => {
                        this.openSuccessSnackbar();
                        this.vacationListRefresh.next();
                    },
                    error: () => {
                        this.openErrorSnackbar();
                    },
                }),
        );
    }
}
