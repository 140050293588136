import { Component, Input, OnDestroy,OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    DialogService,
    LoadingSpinnerService,
    Permission,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, combineLatest, from, Observable, of, shareReplay, Subject, Subscription, switchMap, take } from 'rxjs';

import { UserDTO } from '../../../../models/user/user-dto';
import { Settings } from '../../../../models/user-settings/settings';
import { UserSettingsDto } from '../../../../models/user-settings/user-settings-dto';
import { UserSettingsService } from '../../../../services/user-settings.service';

@Component({
    selector: 'app-user-settings-card',
    templateUrl: './user-settings-card.component.html',
    styleUrls: ['../notification-settings-card/notification-settings-card.component.scss'],
})
export class UserSettingsCardComponent implements OnInit, OnDestroy {

    @Input() user: UserDTO;
    @Input() isUserReadOnly: boolean;

    canEdit = false;
    userSettingsForm: FormGroup;
    subscription = new Subscription();
    private settings: Settings = { showKpiDashboard: true }
    private requiredPermissions = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];

    constructor(
        private userSettingsService: UserSettingsService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private permissionService: NgxPermissionsService,
        private loadingSpinnerService: LoadingSpinnerService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.subscription.add(
            combineLatest([this.validatePermissions(), this.getUserSettings()])
            .subscribe(([canEdit, userSettings]) => {
                this.canEdit = canEdit;
                this.settings = userSettings;

                this.userSettingsForm = this.formBuilder.group({
                    showKpiDashboard: new FormControl({
                        value: this.settings.showKpiDashboard,
                        disabled: !this.canEdit
                    })
                });                
            }),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private validatePermissions(): Observable<boolean> {
        return from(this.permissionService.hasPermission(this.requiredPermissions));
    }

    private getUserSettings(): Observable<UserSettingsDto> {
        return this.loadingSpinnerService.withLoader(
            this.userSettingsService.get(this.user.email).pipe(
                catchError((error: unknown) => {
                    console.error('An error occurred: ', error);
                    return of({});
                })
            ),
            'user-settigs-card-loader');    
    }

    saveSettings() {
        this.userSettingsService.update(
            this.user.email, 
            this.userSettingsForm.getRawValue())
        .subscribe({
            next: () => {
                const msg = this.translate.instant('profile.notificationSettings.updatedSuccessfully');
                this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
            },
            error: (error: unknown) => {
                const msg = this.translate.instant('profile.notificationSettings.updateError');
                this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                console.error('An error occurred: ', error);
            },
        })
    }
}
