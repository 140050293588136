<div mat-dialog-title class="dialog-title">
    <h2>{{ 'profile.userRoleDescription' | translate }}</h2>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <shared-icon icon="x"></shared-icon>
    </button>
</div>
<div mat-dialog-content class="dialog-content">
    <span>{{ 'profile.partnernetAccessHint' | translate }}</span>
    <h3>{{ 'profile.roleType.administrator' | translate }}</h3>
    <span class="role-text">{{ 'profile.roleHint.administrator' | translate }}</span>
    <h3>{{ 'profile.roleType.employee' | translate }}</h3>
    <span class="role-text">{{ 'profile.roleHint.employee' | translate }}</span>
    <ng-container *ngIf="salesRoleEnabled">
        <h3>{{ 'profile.roleType.sales' | translate }}</h3>
        <span class="role-text">{{ 'profile.roleHint.sales' | translate }}</span>
    </ng-container>
    <h3>{{ 'profile.roleType.assembler' | translate }}</h3>
    <span class="role-text">{{ 'profile.roleHint.assembler' | translate }}</span>
    <ng-container *ngIf="bDealerEnabled">
        <h3>{{ 'profile.roleType.bdealer' | translate }}</h3>
        <span class="role-text">{{ 'profile.roleHint.bdealer' | translate }}</span>
    </ng-container>
</div>
