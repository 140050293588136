// The original code from https://gist.github.com/martinobordin/39bb1fe3400a29c1078dec00ff76bba9
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DateHttpInterceptor implements HttpInterceptor {
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<unknown>) => {
                    if (event instanceof HttpResponse) {
                        const requestBody = event.body;
                        this.convertToDate(requestBody);
                    }
                },
                (error: unknown) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            // do nothing
                        }
                    }
                },
            ),
        );
    }

    convertToDate(requestBody) {
        if (requestBody === null || requestBody === undefined) {
            return requestBody;
        }

        if (typeof requestBody !== 'object') {
            return requestBody;
        }

        for (const key of Object.keys(requestBody)) {
            const value = requestBody[key];
            if (this.isIso8601(value)) {
                requestBody[key] = new Date(value);
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }
}
