import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { ConfigService, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { forkJoin, Subscription } from 'rxjs';
import { CreateDeliveryAddressRequest } from 'src/app/models/account/create-delivery-address';
import { AccountService } from 'src/app/services/account.service';

import { DeliveryType } from '../delivery-address.component';

@Component({
    selector: 'app-delivery-address-create',
    templateUrl: './delivery-address-create.component.html',
    styleUrls: ['./delivery-address-create.component.scss'],
})
export class DeliveryAddressCreateComponent implements OnInit, OnDestroy {
    DeliveryType = DeliveryType;

    formGroup: FormGroup;
    accountNumber: string;

    private subscription = new Subscription();

    constructor(
        private myStobagSubheaderService: MyStobagSubheaderService,
        private activatedRoute: ActivatedRoute,
        private translationService: TranslateService,
        private router: Router,
        private accountService: AccountService,
        private dialogService: DialogService,
        private configService: ConfigService,
    ) {}

    ngOnInit() {
        this.accountNumber = this.activatedRoute.parent.snapshot.paramMap.get('accountNumber');
        this.formGroup = new FormGroup({
            accountNumber: new FormControl({ value: this.accountNumber, disabled: true }),
            companyName: new FormControl(null, [Validators.required]),
            streetAndNumber: new FormControl(null, [Validators.required]),
            postalCodeAndCity: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            type: new FormControl(null, [Validators.required]),
            comment: new FormControl(),
        });
        if (this.configService.getConfig().bDealerEnabled === true) {
            this.formGroup.addControl(
                'specialShipToPartyType',
                new FormControl(null, [Validators.required]),
            );
        }
        this.subscription.add(
            forkJoin({
                pageName: this.translationService.get('delivery-address.createTitle'),
                pageDescription: this.translationService.get('delivery-address.createDescription'),
            }).subscribe(({ pageName, pageDescription }) => {
                this.myStobagSubheaderService.updatePageName(pageName, null, null, pageDescription);
            }),
        );
        this.myStobagSubheaderService.showBackButton(
            'shared.companySettings',
            `/account/${this.accountNumber}/delivery-address-list`,
            false,
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancel() {
        this.router.navigateByUrl(`account/${this.accountNumber}/delivery-address-list`);
    }

    save() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
            return;
        }

        const values = this.formGroup.getRawValue() as CreateDeliveryAddressRequest;
        this.subscription.add(
            this.accountService.createDeliveryAddress(this.accountNumber, values).subscribe({
                next: () => this.navigateToConfirmationPage(),
                error: () => {
                    const msg = this.translationService.instant('delivery-address.creationError');
                    this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                },
            }),
        );
    }

    private navigateToConfirmationPage() {
        this.router.navigate([
            `/account/${this.accountNumber}/delivery-address/create-confirmation`,
        ]);
    }
}
