import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Role } from '../../../models/user/role';

@Component({
    selector: 'app-user-role-selector',
    templateUrl: './user-role-selector.component.html',
    styleUrls: ['./user-role-selector.component.scss'],
})
export class UserRoleSelectorComponent implements OnInit {
    @Input() allowedRoles: Role[];
    @Input() roleControl: FormControl;

    userRolesByHintKeys = {
        administrator: [
            Role.ROLE_DEALER_ADMIN,
            Role.ROLE_STOBAG_ADMIN,
            Role.ROLE_STOBAG_SUPERADMIN,
        ],
        employee: [Role.ROLE_STOBAG_USER, Role.ROLE_DEALER_USER],
        assembler: [Role.ROLE_DEALER_TECHNICAL],
    };
    userRoleHintKey$: Observable<string>;

    ngOnInit(): void {
        this.userRoleHintKey$ = this.roleControl.valueChanges.pipe(
            startWith(null),
            map(selectedRole => {
                const entry = Object.entries(this.userRolesByHintKeys).find(([key, value]) =>
                    value.includes(selectedRole),
                );
                return entry ? entry[0] : null;
            }),
        );
    }
}
