<shared-subheader [secondary]="true"></shared-subheader>
<div class="content-container">
    <mat-card>
        <div fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="space-around center">
                <h3>{{ 'forbidden' | translate }}</h3>
                <shared-icon
                    class="big-icon"
                    icon="forbid"></shared-icon>
            </div>
        </div>
    </mat-card>
</div>
