<mat-card ngxUiLoaderBlurred loaderId="user-settigs-card-loader">
    <ngx-ui-loader loaderId="user-settigs-card-loader"></ngx-ui-loader>
    
    <h3>{{ 'profile.userSettings.title' | translate }}</h3>
    
    <div class="settings-table">
    
        <form *ngIf="userSettingsForm" [formGroup]="userSettingsForm">
            <div class="row">
                <div class="column">
                    {{ 'profile.userSettings.showKpiDashboard' | translate }}
                </div>
                <div class="column action">
                    <mat-checkbox formControlName="showKpiDashboard"></mat-checkbox>
                </div>
            </div>
        </form>

    </div>

    <div class="profile-settings-action" *ngIf="canEdit">
        <button mat-flat-button class="primary-button" (click)="saveSettings()">
            {{ 'profile.save' | translate }}
        </button>
    </div>

</mat-card>
