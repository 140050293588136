import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountDTO, StatusLabelType } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-account-status-label',
    templateUrl: './account-status-label.component.html',
    styleUrls: ['./account-status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountStatusLabelComponent implements OnChanges {
    @Input() account: AccountDTO;

    type: StatusLabelType = 'info';
    text = '';

    constructor(private translateService: TranslateService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.account) {
            return;
        }
        const account = changes.account.currentValue;

        if (account.active) {
            if (this.account.newAccount) {
                this.type = 'info';
                this.text = this.translateService.instant('accountStatus.newAccount');
            } else {
                this.type = 'success';
                this.text = this.translateService.instant('accountStatus.active');
            }
        } else {
            this.type = 'warn';
            this.text = this.translateService.instant('accountStatus.deactivated');
        }
    }
}
