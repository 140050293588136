import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Settings } from '../models/user-settings/settings';
import { UserSettingsDto } from '../models/user-settings/user-settings-dto';

@Injectable()
export class UserSettingsService {
    private readonly backendUrl = 'authentication/api/user/settings';

    constructor(private httpClient: HttpClient) {}

    get(email: string): Observable<UserSettingsDto> {
        return this.httpClient.get<UserSettingsDto>(`${this.backendUrl}/${email}`);
    }

    update(email: string, requestBody: Settings): Observable<UserSettingsDto> {
        return this.httpClient.put<UserSettingsDto>(
            `${this.backendUrl}/${email}`, 
            requestBody
        );
    }

}
