import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
    private passwordPatter = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\\_]).{8,}$';
    formGroup: FormGroup;
    isOldPasswordVisible = false;
    isNewPasswordVisible = false;
    isNewPasswordAgainVisible = false;

    constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
        this.formGroup = new FormGroup({
            oldPassword: new FormControl('', Validators.required),
            newPassword: new FormControl(
                '',
                Validators.compose([Validators.required, Validators.pattern(this.passwordPatter)]),
            ),
            newPasswordAgain: new FormControl(
                '',
                Validators.compose([Validators.required, Validators.pattern(this.passwordPatter)]),
            ),
        });
    }

    onCancel() {
        this.dialogRef.close();
    }

    onChangePassword(): void {
        if (this.formGroup.invalid || !this.twoPasswordFieldsMatch()) {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
            return;
        }

        const { oldPassword, newPassword } = this.formGroup.value;
        this.dialogRef.close({
            oldPassword,
            newPassword,
        });
    }

    twoPasswordFieldsMatch(): boolean {
        return (
            this.formGroup.get('newPassword').value === this.formGroup.get('newPasswordAgain').value
        );
    }
}
