import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, LoadingSpinnerService, SnackbarType } from '@stobag/mystobag-shared';
import { of, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { UserService } from '../../../../../services/user.service';

@Component({
    selector: 'app-change-email-dialog',
    templateUrl: './change-email-dialog.component.html',
    styleUrls: ['./change-email-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailDialogComponent implements OnInit, OnDestroy {
    email: string;
    confirmationEmail: string;
    formGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.pattern('.*@.*')]),
        emailConfirmation: new FormControl(null, [
            Validators.required,
            Validators.pattern('.*@.*'),
        ]),
    });

    private subscription = new Subscription();

    constructor(
        private dialogRef: MatDialogRef<ChangeEmailDialogComponent>,
        private loadingSpinnerService: LoadingSpinnerService,
        private userService: UserService,
        private translateService: TranslateService,
        private dialogService: DialogService,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.formGroup.valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(() => this.checkIfEmailsMatch()),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    onCancel() {
        this.dialogRef.close();
    }

    save() {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
            return;
        }

        const newEmail = this.formGroup.get('email').value;
        this.subscription.add(
            this.loadingSpinnerService
                .withLoader(this.userService.checkEmail(newEmail), 'email-loader')
                .subscribe(
                    () => {
                        this.dialogService.openSnackbar(
                            this.translateService.instant('addUser.emailAlreadyRegistered'),
                            SnackbarType.ERROR,
                            5,
                        );
                    },
                    (error: HttpErrorResponse) => {
                        if (!error.status || error.status === 404) {
                            this.dialogRef.close(newEmail);
                            return of();
                        }
                        this.dialogService.openSnackbar(
                            this.translateService.instant('addUser.error'),
                            SnackbarType.ERROR,
                            5,
                        );
                        return throwError(error);
                    },
                ),
        );
    }

    checkIfEmailsMatch(): void {
        const email = this.formGroup.get('email').value;
        const confirmationEmail = this.formGroup.get('emailConfirmation').value;
        if (email !== confirmationEmail) {
            this.formGroup.get('emailConfirmation').setErrors({
                match: confirmationEmail,
            });
        } else {
            this.formGroup.get('emailConfirmation').setErrors(null);
        }
    }
}
