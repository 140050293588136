import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-password-sent',
    templateUrl: './password-sent.component.html',
    styleUrls: ['./password-sent.component.scss'],
})
export class PasswordSentComponent implements OnInit {
    username$: Observable<string>;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.username$ = this.activatedRoute.queryParams.pipe(
            filter(({ username }) => username),
            map(({ username }) => username),
        );
    }

    cancel(): void {
        this.router.navigateByUrl('login');
    }
}
