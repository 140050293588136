import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BannerNotificationType,
    BannerService,
    MyStobagSubheaderService,
} from '@stobag/mystobag-header';
import {
    AuthenticationService,
    DialogService,
    Permission,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { from, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { UserSource } from '../../../enums/user-source';
import { Role } from '../../../models/user/role';
import { UserDTO } from '../../../models/user/user-dto';
import { ContactService } from '../../../services/contact.service';
import {
    PasswordResetRequest,
    PasswordResetService,
} from '../../../services/password-reset.service';
import { UserService } from '../../../services/user.service';
import { UserRoleService } from '../../../services/user-role.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
    UserSource = UserSource;

    private userSubject = new Subject<void>();
    private subscription = new Subscription();

    isLoggedInAccount = false;
    user$ = this.loadUser$();
    subheaderUpdated$ = this.updateSubheader$();
    allowedRoles$: Observable<Role[]>;
    error = false;
    isUserReadOnly = false;
    isCognitoUser = false;
    stobagOrDealerAdminPermissions = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];
    rolesBelowDealerAdmin$ = this.userRoleService.getRolesBelowDealerAdmin$();
    isBDealer = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private subHeaderService: MyStobagSubheaderService,
        private userService: UserService,
        private router: Router,
        private translateService: TranslateService,
        private bannerService: BannerService,
        private dialogService: DialogService,
        private ngxPermissionService: NgxPermissionsService,
        private authenticationService: AuthenticationService,
        private passwordResetService: PasswordResetService,
        private contactService: ContactService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private userRoleService: UserRoleService,
    ) {
        this.subHeaderService.updatePageName('profile.title');
        this.subHeaderService.hideBackButton();
    }

    ngOnInit() {
        this.subscription.add(
            this.user$
                .pipe(
                    tap(async user => {
                        await this.addVerifyEmailBanner(user);
                        await this.addForceChangePasswordBanner(user);
                        this.addPhoneNumberMissingBanner(user);
                        this.isBDealer = user.role === Permission.BDealer;
                    }),
                    switchMap(user => this.setupUserPermissionsOnPage$(user)),
                )
                .subscribe(() => this.ngxUiLoaderService.stopLoader('master', 'userChange')),
        );
    }

    updateSubheader$(): Observable<boolean> {
        return this.user$.pipe(
            switchMap(user =>
                this.translateService
                    .get('profile.userProfile', { name: user.name ? user.name : user.fullName })
                    .pipe(
                        map(titleTranslation => {
                            this.subHeaderService.updatePageName(titleTranslation);
                            this.addBackButton(user);
                            return true;
                        }),
                    ),
            ),
        );
    }

    private setupUserPermissionsOnPage$(user: UserDTO) {
        return from(this.ngxPermissionService.hasPermission(Permission.Dealer)).pipe(
            tap(isDealerUser => {
                if (isDealerUser) {
                    this.isUserReadOnly = true;
                }

                if (!this.isUserReadOnly) {
                    this.allowedRoles$ = this.getApplicableRoles(user.role);
                }

                this.isUserReadOnly =
                    user.email === this.authenticationService.getEmailAddress() &&
                    this.authenticationService.getProvider() === 'AZURE';
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.router.navigateByUrl('forbidden');
                }
                return throwError(error);
            }),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    onEmailChange(user: UserDTO, newEmail: string) {
        const saveUserInPnetOrBoth = user.username
            ? this.userService.updateUserEmail(user.username, newEmail)
            : this.contactService.updateContactEmail(user.contactObjectId, newEmail);
        this.ngxUiLoaderService.startLoader('master', 'userChange');
        this.subscription.add(
            saveUserInPnetOrBoth.subscribe(() => {
                this.userSubject.next();
                const message = this.translateService.instant('profile.emailChangeSendSuccess');
                this.dialogService.openSnackbar(message, SnackbarType.SUCCESS, 7);
            }),
        );
    }

    private loadUser$(): Observable<UserDTO> {
        return this.userSubject.pipe(
            startWith(null),
            switchMap(() => this.getUser$()),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    this.router.navigateByUrl('forbidden');
                }
                this.error = true;
                throw error;
            }),
            shareReplay(1),
        );
    }

    private addBackButton(user: UserDTO) {
        const redirectUrl =
            user.role === Permission.BDealer
                ? `/account/${user.parentAccountNumber}`
                : `/account/${user.accountNumber}`;
        this.subHeaderService.showBackButton('shared.navigation.account', redirectUrl, false);
    }

    private addPhoneNumberMissingBanner(user: UserDTO) {
        if (!user.mobile && user.userSource !== UserSource.PartnerNetUser) {
            this.bannerService.addNotification({
                id: 'phone-number-not-provided',
                message: 'profile.mobilePhoneNumberMissing',
                removeOnNavigation: true,
                type: BannerNotificationType.info,
                closeable: true,
            });
        }
    }

    private async addVerifyEmailBanner(user: UserDTO) {
        const hasStobagPermission = await this.ngxPermissionService.hasPermission(
            STOBAG_PERMISSIONS,
        );
        if (hasStobagPermission && !user.emailVerified && user.userSource !== UserSource.Contact) {
            const links = [
                {
                    label: 'profile.resendEmailVerification',
                    onClick: () => this.resendEmailVerification(user.username),
                },
            ];

            this.bannerService.addNotification({
                id: 'email-not-verified',
                message: 'profile.emailNotVerified',
                removeOnNavigation: true,
                type: BannerNotificationType.warning,
                links,
            });
        }
    }

    private async addForceChangePasswordBanner(user: UserDTO) {
        const hasPermission = await this.ngxPermissionService.hasPermission(
            this.stobagOrDealerAdminPermissions,
        );
        if (hasPermission && user.userStatus === 'FORCE_CHANGE_PASSWORD') {
            const links = [
                {
                    label: 'profile.resendForcePasswordChangeEmail',
                    onClick: () => this.onPasswordReset(user.email),
                },
            ];

            this.bannerService.addNotification({
                id: 'force-password-change',
                message: 'profile.forcePasswordChangeStatus',
                removeOnNavigation: true,
                type: BannerNotificationType.warning,
                links,
            });
        }
    }

    private getUser$(): Observable<UserDTO> {
        const username = this.activatedRoute.snapshot.paramMap.get('username');
        const contactId = this.activatedRoute.snapshot.paramMap.get('contactId');
        if (username) {
            this.isCognitoUser = true;
            return this.userService.getUserDetails(username);
        } else if (contactId) {
            return this.userService.getContactDetails(contactId).pipe(
                tap(contactUser => {
                    if (contactUser.username) {
                        this.isCognitoUser = true;
                        this.router.navigate([`/user/profile/${contactUser.username}`]);
                    }
                }),
            );
        } else {
            this.isLoggedInAccount = true;
            return this.userService.getLoggedInUserDetails();
        }
    }

    private resendEmailVerification(username: string): void {
        this.subscription.add(
            this.userService.triggerEmailVerification(username).subscribe(
                () => {
                    const message = this.translateService.instant(
                        'profile.verificationEmailSendSuccess',
                    );
                    this.dialogService.openSnackbar(message, SnackbarType.SUCCESS, 7);
                },
                (error: HttpErrorResponse) => {
                    const errorMessage = this.translateService.instant(
                        'passwordReset.unknownError',
                    );
                    this.dialogService.openSnackbar(errorMessage, SnackbarType.ERROR, 7);
                },
            ),
        );
    }

    private getApplicableRoles(userRole: string): Observable<Role[]> {
        return this.userService
            .getAllowedRolesCreate()
            .pipe(
                map(roles => _.isEmpty(roles)
                    ? [Role[userRole]]
                    : roles.filter(role => role !== Role.ROLE_DEALER_BDEALER || userRole === Role.ROLE_DEALER_BDEALER)
                )
            );
    }

    onPasswordReset(mail: string) {
        const passwordResetRequest = {
            username: mail,
        } as PasswordResetRequest;
        this.subscription.add(
            this.passwordResetService.requestConfirmationCode(passwordResetRequest).subscribe({
                next: () => {
                    const message = this.translateService.instant('profile.passwordResetSuccess');
                    this.dialogService.openSnackbar(message, SnackbarType.SUCCESS, 7);
                },
                error: () => {
                    const errorMessage = this.translateService.instant(
                        'passwordReset.unknownError',
                    );
                    this.dialogService.openSnackbar(errorMessage, SnackbarType.ERROR, 7);
                },
            }),
        );
    }

    formatRole(role: string): string {
        const key = `profile.${role ?? 'withoutRole'}`;
        return this.translateService.instant(key);
    }
}
