import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Player from '@vimeo/player/dist/player.js';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface LoginVideoConfig {
    enabled: boolean;
    urls: string[];
}

export interface VideoContent {
    language: string;
    url: string;
}

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
    @Input() loginVideoConfig: LoginVideoConfig;

    player: Player;

    private subscription = new Subscription();

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        const availableLanguages = Object.keys(this.loginVideoConfig.urls);
        if (this.loginVideoConfig.enabled && availableLanguages.length > 0) {
            const url = this.loginVideoConfig.urls[availableLanguages[0]];
            this.initPlayer(url);
        }

        this.subscription.add(
            this.translateService.onLangChange
                .pipe(filter(() => Boolean(this.player)))
                .subscribe(event => this.changeVideoLanguage(event.lang)),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private initPlayer(url: string): void {
        const options = this.createOptions(url);
        this.player = new Player('video-placeholder', options);
    }

    private loadVideo(url: string): void {
        const options = this.createOptions(url);
        this.player.loadVideo(options);
    }

    private createOptions(url: string) {
        return {
            url,
            width: 640,
        };
    }

    private changeVideoLanguage(language: string) {
        const url = this.loginVideoConfig.urls[language];
        this.loadVideo(url);
    }
}
