import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutComponent } from '@stobag/mystobag-header';
import { AccessAndRoleGuardService, Permission, STOBAG_PERMISSIONS } from '@stobag/mystobag-shared';

import { AccountListComponent } from './components/account/account-list/account-list.component';
import { CompanySettingsComponent } from './components/account/company-settings/company-settings.component';
import { DeliveryAddressComponent } from './components/account/company-settings/delivery-address/delivery-address.component';
import { DeliveryAddressConfirmationComponent } from './components/account/company-settings/delivery-address/delivery-address-confirmation/delivery-address-confirmation.component';
import { DeliveryAddressCreateComponent } from './components/account/company-settings/delivery-address/delivery-address-create/delivery-address-create.component';
import { DeliveryAddressDeleteComponent } from './components/account/company-settings/delivery-address/delivery-address-delete/delivery-address-delete.component';
import { DeliveryAddressEditComponent } from './components/account/company-settings/delivery-address/delivery-address-edit/delivery-address-edit.component';
import { DeliveryAddressListComponent } from './components/account/company-settings/delivery-address-list/delivery-address-list.component';
import { UserListComponent } from './components/account/company-settings/user-list/user-list.component';
import { VacationComponent } from './components/account/company-settings/vacation/vacation.component';
import { VacationListComponent } from './components/account/company-settings/vacation/vacation-list/vacation-list.component';
import { VacationListStandaloneLayoutComponent } from './components/account/company-settings/vacation/vacation-list/vacation-list-standalone-layout.component';
import { AuthenticationLayoutComponent } from './components/authentication/authentication-layout.component';
import { EmailVerificationComponent } from './components/authentication/email-verification/email-verification.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { PasswordChangeComponent } from './components/authentication/password-change/password-change.component';
import { PasswordResetComponent } from './components/authentication/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './components/authentication/password-reset-request/password-reset-request.component';
import { PasswordSentComponent } from './components/authentication/password-sent/password-sent.component';
import { PasswordSetComponent } from './components/authentication/password-set/password-set.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { ProfileComponent } from './components/user/profile/profile.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        children: [
            { path: 'login', component: LoginComponent },
            {
                path: 'password-reset',
                component: PasswordResetRequestComponent,
            },
            { path: 'password-sent', component: PasswordSentComponent },
            { path: 'password-set', component: PasswordSetComponent },
            {
                path: 'password-reset/:username/:confirmationCode',
                component: PasswordResetComponent,
            },
            {
                path: 'password-change/:username/:sessionId',
                component: PasswordChangeComponent,
            },
            {
                path: 'verification/email',
                component: EmailVerificationComponent,
            },
            { path: 'oauth-login', component: LoginComponent },
        ],
    },

    // Account
    {
        path: '',
        canActivate: [AccessAndRoleGuardService],
        component: BasicLayoutComponent,
        data: {
            access: 'ACCESS_SERVICE_ACCOUNT',
            showGhostModeStatusBar: true,
        },
        children: [
            {
                path: 'account',
                children: [
                    {
                        path: '',
                        component: AccountListComponent,
                    },
                    {
                        path: ':accountNumber',
                        component: CompanySettingsComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'employee-list',
                                pathMatch: 'full',
                            },
                            {
                                path: 'employee-list',
                                component: UserListComponent,
                            },
                            {
                                path: 'absences-list',
                                component: VacationComponent,
                            },
                            {
                                path: 'delivery-address-list',
                                component: DeliveryAddressListComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'account/:accountNumber/delivery-address',
                component: DeliveryAddressComponent,
                children: [
                    {
                        path: 'create',
                        component: DeliveryAddressCreateComponent,
                    },
                    {
                        path: 'edit/:shipToPartyId',
                        component: DeliveryAddressEditComponent,
                    },
                    {
                        path: 'delete/:shipToPartyId',
                        component: DeliveryAddressDeleteComponent,
                    },
                    {
                        path: 'create-confirmation',
                        component: DeliveryAddressConfirmationComponent,
                    },
                    {
                        path: 'edit-confirmation',
                        component: DeliveryAddressConfirmationComponent,
                    },
                    {
                        path: 'delete-confirmation',
                        component: DeliveryAddressConfirmationComponent,
                    },
                ],
            },
        ],
    },
    // User
    {
        path: '',
        canActivate: [AccessAndRoleGuardService],
        component: BasicLayoutComponent,
        data: {
            access: 'ACCESS_SERVICE_USER',
            showGhostModeStatusBar: true,
        },
        children: [
            {
                path: 'user/profile',
                component: ProfileComponent,
                canActivate: [AccessAndRoleGuardService],
                data: {
                    access: 'ACCESS_SERVICE_USER',
                },
            },
            {
                path: 'user/profile/:username',
                component: ProfileComponent,
                canActivate: [AccessAndRoleGuardService],
                data: {
                    access: 'ACCESS_SERVICE_USER',
                    roles: [...STOBAG_PERMISSIONS, Permission.DealerAdmin],
                },
            },
            {
                path: 'contact/profile/:contactId',
                component: ProfileComponent,
                canActivate: [AccessAndRoleGuardService],
                data: {
                    access: 'ACCESS_SERVICE_USER',
                    roles: [...STOBAG_PERMISSIONS, Permission.DealerAdmin],
                },
            },
            {
                path: 'user/create/:accountId',
                component: AddUserComponent,
                canActivate: [AccessAndRoleGuardService],
                data: {
                    access: 'ACCESS_SERVICE_USER',
                    roles: [...STOBAG_PERMISSIONS, Permission.DealerAdmin],
                },
            },
        ],
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
    },
    {
        path: 'account/:accountNumber/absences-list-standalone',
        component: VacationListStandaloneLayoutComponent,
        children: [{ path: '', component: VacationListComponent }],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
