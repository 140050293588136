<form [formGroup]="formGroup" fxLayout="column">
    <div fxLayout="column" class="requirements">
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [class.ok]="!hasError('minlength')"
            data-cy="lengthCheck">
            <shared-icon
                [class.material-icons-outlined]="hasError('minlength')"
                icon="circle-check"></shared-icon>
            <span>{{ 'changePassword.passwordRequirements.length' | translate }}</span>
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [class.ok]="!hasError('SMALL_LETTER')"
            data-cy="smallLetterCheck">
            <shared-icon
                [class.material-icons-outlined]="hasError('SMALL_LETTER')"
                icon="circle-check"></shared-icon>
            <span>{{ 'changePassword.passwordRequirements.smallCaseCharacter' | translate }}</span>
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [class.ok]="!hasError('CAPITAL_LETTER')"
            data-cy="capitalLetterCheck">
            <shared-icon
                [class.material-icons-outlined]="hasError('CAPITAL_LETTER')"
                icon="circle-check"></shared-icon>
            <span>{{ 'changePassword.passwordRequirements.capitalCharacter' | translate }}</span>
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [class.ok]="!hasError('SPECIAL_CHARACTER')"
            data-cy="specialCharacterCheck">
            <shared-icon
                [class.material-icons-outlined]="hasError('SPECIAL_CHARACTER')"
                icon="circle-check"></shared-icon>
            <span>{{ 'changePassword.passwordRequirements.specialCharacter' | translate }}</span>
        </div>
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            [class.ok]="!hasError('NUMBER')"
            data-cy="numberCheck">
            <shared-icon
                [class.material-icons-outlined]="hasError('NUMBER')"
                icon="circle-check"></shared-icon>
            <span>{{ 'changePassword.passwordRequirements.number' | translate }}</span>
        </div>
    </div>

    <div fxLayout="column">
        <mat-form-field fxFlex="auto" appearance="standard">
            <mat-label>{{ 'passwordReset.password' | translate }}</mat-label>
            <shared-icon
                matPrefix
                class="icon-prefix-padding"
                [fontSet]="'material-icons-outlined'"
                icon="lock"></shared-icon>
            <input
                matInput
                [type]="isPasswordVisible ? 'text' : 'password'"
                formControlName="newPassword"
                data-cy="newPasswordInput"
                [placeholder]="'passwordReset.password' | translate" />
            <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="isPasswordVisible = !isPasswordVisible">
                <shared-icon [icon]="isPasswordVisible ? 'eye-off' : 'eye'">
                </shared-icon>
            </button>
            <mat-error *ngIf="formGroup.get('newPassword').invalid">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="auto" appearance="standard">
            <mat-label>{{ 'passwordReset.passwordConfirmation' | translate }}</mat-label>
            <shared-icon matPrefix class="icon-prefix-padding" icon="lock"></shared-icon>
            <input
                matInput
                type="password"
                formControlName="newPasswordAgain"
                data-cy="newPasswordAgainInput"
                [placeholder]="'passwordReset.passwordConfirmation' | translate" />
            <mat-error *ngIf="formGroup.get('newPasswordAgain').invalid">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxFlex="auto" fxLayoutAlign="flex-end">
        <button mat-flat-button id="button-login" class="primary-button" (click)="save()">
            {{ 'passwordReset.save' | translate }}
        </button>
    </div>
</form>
