<ng-container *ngIf="!error; else errorTemplate">
    <ng-container *ngIf="user$ | async as user">
        <shared-subheader [secondary]="true" *ngIf="(subheaderUpdated$ | async) === true">
            <shared-status-label *ngIf="user.role" type="success" title-suffix>
                {{ formatRole(user.role) }}
            </shared-status-label>
        </shared-subheader>
        <div class="content-container">
            <app-user-details-card
                *ngIf="user.userSource !== UserSource.PartnerNetUser"
                [user]="user"
                [isUserReadOnly]="isUserReadOnly"></app-user-details-card>
            <app-login-data-card
                [user]="user"
                (emailChanged)="onEmailChange(user, $event)"
                (passwordResetInitiated)="onPasswordReset($event)"></app-login-data-card>
            <app-access-details-card
                *ngxPermissionsExcept="rolesBelowDealerAdmin$ | async"
                [user]="user"
                [isLoggedInAccount]="isLoggedInAccount"
                [isUserReadOnly]="isUserReadOnly"
                [allowedRoles]="allowedRoles$ | async"></app-access-details-card>
            <!-- Hidden until backend implementation -->
            <app-notification-settings-card
                *ngIf="(isCognitoUser || isLoggedInAccount) && !isBDealer"
                [email]="user.email"
                [mobile]="user.mobile"
                [salesContext]="user.account.salesContext"></app-notification-settings-card>
            <app-user-settings-card
                [user]="user"
                [isUserReadOnly]="isUserReadOnly"></app-user-settings-card>                
            <app-user-deletion-card
                *ngxPermissionsOnly="stobagOrDealerAdminPermissions"
                [user]="user"></app-user-deletion-card>
        </div>
    </ng-container>
</ng-container>
<ng-template #errorTemplate>
    <div class="content-container">{{ 'profile.notFound' | translate }}</div>
</ng-template>
