<mat-card>
    <div class="account-logo-container">
        <div class="account-logo">
            <img
                *ngIf="!accountLogoLoadError"
                class="avatar-image"
                src="/account-logo/{{ account.accountNumber }}.png"
                (error)="onImageLoadError()"/>
            <div *ngIf="accountLogoLoadError" class="missing-logo">
                <span class="missing-logo-message">{{ 'account.yourLogo' | translate }}</span>
                <shared-icon
                    class="missing-logo-icon"
                    icon="photo"></shared-icon>
            </div>
        </div>
        <button mat-stroked-button class="secondary-button" (click)="uploadLogo()"
                *ngxPermissionsExcept="rolesBelowDealerAdmin$ | async">
            <shared-icon
                class="upload-logo-icon"
                [fontSet]="'material-icons-outlined'"
                icon="camera"></shared-icon>
            {{ 'account-logo.uploadButtonTitle' | translate }}
        </button>
    </div>
    <div class="account-info">
        <div *ngIf="account.accountNumber" class="info-row">
            <span class="label-bold">{{ 'account.accountNumber' | translate }}</span>
            <div fxLayout="row" fxLayoutGap="0.5rem">
                <span>{{ account.accountNumber }}</span>
                <mat-icon
                    *stobagOnly
                    featureHighlight
                    class="clickable"
                    svgIcon="ghost"
                    (click)="enableGhostMode()">
                </mat-icon>
            </div>
        </div>
        <div *ngIf="account.street" class="info-row">
            <span class="label-bold">{{ 'account.street' | translate }}</span>
            <div>
                <span>{{ account.street }}</span
                >&nbsp;<span>{{ account.houseNumber }}</span>
            </div>
        </div>
        <div *ngIf="account.zipCode" class="info-row">
            <span class="label-bold">{{ 'account.zipCode' | translate }}</span>
            <span>{{ account.zipCode }} {{ account.city }}</span>
        </div>
        <div *ngIf="account.phone" class="info-row">
            <span class="label-bold">{{ 'account.phone' | translate }}</span>
            <span>{{ account.phone }}</span>
        </div>
        <div *ngIf="account.salesContext?.displayName" class="info-row">
            <span class="label-bold">{{ 'account.salesContext' | translate }}</span>
            <span>{{ account.salesContext.displayName }}</span>
        </div>
        <div *ngIf="account.salesOffice?.displayName" class="info-row">
            <span class="label-bold">{{ 'account.salesOffice' | translate }}</span>
            <span>{{ account.salesOffice.displayName }}</span>
        </div>
        <div *ngIf="account.contactPerson" class="info-row">
            <span class="label-bold">{{ 'account.contactPerson' | translate }}</span>
            <span>{{ account.contactPerson }}</span>
        </div>
        <div *ngIf="account.contactPersonEmail" class="info-row">
            <span class="label-bold">{{ 'account.contactPersonEmail' | translate }}</span>
            <span>{{ account.contactPersonEmail }}</span>
        </div>
    </div>
    <div class="partner-status">
        <mat-icon
            *ngIf="account.partnerStatuses.includes('PARTNER_BRONZE')"
            svgIcon="bronze-partner"
            class="partner-status-image"></mat-icon>
        <mat-icon
            *ngIf="account.partnerStatuses.includes('PARTNER_SILVER')"
            svgIcon="silver-partner"
            class="partner-status-image"></mat-icon>
        <mat-icon
            *ngIf="account.partnerStatuses.includes('PARTNER_GOLD')"
            svgIcon="gold-partner"
            class="partner-status-image"></mat-icon>
    </div>
</mat-card>
