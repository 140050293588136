<ng-container *ngIf="oldDeliveryAddress$ | async as oldDeliveryAddress">
    <form *ngIf="formGroup$ | async as formGroup" class="form" [formGroup]="formGroup">
        <div class="address-data">
            <div>
                <h3>{{ 'delivery-address.previousAddress' | translate }}</h3>
                <div class="previous-address">
                    <div class="address-info">
                        <div class="label-field">
                            <div class="label-bold">
                                {{ 'delivery-address.accountNumber' | translate }}
                            </div>
                            <div>{{ oldDeliveryAddress.shipToPartyId }}</div>
                        </div>
                        <div class="label-field">
                            <div class="label-bold">
                                {{ 'delivery-address.company' | translate }}
                            </div>
                            <div>{{ oldDeliveryAddress.name }}</div>
                        </div>
                    </div>
                    <div class="label-field">
                        <div class="label-bold">
                            {{ 'delivery-address.streetAndNumber' | translate }}
                        </div>
                        <div>
                            {{
                                oldDeliveryAddress.address?.street +
                                    ' ' +
                                    oldDeliveryAddress.address?.houseNumber
                            }}
                        </div>
                    </div>
                    <div class="label-field">
                        <div class="label-bold">
                            {{ 'delivery-address.postalCodeAndCity' | translate }}
                        </div>
                        <div>
                            {{
                                oldDeliveryAddress.address?.postalCode +
                                    ' ' +
                                    oldDeliveryAddress.address?.city
                            }}
                        </div>
                    </div>
                    <div class="label-field">
                        <div class="label-bold">{{ 'delivery-address.phone' | translate }}</div>
                        <div>{{ oldDeliveryAddress.address?.telephoneNumber }}</div>
                    </div>
                    <div class="label-field" *ngIf="oldDeliveryAddress.specialShipToPartyType">
                        <div class="label-bold">
                            {{ 'delivery-address.specialDealerType.label' | translate }}
                        </div>
                        <div>
                            {{
                                'delivery-address.specialDealerType.' +
                                    oldDeliveryAddress.specialShipToPartyType | translate
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h3>{{ 'delivery-address.newAddress' | translate }}</h3>
                <div class="new-address">
                    <mat-form-field>
                        <mat-label>{{ 'delivery-address.streetAndNumber' | translate }}</mat-label>
                        <input matInput formControlName="streetAndNumber" />
                        <mat-error>{{
                            'shared.stateChangeMessages.requiredError' | translate
                        }}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{
                            'delivery-address.postalCodeAndCity' | translate
                        }}</mat-label>
                        <input matInput formControlName="postalCodeAndCity" />
                        <mat-error>{{
                            'shared.stateChangeMessages.requiredError' | translate
                        }}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ 'delivery-address.phone' | translate }}</mat-label>
                        <input matInput formControlName="phone" />
                        <mat-error>{{
                            'shared.stateChangeMessages.requiredError' | translate
                        }}</mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="formGroup.get('specialShipToPartyType')?.value">
                        <mat-label>{{
                            'delivery-address.specialDealerType.label' | translate
                        }}</mat-label>
                        <mat-select formControlName="specialShipToPartyType">
                            <mat-option value="A_DEALER">{{
                                'delivery-address.specialDealerType.A_DEALER' | translate
                            }}</mat-option>
                            <mat-option value="B_DEALER">{{
                                'delivery-address.specialDealerType.B_DEALER' | translate
                            }}</mat-option>
                        </mat-select>
                        <mat-error>{{
                            'shared.stateChangeMessages.requiredError' | translate
                        }}</mat-error>
                    </mat-form-field>
                    <div>
                        <p class="required-label">
                            {{ 'delivery-address.addressType' | translate }}
                        </p>
                        <mat-radio-group formControlName="type">
                            <mat-radio-button [value]="DeliveryType.Truck">
                                {{ 'delivery-address.truck' | translate }}
                            </mat-radio-button>
                            <mat-radio-button [value]="DeliveryType.TruckAndPackage">
                                {{ 'delivery-address.truckAndPackage' | translate }}
                            </mat-radio-button>
                            <mat-radio-button [value]="DeliveryType.Package">
                                {{ 'delivery-address.package' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="comment">
            <h3>{{ 'delivery-address.comment' | translate }}</h3>
            <mat-form-field fxFill appearance="outline" class="comment-field">
                <textarea
                    matInput
                    formControlName="comment"
                    [placeholder]="'delivery-address.commentPlaceholder' | translate"></textarea>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-stroked-button color="primary" class="secondary-button" (click)="cancel()">
                {{ 'shared.actions.cancel' | translate }}
            </button>
            <button mat-flat-button color="primary" class="primary-button" (click)="save()">
                {{ 'shared.actions.save' | translate }}
            </button>
        </div>
    </form>
</ng-container>
