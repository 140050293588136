import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthenticationService,
    DialogService,
    LoadingSpinnerService,
    Permission,
    serviceUrls,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, from, Observable, Subscription } from 'rxjs';

import { UserSource } from '../../../../enums/user-source';
import { Role } from '../../../../models/user/role';
import { UserDTO } from '../../../../models/user/user-dto';
import { AccountService } from '../../../../services/account.service';
import { ContactService } from '../../../../services/contact.service';
import { UserService } from '../../../../services/user.service';

export interface AccessFormValue {
    partnerNetAccess: boolean;
    dealerAccount: string;
    role: string;
    locale: string;
    earlyAdopter: boolean;
}

@Component({
    selector: 'app-access-details-card',
    templateUrl: './access-details-card.component.html',
    styleUrls: ['./access-details-card.component.scss'],
})
export class AccessDetailsCardComponent implements OnInit {
    UserSource = UserSource;

    @Input() isLoggedInAccount: boolean;
    @Input() allowedRoles: Role[];
    @Input() isUserReadOnly: boolean;

    @Input() set user(user: UserDTO) {
        if (user) {
            this._user = user;
            if (user.userSource === UserSource.Contact) {
                this.setUniqueContactFound(user.email);
            }
        }
    }

    _user: UserDTO;
    uniqueContactFound: boolean;
    formGroup = new FormGroup({
        partnerNetAccess: new FormControl(false),
        dealerAccount: new FormControl({ value: null, disabled: true }, [Validators.required]),
        role: new FormControl(null, [Validators.required]),
        locale: new FormControl(null, [Validators.required]),
        earlyAdopter: new FormControl(false),
    });
    supportedLanguages$: Observable<string[]>;
    canEdit = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];

    private subscription = new Subscription();

    constructor(
        private userService: UserService,
        private contactService: ContactService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private authenticationService: AuthenticationService,
        private ngxPermissionsService: NgxPermissionsService,
        private loadingSpinnerService: LoadingSpinnerService,
        private accountService: AccountService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.supportedLanguages$ = this.userService.getSupportedLanguagesByAccount(
            this._user.parentAccountNumber ?? this._user.accountNumber,
        );

        const fullName = this.getUserFullName(this._user);
        this.formGroup.get('dealerAccount').setValue(`${fullName} (${this._user.accountNumber})`);
        this.formGroup.get('role').setValue(this._user.role);
        this.formGroup.get('locale').setValue(this._user.locale);
        this.formGroup
            .get('partnerNetAccess')
            .setValue(this._user.userSource !== UserSource.Contact);
        this.formGroup.get('earlyAdopter').setValue(this._user.earlyAdopter);

        const isCognitoUser = this.authenticationService.getProvider() === 'COGNITO';
        if (isCognitoUser) {
            this.setEarlyAdopterValueIfLoggedInAsStobagUser(this._user.earlyAdopter);
        }

        this.disableFormValuesIfDealerUserOrBelow();

        if (this._user.role === Permission.BDealer) {
            const shipToPartId = parseInt(this._user.accountNumber, 10).toString();
            this.accountService
                .getOneDeliveryAddress(this._user.parentAccountNumber, shipToPartId)
                .subscribe(dAddress => {
                    const address = `${dAddress.name}, ${dAddress.address.street} ${dAddress.address.houseNumber}, ${dAddress.address.postalCode} ${dAddress.address.city}`;
                    this.formGroup.addControl(
                        'bDealerDeliveryAddress',
                        new FormControl({ value: address, disabled: true }),
                    );
                    this.cdr.markForCheck();
                });
            this.formGroup.get('role').disable();
        }
    }

    private disableFormValuesIfDealerUserOrBelow() {
        this.subscription.add(
            from(this.ngxPermissionsService.hasPermission(this.canEdit))
                .pipe(filter(hasPermission => !hasPermission))
                .subscribe(() => {
                    this.formGroup.get('role').disable();
                    this.formGroup.get('locale').disable();
                    this.allowedRoles = Object.values(Role).filter(
                        r => `${r}` === `${this._user.role}`,
                    );
                }),
        );
    }

    private setUniqueContactFound(email: string): void {
        this.subscription.add(
            this.loadingSpinnerService
                .withLoader(this.contactService.getContactByMail(email), 'partnernet-access-loader')
                .subscribe(
                    () => (this.uniqueContactFound = true),
                    () => (this.uniqueContactFound = false),
                ),
        );
    }

    enableDisableEarlyAdopter(): void {
        const earlyAdopterValue = !this.formGroup.get('earlyAdopter').value;
        const username = this._user.username;
        this.subscription.add(
            this.userService
                .setEarlyAdopter(username, earlyAdopterValue)
                .subscribe((user: UserDTO) => {
                    this.formGroup.get('earlyAdopter').setValue(user.earlyAdopter);
                    const updateSuccessMessage =
                        this.translateService.instant('profile.successUpdate');
                    this.dialogService.openSnackbar(updateSuccessMessage, SnackbarType.SUCCESS, 5);
                }),
        );
    }

    save() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
            return;
        }
        const username = this._user.username;
        const formValue = this.formGroup.getRawValue() as AccessFormValue;
        const createOrUpdate = username
            ? this.enablePartnernetAccess(username, formValue)
            : this.userService.createUserFromContact(this._user.contactObjectId, formValue.role);
        this.subscription.add(
            createOrUpdate.subscribe({
                next: user => {
                    const updateSuccessMessage =
                        this.translateService.instant('profile.successUpdate');
                    this.dialogService.openSnackbar(updateSuccessMessage, SnackbarType.SUCCESS, 5);
                    if (!username) {
                        this.router.navigateByUrl(`${serviceUrls.userProfile}/${user.username}`);
                    }
                },
                error: (error: HttpErrorResponse) => this.handleError(error),
            }),
        );
    }

    private enablePartnernetAccess(
        username: string,
        formValue: AccessFormValue,
    ): Observable<UserDTO> {
        const request = {
            role: formValue.role,
            locale: formValue.locale,
            earlyAdopter: formValue.earlyAdopter,
        };
        return this.userService.enablePartnernetAccess(username, request);
    }

    private getUserFullName(user: UserDTO): string | null {
        if (user.fullName) {
            return user.fullName;
        } else if (user.account) {
            return `${user.account.name} ${user.account.name2}`;
        }
    }

    private setEarlyAdopterValueIfLoggedInAsStobagUser(isEarlyAdopter: boolean): void {
        this.ngxPermissionsService.hasPermission(STOBAG_PERMISSIONS).then(isStobagUser => {
            if (isStobagUser) {
                this.formGroup.get('earlyAdopter').setValue(isEarlyAdopter);
            }
        });
    }

    private handleError(error: HttpErrorResponse): void {
        const message =
            error.status === 500
                ? this.translateService.instant('profile.serverError')
                : error.error;
        this.dialogService.openSnackbar(message, SnackbarType.ERROR, 5);
    }
}
