<app-content-card>
	<form class="form content-block" fxLayout="column">
		<h1>{{'passwordReset.title' | translate}}</h1>
		<p>{{'passwordReset.description' | translate}}</p>
		<app-password-change-form (newPassword)="setNewPassword($event)"></app-password-change-form>
		<div fxFlex="auto">
			<div class="textlink" (click)="cancel()">
				{{ 'cancel' | translate }}
			</div>
		</div>
	</form>
</app-content-card>
