import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    NotificationSubscriptionSettings,
    UserNotificationSettings,
} from '@stobag/mystobag-notification-shared';
import { Observable } from 'rxjs';

@Injectable()
export class NotificationSettingsService {
    private readonly backendUrl = 'notification/api/notification-settings';

    constructor(private httpClient: HttpClient) {}

    getNotificationSettingsOfUser(
        userEmail: string,
    ): Observable<Record<string, NotificationSubscriptionSettings>> {
        return this.httpClient.get<Record<string, NotificationSubscriptionSettings>>(
            `${this.backendUrl}/${userEmail}`,
        );
    }

    updateUserNotificationSettings(
        userEmail: string,
        requestBody: UserNotificationSettings,
    ): Observable<void> {
        return this.httpClient.put<void>(`${this.backendUrl}/${userEmail}`, requestBody);
    }
}
