import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserRoleInfoDialogComponent } from './user-role-info-dialog/user-role-info-dialog.component';

@Component({
    selector: 'app-user-role-info-hint',
    templateUrl: './user-role-info-hint.component.html',
    styleUrls: ['./user-role-info-hint.component.scss'],
})
export class UserRoleInfoHintComponent {
    @Input() hasError: false;

    constructor(private dialog: MatDialog) {}

    onClick() {
        this.dialog.open(UserRoleInfoDialogComponent);
    }
}
