import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

@Injectable()
export class VerificationService {
    backendUrl = '/authentication/verification';

    constructor(private httpClient: HttpClient) {}

    sendEmailChangeVerification(username: string, code: string): Observable<unknown> {
        const options = {
            params: this.generateParams(username, code),
        };
        return this.httpClient.post<unknown>(`${this.backendUrl}/email`, null, options);
    }

    private generateParams(username: string, code: string): HttpParams {
        let paramsValue = new HttpParams();
        paramsValue = paramsValue.set('username', username);
        paramsValue = paramsValue.set('code', code);
        return paramsValue;
    }
}
