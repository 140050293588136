import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { UpdateUserRequest } from '../models/user/update-user-request';
import { UserDTO } from '../models/user/user-dto';
import { CustomURLEncoder } from '../utils/custom-url-encoder';

@Injectable()
export class ContactService {
    backendUrl = `/authentication/api/contact`;

    constructor(
        private httpClient: HttpClient,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {}

    getContactByMail(mail: string): Observable<UserDTO> {
        const params = new HttpParams({ encoder: new CustomURLEncoder() }).set('mail', mail);
        return this.loadingSpinnerService.withLoader(
            this.httpClient.get<UserDTO>(this.backendUrl, { params }),
            'get-contact',
        );
    }

    updateContact(contactObjectId: string, request: UpdateUserRequest): Observable<UserDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.httpClient.put<UserDTO>(`${this.backendUrl}/${contactObjectId}`, request),
        );
    }

    updateContactEmail(contactObjectId: string, newEmail: string): Observable<UserDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.httpClient.put<UserDTO>(`${this.backendUrl}/${contactObjectId}/email`, {
                email: newEmail,
            }),
        );
    }
}
