import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-user-deletion-confirmation-dialog',
    templateUrl: 'user-deletion-confirmation-dialog.component.html',
    styleUrls: ['user-deletion-confirmation-dialog.component.scss'],
})
export class UserDeletionConfirmationDialogComponent {
    constructor(public dialogRef: MatDialogRef<unknown>) {
    }

    close() {
        this.dialogRef.close(true);
    }
}
