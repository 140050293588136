import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    mapSalesItemsToOptions,
    SearchFieldDefBase,
    SearchSelectField,
    SearchTextField,
} from '@stobag/mystobag-header';
import { salesContextFilters } from '@stobag/mystobag-shared';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface FilterRequest {
    search: string;
    salesOffice: number[];
    countries: string[];
    partnerStatus: string[];
    showOwnDealers: boolean;
    hideNewAccounts: boolean;
    hideDeactivatedAccounts: boolean;
}

@Component({
    selector: 'app-account-list-filter',
    templateUrl: './account-list-filter.component.html',
    styleUrls: ['./account-list-filter.component.scss'],
})
export class AccountListFilterComponent implements OnInit, OnDestroy {
    fieldDefs$: Observable<Array<SearchFieldDefBase<unknown>>>;
    filterForm: FormGroup;

    @Input() countries$: Observable<string[]>;
    @Input() partnerStatuses$: Observable<string[]>;

    @Output() filterRequest: EventEmitter<FilterRequest> = new EventEmitter<FilterRequest>();

    private subscription: Subscription = new Subscription();
    private filterSubject = new Subject<Record<string, unknown>>();

    ngOnInit() {
        this.filterForm = new FormGroup({
            hideNewAccounts: new FormControl(true),
            showOwnDealers: new FormControl(),
        });
        this.fieldDefs$ = this.getFieldDefs$();
        this.subscription.add(
            combineLatest([
                this.filterSubject.asObservable(),
                this.filterForm.valueChanges.pipe(startWith({ hideNewAccounts: true })),
            ])
                .pipe(map(([filter, formValues]) => this.toFilterRequest(filter, formValues)))
                .subscribe(filterRequest => this.filterRequest.emit(filterRequest)),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onFilterChange(filter: Record<string, unknown>) {
        this.filterSubject.next(filter);
    }

    private toFilterRequest(
        filter: Record<string, unknown>,
        formValues: Record<string, unknown>,
    ): FilterRequest {
        const { searchKey, countries, salesContext, partnerStatuses } = filter;
        const { hideNewAccounts, showOwnDealers } = formValues;

        return {
            countries,
            salesOffice: salesContext
                ? (salesContext as string[]).map(code => parseInt(code, 10))
                : [],
            search: searchKey,
            partnerStatus: partnerStatuses,
            hideDeactivatedAccounts: hideNewAccounts,
            hideNewAccounts,
            showOwnDealers,
        } as FilterRequest;
    }

    private getFieldDefs$(): Observable<Array<SearchFieldDefBase<unknown>>> {
        const salesItems = salesContextFilters;
        return of([
            new SearchTextField({
                key: 'searchKey',
                label: 'account-list.search',
                placeholder: 'account-list.searchPlaceholder',
            }),
            new SearchSelectField({
                key: 'countries',
                label: 'account.country',
                multiple: true,
                options: this.countries$.pipe(
                    map(countries =>
                        countries.map(country => ({
                            key: country.toLowerCase(),
                            label: country,
                        })),
                    ),
                ),
                showFilter: true,
                filterPlaceholder: 'account-list.search',
                filterEmptyLabel: 'account.searchErrorMessage',
            }),
            new SearchSelectField({
                key: 'salesContext',
                label: 'account.salesContext',
                multiple: true,
                options: mapSalesItemsToOptions(salesItems),
                showFilter: true,
                filterPlaceholder: 'account-list.search',
                filterEmptyLabel: 'account.searchErrorMessage',
            }),
            new SearchSelectField({
                key: 'partnerStatuses',
                label: 'account.partnerStatuses',
                multiple: true,
                options: this.partnerStatuses$.pipe(
                    map(partnerStatuses =>
                        partnerStatuses.map(status => ({
                            key: status,
                            label: `account.partnerStatus.${status}`,
                        })),
                    ),
                ),
            }),
        ]);
    }
}
