<shared-dialog-frame title="vacation-list.addVacation">
    <h4>{{ 'vacation-list.add-vacation.info' | translate }}</h4>
    <form class="form" [formGroup]="formGroup" (keydown.enter)="save()">
        <div class="date-picker">
            <mat-form-field class="delivery-date-field" appearance="standard">
                <mat-label>{{ 'vacation-list.add-vacation.from' | translate }}</mat-label>
                <input
                    matInput
                    formControlName="from"
                    [matDatepicker]="fromPicker"
                    [matDatepickerFilter]="dateFilter"
                    [min]="fromMinDate | async"
                    [max]="fromMaxDate | async"
                    data-cy="vacation-from-input" />
                <mat-error *ngIf="formGroup.get('from').hasError('required')">
                    {{ 'shared.stateChangeMessages.requiredError' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroup.get('from').hasError('matDatepickerFilter')">
                    {{ 'vacation-list.add-vacation.existingDateError' | translate }}
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="delivery-date-field" appearance="standard">
                <mat-label>{{ 'vacation-list.add-vacation.to' | translate }}</mat-label>
                <input
                    matInput
                    formControlName="to"
                    [matDatepicker]="toPicker"
                    [matDatepickerFilter]="dateFilter"
                    [min]="toMinDate | async"
                    data-cy="vacation-to-input" />
                <mat-error *ngIf="formGroup.get('to').hasError('required')">
                    {{ 'shared.stateChangeMessages.requiredError' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroup.get('to').hasError('matDatepickerFilter')">
                    {{ 'vacation-list.add-vacation.existingDateError' | translate }}
                </mat-error>
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <mat-error *ngIf="overlapError">{{
            'vacation-list.add-vacation.overlapError' | translate
        }}</mat-error>
        <div class="delivery-addresses" *ngIf="deliveryAddresses?.length > 1">
            <mat-label>{{ 'vacation-list.add-vacation.validFor' | translate }}</mat-label>
            <mat-checkbox *ngFor="let da of deliveryAddresses" [formControlName]="da.shipToPartyId">
                {{
                    (da.name === accountName ? '' : da.name + ', ') +
                        da.address.street +
                        ' ' +
                        da.address.houseNumber +
                        ', ' +
                        da.address.postalCode +
                        ' ' +
                        da.address.city
                }}
            </mat-checkbox>
            <mat-error *ngIf="selectError">{{
                'vacation-list.add-vacation.selectError' | translate
            }}</mat-error>
        </div>
    </form>
    <ng-container actions>
        <button
            class="secondary-button"
            mat-dialog-close
            cdkFocusInitial
            type="button"
            mat-stroked-button>
            {{ 'shared.actions.cancel' | translate }}
        </button>
        <button
            mat-flat-button
            type="button"
            class="primary-button"
            (click)="save()"
            data-cy="confirm-vacation-save-button">
            {{ 'shared.actions.save' | translate }}
        </button>
    </ng-container>
</shared-dialog-frame>
