import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { UserRoleService } from '../../../../../../services/user-role.service';
import { VacationWithAffectedAddresses } from '../vacation-list.component';

@Component({
    selector: 'app-vacation-card',
    templateUrl: './vacation-card.component.html',
    styleUrls: ['./vacation-card.component.scss'],
})
export class VacationCardComponent implements OnInit {
    @Input() vacation: VacationWithAffectedAddresses;
    @Input() hasMultipleDeliveryAddresses: boolean;
    rolesBelowDealerAdmin$: Observable<string[]>;

    @Output() vacationDelete = new EventEmitter<VacationWithAffectedAddresses>();

    constructor(private userRoleService: UserRoleService) {}

    deleteVacation() {
        this.vacationDelete.emit(this.vacation);
    }

    ngOnInit(): void {
        this.rolesBelowDealerAdmin$ = this.userRoleService.getRolesBelowDealerAdmin$();
    }
}
