import { Component } from '@angular/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { MyStobagRedirectService, serviceUrls } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
    constructor(
        private redirect: MyStobagRedirectService,
        private subheaderService: MyStobagSubheaderService,
    ) {
        subheaderService.showBackButton('shared.navigation.dashboard', serviceUrls.dashboard, true);
        subheaderService.updatePageNameShowServiceName();
    }
}
