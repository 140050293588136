import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import {
    ActionCellDef,
    AuthenticationService,
    DialogService,
    serviceUrls,
    SnackbarType,
    StatusLabelCellDef,
    TableColumnDef,
    TextCellDef,
} from '@stobag/mystobag-shared';
import { combineLatest, Observable, of, Subject, throwError, timer } from 'rxjs';
import {
    catchError,
    debounceTime,
    delayWhen,
    distinctUntilChanged,
    map,
    retryWhen,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';

import { AccountFilterRequest } from '../../../models/account/account-filter-request';
import { AccountSummary } from '../../../models/account/account-summary';
import { AccountService } from '../../../services/account.service';
import { UserService } from '../../../services/user.service';
import { FilterRequest } from './account-list-filter/account-list-filter.component';

@Component({
    selector: 'app-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent implements OnInit {
    columnDefs: Array<TableColumnDef<AccountSummary>>;
    tableData$: Observable<AccountSummary[]>;
    countries$: Observable<string[]>;
    partnerStatuses$: Observable<string[]>;
    filterSubject: Subject<FilterRequest> = new Subject<FilterRequest>();
    sortSubject: Subject<Sort> = new Subject<Sort>();
    private pagingSubject = new Subject<PageEvent>();
    totalElements: number;
    pageIndex = 0;
    emptyIcon = 'user';

    constructor(
        private translateService: TranslateService,
        private userService: UserService,
        private accountService: AccountService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private dialogService: DialogService,
        subHeaderService: MyStobagSubheaderService,
    ) {
        subHeaderService.updatePageName('account-list.manageAccounts');
        subHeaderService.showBackButton('shared.navigation.dashboard', serviceUrls.dashboard, true);
    }

    ngOnInit(): void {
        this.columnDefs = this.getColumnDefs();
        this.countries$ = this.getCountries$();
        this.partnerStatuses$ = this.getPartnerStatuses$();
        this.tableData$ = combineLatest([
            this.filterSubject.asObservable().pipe(
                startWith({
                    hideNewAccounts: true,
                    hideDeactivatedAccounts: true,
                } as FilterRequest),
                debounceTime(50),
                distinctUntilChanged(),
                tap(() => {
                    this.pageIndex = 0;
                }),
            ),
            this.sortSubject.asObservable().pipe(
                startWith(null),
                distinctUntilChanged(),
                tap(sorting => (this.pageIndex = 0)),
            ),
            this.pagingSubject.asObservable().pipe(
                startWith(null),
                distinctUntilChanged(),
                tap(paging => {
                    this.pageIndex = paging?.pageIndex;
                }),
            ),
        ]).pipe(
            switchMap(([filterRequest, sort, paging]) => {
                return this.accountService
                    .getFilteredAccountSummaries(
                        this.mapFormFilterRequestToAccountFilterRequest(filterRequest),
                        sort,
                        paging,
                    )
                    .pipe(
                        catchError((error: unknown) => {
                            return this.translateService.get('account-list.loadingError').pipe(
                                switchMap(translatedErrorMessage => {
                                    this.dialogService.openSnackbar(
                                        translatedErrorMessage,
                                        SnackbarType.ERROR,
                                        7,
                                    );
                                    return of({ content: [], number: 0, totalElements: 0 });
                                }),
                            );
                        }),
                        map(response => {
                            this.pageIndex = response.number;
                            this.totalElements = response.totalElements;
                            return response.content;
                        }),
                    );
            }),
        );
    }

    private mapFormFilterRequestToAccountFilterRequest(
        filter: FilterRequest,
    ): AccountFilterRequest {
        return {
            hideNewAccount: filter.hideNewAccounts,
            hideDeactivatedAccount: filter.hideDeactivatedAccounts,
            searchKey: filter.search,
            salesOffices: filter.salesOffice,
            countries: filter.countries,
            partnerStatuses: filter.partnerStatus,
            contactPersonMail: filter.showOwnDealers
                ? this.authenticationService.getEmailAddress()
                : null,
        } as AccountFilterRequest;
    }

    onSortChange(sort: Sort) {
        this.sortSubject.next(sort);
    }

    onPagingChange(event: PageEvent) {
        this.pagingSubject.next(event);
    }

    enableGhostMode(account: AccountSummary) {
        this.accountService.enableGhostMode(account.name, account.accountNumber);
    }

    goToAccountDetails(element: AccountSummary) {
        const url = `/account/${element.accountNumber}`;
        this.router.navigateByUrl(url);
    }

    onFilterRequest(event: FilterRequest) {
        this.filterSubject.next(event);
    }

    downloadCsv() {
        this.userService.getCsv();
    }

    private getCountries$(): Observable<string[]> {
        return this.accountService.getAllDistinctCountries().pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleHttpErrorResponse(error);
                return throwError(error);
            }),
        );
    }

    private getPartnerStatuses$(): Observable<string[]> {
        return this.accountService.getAllowedPartnerStatuses().pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleHttpErrorResponse(error);
                return throwError(error);
            }),
        );
    }

    private handleHttpErrorResponse(error) {
        if (error.status === 403) {
            this.router.navigate(['forbidden']);
        } else {
            throw error;
        }
    }

    private getColumnDefs(): Array<TableColumnDef<AccountSummary>> {
        return [
            {
                columnDef: 'accountNumber',
                headerCellDef: 'account-list.table.accountNumber',
                cellDef: (accountSummary: AccountSummary) =>
                    new TextCellDef(accountSummary.accountNumber),
                sortable: true,
            },
            {
                columnDef: 'newAccount',
                headerCellDef: 'account-list.table.status',
                cellDef: (accountSummary: AccountSummary) => {
                    return accountSummary.active
                        ? accountSummary.newAccount
                            ? new StatusLabelCellDef(
                                  this.translateService.instant('accountStatus.newAccount'),
                                  { type: 'info' },
                              )
                            : new StatusLabelCellDef(
                                  this.translateService.instant('accountStatus.active'),
                                  { type: 'success' },
                              )
                        : new StatusLabelCellDef(
                              this.translateService.instant('accountStatus.deactivated'),
                              { type: 'warn' },
                          );
                },
            },
            {
                columnDef: 'name',
                headerCellDef: 'account-list.table.name',
                cellDef: (accountSummary: AccountSummary) => new TextCellDef(accountSummary.name),
                sortable: true,
            },
            {
                columnDef: 'country',
                headerCellDef: 'account.country',
                cellDef: (accountSummary: AccountSummary) =>
                    new TextCellDef(accountSummary.country),
                sortable: true,
            },
            {
                columnDef: 'salesOffice',
                headerCellDef: 'account.salesOffice',
                cellDef: (accountSummary: AccountSummary) => {
                    const salesOffice = accountSummary.salesOffice
                        ? `${accountSummary.salesOffice.code} - ${accountSummary.salesOffice.displayName}`
                        : '';
                    return new TextCellDef(salesOffice);
                },
            },
            {
                columnDef: 'partnerStatus',
                headerCellDef: 'account.partnerStatuses',
                cellDef: (accountSummary: AccountSummary) => {
                    const partnerStatus = this.transformPartnerStatuses(
                        accountSummary.partnerStatuses,
                    );
                    return new TextCellDef(partnerStatus);
                },
            },
            {
                columnDef: 'action',
                headerCellDef: '',
                cellDef: (accountSummary: AccountSummary) =>
                    new ActionCellDef([
                        {
                            icon: 'ghost',
                            isSvgIcon: true,
                            onClick: () => this.enableGhostMode(accountSummary),
                        },
                        {
                            icon: 'chevron-right',
                            onClick: event => {
                                event.preventDefault();
                                this.goToAccountDetails(accountSummary);
                            },
                            redirectUrl: `/account/${accountSummary.accountNumber}`,
                        },
                    ]),
                isActionColumn: true,
            },
        ];
    }

    private transformPartnerStatuses(partnerStatuses: string[]): string {
        return partnerStatuses?.length > 0
            ? partnerStatuses
                  .map(ps => this.translateService.instant(`account.partnerStatus.${ps}`))
                  .join(', ')
            : '-';
    }
}
