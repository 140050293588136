import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BannerNotificationConfig,
    BannerNotificationType,
    BannerService,
    MyStobagSubheaderService,
} from '@stobag/mystobag-header';
import {
    AccountNumberWithName,
    ConfigService,
    DialogService,
    LoadingSpinnerService,
    Permission,
    SnackbarType,
} from '@stobag/mystobag-shared';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Role } from '../../../models/user/role';
import { UserDTO } from '../../../models/user/user-dto';
import { AccountService } from '../../../services/account.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
    accountNumber: string;

    isUserDetailsValid = false;
    isEmailValid = false;

    userDetailsForm: FormGroup;
    emailForm: FormGroup;
    partnernetLoginForm: FormGroup;

    supportedLanguages$: Observable<string[]>;
    allowedRoles$: Observable<Role[]>;
    bDealerEnabled = false;
    connectedBDealerAccounts: AccountNumberWithName[] = [];

    private subscription = new Subscription();

    constructor(
        private subHeaderService: MyStobagSubheaderService,
        private userService: UserService,
        private accountService: AccountService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private activatedRoute: ActivatedRoute,
        private loadingSpinnerService: LoadingSpinnerService,
        private bannerService: BannerService,
        private router: Router,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
    ) {
        this.subHeaderService.updatePageName('addUser.title');
        this.bDealerEnabled = this.configService.getConfig().bDealerEnabled === true;
    }

    ngOnInit() {
        this.subscription.add(
            this.activatedRoute.params.subscribe(({ accountId }) => {
                this.accountNumber = accountId;
                this.subHeaderService.showBackButton(
                    'shared.navigation.account',
                    `/account/${accountId}`,
                    false,
                );
            }),
        );

        this.subscription.add(
            this.activatedRoute.params
                .pipe(
                    switchMap(({ accountId }) => this.accountService.getAccount(accountId)),
                    take(1),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 403) {
                            this.router.navigate(['forbidden']);
                        }
                        throw error;
                    }),
                )
                .subscribe({
                    next: account => (this.connectedBDealerAccounts = account.bDealerAccounts),
                }),
        );

        this.initializeForms();
        this.supportedLanguages$ = this.userService.getSupportedLanguagesByAccount(
            this.accountNumber,
        );
        this.allowedRoles$ = this.userService.getAllowedRolesCreate().pipe(
            // Only show BDealer option if Account has connected B Dealers
            map(roles =>
                roles.filter(
                    role =>
                        role !== Role.ROLE_DEALER_BDEALER ||
                        this.connectedBDealerAccounts?.length > 0,
                ),
            ),
        );
    }

    private initializeForms() {
        this.userDetailsForm = new FormGroup({
            titleCode: new FormControl(null, Validators.required),
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            contactLanguage: new FormControl(null),
            phone: new FormControl(null),
            mobile: new FormControl(null),
            functionCode: new FormControl(null),
            departmentCode: new FormControl(null),
        });
        this.emailForm = new FormGroup({
            enableDisableEmailInput: new FormControl(true),
            email: new FormControl(null, [Validators.required, Validators.pattern('.*@.*')]),
        });

        const roleControl = new FormControl(null, Validators.required);
        this.partnernetLoginForm = new FormGroup({
            enableDisablePartnernetLogin: new FormControl(true),
            role: roleControl,
            locale: new FormControl(null, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(2),
            ]),
        });

        this.subscription.add(
            roleControl.valueChanges.subscribe({
                next: role => {
                    if (role === Permission.BDealer) {
                        this.partnernetLoginForm.addControl(
                            'bDealerAccount',
                            new FormControl(null, [Validators.required]),
                        );
                    } else if (this.partnernetLoginForm.get('bDealerAccount')) {
                        this.partnernetLoginForm.removeControl('bDealerAccount');
                    }
                    this.cdr.markForCheck();
                },
            }),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    checkEmail() {
        if (!this.validateEmailForm()) {
            return;
        }

        const emailControl = this.emailForm.get('email');
        const newEmail = emailControl.value;
        this.subscription.add(
            this.loadingSpinnerService
                .withLoader(this.userService.checkEmail(newEmail), 'email-loader')
                .pipe(
                    tap(() => {
                        this.dialogService.openSnackbar(
                            this.translateService.instant('addUser.emailAlreadyRegistered'),
                            SnackbarType.ERROR,
                            5,
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 404) {
                            this.isEmailValid = true;
                            this.emailForm.disable();
                            return [];
                        } else {
                            this.dialogService.openSnackbar(
                                this.translateService.instant('addUser.error'),
                                SnackbarType.ERROR,
                                5,
                            );
                            throw error;
                        }
                    }),
                )
                .subscribe(),
        );
    }

    saveUserAndContact(): void {
        if (!this.validatePartnernetLoginForm()) {
            return;
        }

        const newUser = this.userDetailsForm.getRawValue() as UserDTO;
        if (this.partnernetLoginForm.get('role')?.value === Role.ROLE_DEALER_BDEALER) {
            newUser.accountNumber = this.partnernetLoginForm.get('bDealerAccount').value;
            newUser.parentAccountNumber = this.accountNumber;
        } else {
            newUser.accountNumber = this.accountNumber;
        }
        newUser.email = this.emailForm.get('enableDisableEmailInput').value
            ? this.emailForm.get('email').value
            : null;
        newUser.role = this.partnernetLoginForm.get('role').value;
        newUser.locale = this.partnernetLoginForm.get('locale').value;
        this.subscription.add(this.save(newUser, true).subscribe());
    }

    saveContact() {
        if (!this.validateEmailForm()) {
            return;
        }

        const newUser = this.userDetailsForm.getRawValue() as UserDTO;
        newUser.accountNumber = this.accountNumber;

        if (this.emailForm.get('enableDisableEmailInput').value) {
            newUser.email = this.emailForm.get('email').value;
        }
        this.subscription.add(this.save(newUser, false).subscribe());
    }

    private save(newUser: UserDTO, loginEnabled: boolean) {
        return this.userService.saveNewUser(newUser, loginEnabled).pipe(
            tap(user => {
                this.showUserCreationSuccessBanner(user);
                this.router.navigateByUrl(`/account/${this.accountNumber}`);
            }),
            catchError((error: HttpErrorResponse) => {
                this.handleError(error);
                return of();
            }),
        );
    }

    emailBack() {
        this.isUserDetailsValid = false;
        this.userDetailsForm.enable();
    }

    partnernetDetailsBack() {
        this.isEmailValid = false;
        this.emailForm.enable();
    }

    validateUserDetails() {
        if (this.userDetailsForm.invalid) {
            this.userDetailsForm.markAllAsTouched();
            this.userDetailsForm.updateValueAndValidity();
            this.isUserDetailsValid = false;
        } else {
            this.isUserDetailsValid = true;
            this.userDetailsForm.disable();
        }
    }

    private validateEmailForm(): boolean {
        if (
            (this.emailForm.get('enableDisableEmailInput').value as boolean) &&
            this.emailForm.invalid
        ) {
            this.emailForm.markAllAsTouched();
            this.emailForm.updateValueAndValidity();
            this.isEmailValid = false;
            return false;
        }
        return true;
    }

    private validatePartnernetLoginForm(): boolean {
        if (this.partnernetLoginForm.invalid) {
            this.partnernetLoginForm.markAllAsTouched();
            this.partnernetLoginForm.updateValueAndValidity();
            return false;
        }
        return true;
    }

    private handleError(error: HttpErrorResponse): void {
        console.error(error);
        this.dialogService.openSnackbar(
            this.translateService.instant('addUser.error'),
            SnackbarType.ERROR,
            5,
        );
    }

    private showUserCreationSuccessBanner(user) {
        this.bannerService.addNotification({
            closeable: true,
            type: BannerNotificationType.success,
            message: this.translateService.instant('addUser.userCreatedSuccess', {
                email: user.email ?? user.fullName,
            }),
            timeout: 10_000,
        } as BannerNotificationConfig);
    }
}
