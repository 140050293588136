import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-password-set',
    templateUrl: './password-set.component.html',
    styleUrls: ['./password-set.component.scss'],
})
export class PasswordSetComponent {
    constructor(private router: Router) {}

    backToLogin(): void {
        this.router.navigateByUrl('login');
    }
}
