<shared-subheader [secondary]="true"></shared-subheader>
<div class="content-container">
    <mat-card>
        <h3 class="header-title">
            <span class="step" [class.active]="!isUserDetailsValid">1</span>
            <span>{{ 'addUser.fillDetails' | translate }}</span>
        </h3>
        <app-user-details class="details" [userForm]="userDetailsForm"></app-user-details>
        <div fxLayout="row" fxLayoutAlign="end end" *ngIf="!isUserDetailsValid" class="action-row">
            <button
                mat-flat-button
                color="primary"
                class="primary-button"
                (click)="validateUserDetails()"
                data-cy="details-form-next-button">
                {{ 'addUser.next' | translate }}
            </button>
        </div>
    </mat-card>
    <mat-card>
        <h3 class="header-title" [class.disabled]="!isUserDetailsValid">
            <span class="step" [class.active]="isUserDetailsValid && !isEmailValid">2</span>
            <span>{{ 'addUser.emailAddress' | translate }}</span>
        </h3>
        <p *ngIf="isUserDetailsValid">
            {{ 'addUser.emailAddressDescription' | translate }}
        </p>
        <div ngxUiLoaderBlurred loaderId="email-loader" *ngIf="isUserDetailsValid">
            <ngx-ui-loader loaderId="email-loader"></ngx-ui-loader>
            <form class="form-field-container" [formGroup]="emailForm" fxLayout="column">
                <div class="email-address-toggle" fxLayout="row">
                    <mat-slide-toggle
                        color="primary"
                        id="emailAddressToggle"
                        formControlName="enableDisableEmailInput"
                        data-cy="email-toggle">
                        {{
                            (emailForm.get('enableDisableEmailInput').value
                                ? 'addUser.disableEmailAddress'
                                : 'addUser.enableEmailAddress'
                            ) | translate
                        }}
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row">
                    <mat-form-field
                        *ngIf="emailForm.get('enableDisableEmailInput').value"
                        class="email-input">
                        <mat-label>{{ 'addUser.email' | translate }}</mat-label>
                        <input matInput formControlName="email" data-cy="email-input" />
                        <shared-icon
                            *ngIf="isEmailValid"
                            matSuffix
                            class="ok"
                            icon="check"></shared-icon>
                        <mat-error *ngIf="emailForm.get('email').hasError('required')">
                            {{ 'shared.stateChangeMessages.requiredError' | translate }}
                        </mat-error>
                        <mat-error *ngIf="emailForm.get('email').hasError('pattern')">
                            {{ 'addUser.invalidEmailError' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <div
                fxLayout="row"
                fxLayoutAlign="space-between end"
                *ngIf="!isEmailValid"
                class="action-row">
                <button
                    mat-stroked-button
                    color="primary"
                    class="secondary-button"
                    (click)="emailBack()"
                    data-cy="email-back-button">
                    {{ 'addUser.back' | translate }}
                </button>
                <button
                    *ngIf="emailForm.get('enableDisableEmailInput').value; else createEmployee"
                    mat-flat-button
                    color="primary"
                    class="primary-button"
                    (click)="checkEmail()"
                    data-cy="email-next-button">
                    {{ 'addUser.next' | translate }}
                </button>
                <ng-template #createEmployee>
                    <button
                        mat-flat-button
                        color="primary"
                        class="primary-button"
                        (click)="saveContact()"
                        data-cy="create-contact-wo-email-button">
                        {{ 'addUser.createEmployee' | translate }}
                    </button>
                </ng-template>
            </div>
        </div>
    </mat-card>
    <mat-card *ngIf="emailForm.get('enableDisableEmailInput').value">
        <h3
            class="header-title"
            [class.disabled]="!isEmailValid"
            fxLayout="row"
            fxLayoutAlign="start center">
            <span class="step" [class.active]="isEmailValid">3</span>
            <span>{{ 'profile.partnerNetAccess' | translate }}</span>
        </h3>
        <ng-container *ngIf="isEmailValid">
            <form class="access-form" [formGroup]="partnernetLoginForm">
                <div class="partnernet-access-toggle">
                    <mat-slide-toggle
                        color="primary"
                        id="active"
                        formControlName="enableDisablePartnernetLogin"
                        data-cy="pnet-access-toggle">
                        {{
                            (partnernetLoginForm.get('enableDisablePartnernetLogin').value
                                ? 'addUser.disablePartnernetLogin'
                                : 'addUser.enablePartnernetLogin'
                            ) | translate
                        }}
                    </mat-slide-toggle>
                </div>
                <div
                    class="access-form-row"
                    *ngIf="partnernetLoginForm?.get('enableDisablePartnernetLogin')?.value">
                    <ng-container *ngIf="bDealerEnabled">
                        <app-user-role-selector
                            [roleControl]="$any(partnernetLoginForm.controls['role'])"
                            [allowedRoles]="allowedRoles$ | async"
                            data-cy="role-selector"></app-user-role-selector>
                        <mat-form-field
                            *ngIf="partnernetLoginForm.get('bDealerAccount')"
                            id="bDealerAccount"
                            ngxUiLoaderBlurred
                            loaderId="locale-loader">
                            <mat-label>{{ 'B Dealer' | translate }}</mat-label>
                            <mat-select
                                panelClass="drop-down-filters"
                                disableOptionCentering
                                formControlName="bDealerAccount"
                                data-cy="bdealer-account-select">
                                <mat-option
                                    *ngFor="let connectedBDealerAccount of connectedBDealerAccounts"
                                    [value]="connectedBDealerAccount.accountNumber"
                                    >{{ connectedBDealerAccount.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="
                                    partnernetLoginForm.get('bDealerAccount').hasError('required')
                                ">
                                {{ 'shared.stateChangeMessages.requiredError' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            id="preferredLanguage"
                            ngxUiLoaderBlurred
                            loaderId="locale-loader">
                            <mat-label>{{ 'profile.locale' | translate }}</mat-label>
                            <mat-select
                                panelClass="drop-down-filters"
                                disableOptionCentering
                                formControlName="locale"
                                data-cy="locale-select">
                                <mat-option
                                    *ngFor="let language of supportedLanguages$ | async"
                                    [value]="language"
                                    >{{ 'language.' + language | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="partnernetLoginForm.get('locale').hasError('required')">
                                {{ 'shared.stateChangeMessages.requiredError' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!bDealerEnabled">
                        <app-user-role-selector
                            [roleControl]="$any(partnernetLoginForm.controls['role'])"
                            [allowedRoles]="allowedRoles$ | async"
                            data-cy="role-selector"></app-user-role-selector>
                        <mat-form-field
                            id="preferredLanguage"
                            ngxUiLoaderBlurred
                            loaderId="locale-loader">
                            <mat-label>{{ 'profile.locale' | translate }}</mat-label>
                            <mat-select
                                panelClass="drop-down-filters"
                                disableOptionCentering
                                formControlName="locale"
                                data-cy="locale-select">
                                <mat-option
                                    *ngFor="let language of supportedLanguages$ | async"
                                    [value]="language"
                                    >{{ 'language.' + language | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="partnernetLoginForm.get('locale').hasError('required')">
                                {{ 'shared.stateChangeMessages.requiredError' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                </div>
            </form>
            <div fxLayout="row" fxLayoutAlign="space-between end" class="action-row">
                <button
                    mat-stroked-button
                    color="primary"
                    class="secondary-button"
                    (click)="partnernetDetailsBack()"
                    data-cy="partnernet-details-back-button">
                    {{ 'addUser.back' | translate }}
                </button>
                <button
                    *ngIf="partnernetLoginForm.get('enableDisablePartnernetLogin').value as bool"
                    mat-flat-button
                    color="primary"
                    class="primary-button"
                    (click)="saveUserAndContact()"
                    data-cy="create-pnet-user-button">
                    {{ 'addUser.createEmployee' | translate }}
                </button>
                <button
                    *ngIf="!partnernetLoginForm.get('enableDisablePartnernetLogin').value as bool"
                    mat-flat-button
                    color="primary"
                    class="primary-button"
                    (click)="saveContact()"
                    data-cy="create-contact-button">
                    {{ 'addUser.createEmployee' | translate }}
                </button>
            </div>
        </ng-container>
    </mat-card>
</div>
