import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';

import { passwordMatchValidator, regexValidator } from '../../../utils/form-validators';

@Component({
    selector: 'app-password-change-form',
    templateUrl: './password-change-form.component.html',
    styleUrls: ['./password-change-form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit {
    formGroup: FormGroup;
    isPasswordVisible = false;

    @Output() newPassword: EventEmitter<string | null> = new EventEmitter<string | null>();

    constructor(private translateService: TranslateService, private dialogService: DialogService) {}

    ngOnInit() {
        this.formGroup = new FormGroup(
            {
                newPassword: new FormControl('', [
                    Validators.minLength(8),
                    regexValidator('\\d', 'NUMBER'),
                    regexValidator('[A-Z]', 'CAPITAL_LETTER'),
                    regexValidator('[a-z]', 'SMALL_LETTER'),
                    regexValidator('[~!@#$%^&*()_\\-\\=/.,?><+\\[\\]{}\\\\]', 'SPECIAL_CHARACTER'),
                    Validators.required,
                ]),
                newPasswordAgain: new FormControl('', Validators.required),
            },
            {
                validators: passwordMatchValidator,
            },
        );
    }

    hasError(error: string): boolean {
        if (this.formGroup.get('newPassword').value) {
            return this.formGroup.get('newPassword').hasError(error) as boolean;
        }
        return true;
    }

    save() {
        if (this.formGroup.get('newPassword').invalid) {
            this.formGroup.get('newPassword').markAllAsTouched();
            this.formGroup.get('newPassword').updateValueAndValidity();
            if (this.formGroup.hasError('passwordMatchError')) {
                const message = this.translateService.instant('changePassword.passwordMatchError');
                this.dialogService.openSnackbar(message, SnackbarType.ERROR, 5);
            }
            this.newPassword.emit(null);
            return;
        }

        const newPassword = this.formGroup.get('newPassword').value;
        this.newPassword.emit(newPassword);
    }
}
