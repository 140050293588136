<mat-card>
    <h3>{{ 'profile.userDetails' | translate }}</h3>
    <form *ngIf="userForm" class="form">
        <app-user-details [userForm]="userForm"></app-user-details>
        <div
            class="padded-buttons"
            fxLayout="row"
            fxLayoutAlign="flex-end"
            *ngxPermissionsOnly="canEdit">
            <button
                *ngIf="!isUserReadOnly"
                mat-flat-button
                class="primary-button"
                (click)="saveUser()"
                data-cy="save-user-button">
                {{ 'profile.save' | translate }}
            </button>
        </div>
    </form>
</mat-card>
