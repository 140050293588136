import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription,tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CreateVacationRequest } from 'src/app/models/account/create-vacation-request';

import { DeliveryAddressDTO } from '../../../../../../models/account/delivery-address-dto';
import { AccountService } from '../../../../../../services/account.service';
import { VacationWithAffectedAddresses } from '../vacation-list.component';

@Component({
    selector: 'app-add-vacation-dialog',
    templateUrl: './add-vacation-dialog.component.html',
    styleUrls: ['./add-vacation-dialog.component.scss'],
})
export class AddVacationDialogComponent implements OnInit, OnDestroy {
    formGroup = new FormGroup({
        from: new FormControl(null, [Validators.required]),
        to: new FormControl(null, [Validators.required]),
    });
    fromMinDate: Observable<Date>;
    fromMaxDate: Observable<Date>;
    toMinDate: Observable<Date>;
    deliveryAddresses: DeliveryAddressDTO[];
    subscription = new Subscription();
    accountName;
    selectError = false;
    overlapError = false;
    dateFilter: (d: Date) => boolean;

    constructor(
        private matDialogRef: MatDialogRef<AddVacationDialogComponent>,
        private accountService: AccountService,
        @Inject(MAT_DIALOG_DATA)
        private data: {
            accountNumber: string;
            vacations$: Observable<VacationWithAffectedAddresses[]>;
        },
    ) {}

    ngOnInit() {
        const defaultFromDate = tomorrow();
        const defaultToDate = addDays(defaultFromDate, 1);
        this.toMinDate = this.formGroup.get('from').valueChanges.pipe(
            startWith(new Date()),
            tap(() => (this.overlapError = false)),
            map(value => (defaultToDate > value ? defaultToDate : value)),
        );
        this.fromMinDate = this.formGroup.get('from').valueChanges.pipe(
            startWith(new Date()),
            map(value => (defaultFromDate > value ? defaultFromDate : value)),
        );
        this.fromMaxDate = this.formGroup.get('to').valueChanges.pipe(
            startWith(null),
            tap(() => (this.overlapError = false)),
            map(value => (value ? value : null)),
        );
        this.subscription.add(
            this.accountService
                .getDeliveryAddresses(this.data.accountNumber)
                .subscribe(deliveryAddresses => {
                    this.deliveryAddresses = deliveryAddresses;
                    this.accountName =
                        deliveryAddresses.find(
                            address =>
                                Number.parseInt(address.shipToPartyId, 10) ===
                                Number.parseInt(this.data.accountNumber, 10),
                        )?.name || '';
                    if (deliveryAddresses.length === 1) {
                        this.formGroup.addControl(
                            deliveryAddresses[0].shipToPartyId,
                            new FormControl({ value: true, disabled: true }),
                        );
                    } else {
                        deliveryAddresses.forEach(address =>
                            this.formGroup.addControl(
                                address.shipToPartyId,
                                new FormControl(false),
                            ),
                        );
                    }
                    this.initDateFilter();
                }),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    private initDateFilter() {
        this.subscription.add(
            this.data.vacations$
                .pipe(
                    map(vacations => {
                        const selectedCustomerIds = (this.deliveryAddresses || [])
                            .filter(address => this.formGroup.get(address.shipToPartyId).value)
                            .map(address => address.shipToPartyId);

                        return this.getDateFilter(selectedCustomerIds, vacations);
                    }),
                )
                .subscribe({
                    next: dateFilter => (this.dateFilter = dateFilter),
                }),
        );
    }

    save(): void {
        this.selectError = false;
        this.overlapError = false;
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
            return;
        }

        const startDate = this.formGroup.get('from').value;
        const endDate = this.formGroup.get('to').value;
        const customerIds = this.deliveryAddresses
            .filter(address => this.formGroup.get(address.shipToPartyId).value)
            .map(address => address.shipToPartyId);
        if (customerIds.length < 1) {
            this.selectError = true;
            return;
        }

        this.data.vacations$
            .pipe(
                map(vacations => {
                    return customerIds.some(customerId =>
                        vacations.some(
                            vacation =>
                                new Date(vacation.startDate) <= endDate &&
                                new Date(vacation.endDate) >= startDate &&
                                vacation.customerId === customerId,
                        ),
                    );
                }),
            )
            .subscribe({
                next: vacationOverlaps => {
                    if (vacationOverlaps) {
                        this.overlapError = true;
                    } else {
                        const request = {
                            customerIds,
                            startDate,
                            endDate,
                        } as CreateVacationRequest;
                        this.matDialogRef.close(request);
                    }
                },
            });
    }

    private getDateFilter(
        selectedCustomerIds: string[],
        vacations: VacationWithAffectedAddresses[],
    ) {
        return (d: Date) => {
            const dateIsInVacationPeriod = vacations.some(vacation => {
                const startOfVacation = new Date(vacation.startDate);
                const endOfVacation = new Date(vacation.endDate);
                return (
                    startOfVacation <= d &&
                    endOfVacation >= d &&
                    selectedCustomerIds.includes(vacation.customerId)
                );
            });
            return !dateIsInVacationPeriod;
        };
    }
}

function tomorrow(): Date {
    const now = new Date();
    return addDays(now, 1);

}

function addDays(originalDate: Date, daysToAdd: number): Date {
    const result = new Date()
    result.setDate( originalDate.getDate() + daysToAdd);
    return result;
}