import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit,Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements AfterViewInit {
    username: string;
    code: string;
    verificationError = false;

    constructor(
        private verificationService: VerificationService,
        activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.username = activatedRoute.snapshot.queryParamMap.get('username');
        this.code = activatedRoute.snapshot.queryParamMap.get('code');
    }

    ngAfterViewInit() {
        this.verificationService.sendEmailChangeVerification(this.username, this.code).subscribe(
            () => this.router.navigate(['login']),
            (error: HttpErrorResponse) => (this.verificationError = true),
        );
    }
}
