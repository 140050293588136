import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ActionCellDef,
    IconCellDef,
    Permission,
    serviceUrls,
    StatusLabelCellDef,
    STOBAG_PERMISSIONS,
    TableColumnDef,
    TextCellDef,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserSource } from '../../../../enums/user-source';
import { UserDTO } from '../../../../models/user/user-dto';
import { UserService } from '../../../../services/user.service';
import { UserRoleService } from '../../../../services/user-role.service';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent implements OnInit {
    columnDefs$: Observable<Array<TableColumnDef<UserDTO>>>;
    users$: Observable<UserDTO[]>;
    userServiceAvailable = true;
    userServiceError: string;
    accountNumber: string;
    stobagOrDealerAdminPermissions = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];
    emptyIcon = 'user';
    areRowsClickable$: Observable<boolean>;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private ngxPermissionsService: NgxPermissionsService,
        private translateService: TranslateService,
        private userRoleService: UserRoleService,
    ) {}

    ngOnInit(): void {
        const accountNumber = this.route.parent.snapshot.paramMap.get('accountNumber');
        this.accountNumber = accountNumber;
        this.columnDefs$ = this.getColumnDefs$();
        this.users$ = this.userService.getUsersOfAccount(accountNumber).pipe(
            catchError((error: HttpErrorResponse) => {
                this.userServiceAvailable = false;
                if (error.status === 403) {
                    this.router.navigate(['forbidden']);
                } else {
                    this.userServiceAvailable = false;
                    this.userServiceError = error.error;
                }
                return throwError(error);
            }),
        );
        this.areRowsClickable$ = this.userRoleService.userHasHigherOrEqualRoleThanDealerAdmin$();
    }

    navigateToUser(user: UserDTO) {
        const url = this.getUserUrl(user);
        this.router.navigateByUrl(url);
    }

    navigateToCreateUser(): void {
        this.router.navigateByUrl(`/user/create/${this.accountNumber}`);
    }

    private getUserUrl(user: UserDTO) {
        return user.userSource === UserSource.Contact
            ? `contact/profile/${user.contactObjectId}`
            : `${serviceUrls.userProfile}/${user.username}`;
    }

    private getColumnDefs$(): Observable<Array<TableColumnDef<UserDTO>>> {
        return from(
            this.ngxPermissionsService.hasPermission(this.stobagOrDealerAdminPermissions),
        ).pipe(
            map(hasPermission => {
                return [
                    {
                        columnDef: 'icon',
                        headerCellDef: '',
                        cellDef: (user: UserDTO) =>
                            new IconCellDef('user', {
                                isOutlined: true,
                                isRowIcon: true,
                            }),
                        isIconColumn: true,
                    },
                    {
                        columnDef: 'name',
                        headerCellDef: 'user-list.table.name',
                        sortable: true,
                        cellDef: (user: UserDTO) => new TextCellDef(user.name),
                    },
                    {
                        columnDef: 'email',
                        headerCellDef: 'user-list.table.email',
                        sortable: true,
                        cellDef: (user: UserDTO) => new TextCellDef(user.email),
                    },
                    {
                        columnDef: 'phone',
                        headerCellDef: 'user-list.table.phone',
                        cellDef: (user: UserDTO) => new TextCellDef(user.phone),
                    },
                    {
                        columnDef: 'mobile',
                        headerCellDef: 'user-list.table.mobile',
                        cellDef: (user: UserDTO) => new TextCellDef(user.mobile),
                    },
                    {
                        columnDef: 'role',
                        headerCellDef: 'user-list.table.role',
                        sortable: true,
                        cellDef: (user: UserDTO) =>
                            new StatusLabelCellDef(
                                this.translateService.instant(
                                    `profile.${user.role ?? 'withoutRole'}`,
                                ),
                                user.role ? { type: 'info' } : {},
                            ),
                    },
                    {
                        columnDef: 'action',
                        headerCellDef: '',
                        cellDef: (user: UserDTO) => {
                            const redirectUrl = this.getUserUrl(user);
                            return new ActionCellDef(
                                hasPermission
                                    ? [
                                          {
                                              icon: 'chevron-right',
                                              onClick: event => {
                                                  event.preventDefault();
                                                  this.navigateToUser(user);
                                              },
                                              redirectUrl,
                                          },
                                      ]
                                    : [],
                            );
                        },
                        isActionColumn: true,
                    },
                ];
            }),
        );
    }
}
