<ng-container *ngIf="account$ | async as account">
    <shared-subheader class="with-search-bar">
        <app-account-status-label
            *stobagOnly
            featureHighlight
            [account]="account"
            title-suffix></app-account-status-label>
    </shared-subheader>

    <div class="content-container">
        <app-account-header [account]="account"></app-account-header>
        <nav mat-tab-nav-bar>
            <a
                mat-tab-link
                *ngFor="let tab of enabledTabs"
                [routerLink]="tab.link"
                [attr.data-cy]="tab.cyAttrName"
                [active]="(activeTab$ | async) === tab.link">
                {{ tab.label | translate }}
            </a>
        </nav>
        <router-outlet></router-outlet>
    </div>
</ng-container>
