<app-content-card *ngIf="!maintenanceModeOn; else maintenanceModeCard">
    <form class="form content-block" [formGroup]="loginForm" fxLayout="column">
        <h1>{{ 'login.title' | translate }}</h1>
        <p>{{ 'login.description' | translate }}</p>
        <div fxLayout="column">
            <mat-form-field fxFlex="auto" appearance="standard">
                <mat-label>{{ 'login.email' | translate }}</mat-label>
                <shared-icon
                    matPrefix
                    class="icon-prefix-padding"
                    [fontSet]="'material-icons-outlined'"
                    icon="user"></shared-icon>
                <input
                    matInput
                    id="input-username"
                    type="text"
                    size="30"
                    formControlName="username"
                    placeholder="{{ 'login.email' | translate }}"
                    autocomplete="email" />
                <mat-error *ngIf="loginForm.get('username').invalid">
                    {{ 'shared.stateChangeMessages.requiredError' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="auto" appearance="standard">
                <mat-label>{{ 'login.password' | translate }}</mat-label>
                <shared-icon
                    matPrefix
                    class="icon-prefix-padding"
                    [fontSet]="'material-icons-outlined'"
                    icon="lock"></shared-icon>
                <input
                    matInput
                    id="input-password"
                    formControlName="password"
                    [type]="isPasswordVisible ? 'text' : 'password'"
                    placeholder="{{ 'login.password' | translate }}"
                    autocomplete="current-password" />
                <button
                    mat-icon-button
                    matSuffix
                    type="button"
                    (click)="isPasswordVisible = !isPasswordVisible">
                    <shared-icon [icon]="isPasswordVisible ? 'eye-off' : 'eye'"> </shared-icon>
                </button>
                <mat-error *ngIf="loginForm.get('password').invalid">
                    {{ 'shared.stateChangeMessages.requiredError' | translate }}
                </mat-error>
            </mat-form-field>
            <div fxFlex="auto">
                <div *ngIf="showError" class="error-element">
                    {{ 'login.' + errorMessage | translate }}
                </div>
            </div>
            <div fxFlex="auto" fxLayoutAlign="flex-end">
                <button
                    mat-flat-button
                    data-cy="loginButton"
                    id="button-login"
                    class="primary-button submit-button"
                    (click)="onLoginClick()">
                    {{ 'login.login' | translate }}
                </button>
            </div>
        </div>
        <div fxFlex="auto">
            <div
                class="textlink"
                (click)="navigateToPasswordReset()"
                data-cy="forgotPasswordButton">
                {{ 'login.forgotPassword' | translate }}
            </div>
        </div>
        <div class="secondary-text">{{ 'login.supportedBrowser' | translate }}</div>
    </form>
    <div
        *ngIf="loginVideoConfig?.enabled"
        class="content-block"
        fxLayout="column"
        fxLayoutAlign="center center">
        <div class="video-container">
            <app-video-player></app-video-player>
        </div>
    </div>
</app-content-card>
<ng-template #maintenanceModeCard>
    <app-content-card>
        <div>
            <h2>{{ 'maintenance.title' | translate }}</h2>
            <p>
                {{
                    'maintenance.message'
                        | translate
                            : {
                                  date: (maintenanceUntil | sharedDatePipe: 'fullDate'),
                                  time: (maintenanceUntil | sharedDatePipe: 'shortTime')
                              }
                }}
            </p>
        </div>
    </app-content-card>
</ng-template>
