import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    CustomDatePipe,
    DialogService,
    LoadingSpinnerService,
    Permission,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { catchError, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { UserSource } from '../../../../enums/user-source';
import { UserDTO } from '../../../../models/user/user-dto';
import { UserService } from '../../../../services/user.service';
import { ChangeEmailDialogComponent } from './change-email-dialog/change-email-dialog.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';

@Component({
    selector: 'app-login-data-card',
    templateUrl: './login-data-card.component.html',
    styleUrls: ['./login-data-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDataCardComponent implements OnDestroy, OnInit {
    @Input() set user(user: UserDTO) {
        if (user) {
            this._user = user;
            if (this.dataForm) {
                this.emailVerified = user.emailVerified;
                const emailFormControl = this.dataForm.get('email');
                emailFormControl.setValue(user.email);
                emailFormControl.markAsPristine();

                if (user.email) {
                    emailFormControl.disable();
                }

                this.setLastLogin(user.lastLogin);
                this.userHasPartnernetAccess = [
                    UserSource.Merged,
                    UserSource.PartnerNetUser,
                ].includes(user.userSource);
            }
        }
    }

    _user: UserDTO;
    lastLoginMessage$: Observable<string>;
    dataForm: FormGroup;
    emailVerified = false;
    userHasPartnernetAccess: boolean;

    setLastLogin(lastLogin: Date) {
        if (!lastLogin) {
            this.lastLoginMessage$ = of('');
            return;
        }

        const date = this.customDatePipe.transform(lastLogin, 'shortDate');
        const time = this.customDatePipe.transform(lastLogin, 'shortTime');
        this.lastLoginMessage$ = this.translateService.get('profile.lastLogin', { date, time });
    }
    canEdit = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];

    @Output() emailChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() passwordResetInitiated: EventEmitter<string> = new EventEmitter<string>();
    private subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private userService: UserService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private customDatePipe: CustomDatePipe,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {}

    ngOnInit(): void {
        this.emailVerified = this._user.emailVerified;
        this.dataForm = new FormGroup({
            email: new FormControl(
                { value: this._user.email, disabled: Boolean(this._user.email) },
                [Validators.required, Validators.pattern('.*@.*')],
            ),
            password: new FormControl({ value: '00000', disabled: true }),
        });
        this.setLastLogin(this._user.lastLogin);
        this.userHasPartnernetAccess = [UserSource.Merged, UserSource.PartnerNetUser].includes(
            this._user.userSource,
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    changePassword(): void {
        this.subscription.add(
            this.dialog
                .open(ChangePasswordDialogComponent)
                .afterClosed()
                .pipe(
                    filter(passwords => passwords?.newPassword && passwords?.oldPassword),
                    switchMap(({ oldPassword, newPassword }) =>
                        this.userService.changePassword(oldPassword, newPassword),
                    ),
                )
                .subscribe(
                    () => {
                        const message = this.translateService.instant('changePassword.success');
                        this.dialogService.openSnackbar(message, SnackbarType.SUCCESS, 5);
                    },
                    (error: HttpErrorResponse) => {
                        this.dialogService.openSnackbar(error.error, SnackbarType.ERROR, 7);
                    },
                ),
        );
    }

    initiatePasswordReset() {
        const mail = this.dataForm.get('email').value;
        this.passwordResetInitiated.emit(mail);
    }

    changeEmail(): void {
        this.subscription.add(
            this.dialog
                .open(ChangeEmailDialogComponent)
                .afterClosed()
                .pipe(
                    filter(newEmail => Boolean(newEmail)),
                    switchMap(newEmail => {
                        const message = this.translateService.instant(
                            'changeEmail.newEmailConfirmation',
                            { email: newEmail },
                        );
                        return this.dialogService
                            .openConfirmationDialog(message)
                            .afterClosed()
                            .pipe(
                                filter(confirmation => Boolean(confirmation)),
                                map(() => newEmail),
                            );
                    }),
                )
                .subscribe(newEmail => this.emailChanged.emit(newEmail)),
        );
    }

    saveEmail() {
        const emailFormControl = this.dataForm.get('email');

        if (emailFormControl.invalid) {
            emailFormControl.markAsTouched();
            emailFormControl.updateValueAndValidity();
            return;
        }

        const newEmail = emailFormControl.value;
        this.subscription.add(
            this.loadingSpinnerService
                .withLoader(this.userService.checkEmail(newEmail), 'check-email-loader')
                .pipe(
                    tap(() => {
                        this.dialogService.openSnackbar(
                            this.translateService.instant('addUser.emailAlreadyRegistered'),
                            SnackbarType.ERROR,
                            5,
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 404) {
                            this.emailChanged.emit(newEmail);
                            return of(null);
                        } else {
                            this.dialogService.openSnackbar(
                                this.translateService.instant('addUser.error'),
                                SnackbarType.ERROR,
                                5,
                            );
                            return throwError(error);
                        }
                    }),
                )
                .subscribe(),
        );
    }
}
