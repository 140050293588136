<mat-card>
    <h3>{{ 'profile.emailAddress' | translate }}</h3>
    <div class="last-login-info">{{ lastLoginMessage$ | async }}</div>
    <form class="form" [formGroup]="dataForm">
        <mat-form-field class="full-width">
            <mat-label>{{ 'profile.email' | translate }}</mat-label>
            <input matInput type="text" formControlName="email" />
            <shared-icon
                *ngIf="emailVerified"
                matSuffix
                icon="shield-check"></shared-icon>
            <mat-error *ngIf="dataForm.get('email').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="dataForm.get('email').hasError('pattern')">
                {{ 'changeEmail.invalidEmailError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" *ngIf="userHasPartnernetAccess">
            <mat-label>{{ 'profile.password' | translate }}</mat-label>
            <input matInput formControlName="password" type="password" />
        </mat-form-field>
    </form>
    <div fxLayout="row" fxLayoutAlign="flex-end flex-end" fxLayoutGap="1.5rem">
        <ng-container *ngIf="emailVerified">
            <button
                *stobagOnly
                mat-stroked-button
                id="password-reset-button"
                class="secondary-button"
                (click)="initiatePasswordReset()">
                {{ 'passwordReset.title' | translate }}
            </button>
            <button
                mat-flat-button
                id="change-password-button"
                class="primary-button"
                (click)="changePassword()">
                {{ 'profile.changePassword' | translate }}
            </button>
        </ng-container>
        <ng-container *ngxPermissionsOnly="canEdit">
            <button
                *ngIf="_user.email; else contactWithoutEmail"
                mat-flat-button
                class="primary-button"
                (click)="changeEmail()">
                {{ 'profile.changeEmail' | translate }}
            </button>
            <ng-template #contactWithoutEmail>
                <button mat-flat-button class="primary-button" (click)="saveEmail()">
                    {{ 'profile.save' | translate }}
                </button>
            </ng-template>
        </ng-container>
    </div>
</mat-card>
