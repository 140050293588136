import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'app-delivery-address-confirmation',
    templateUrl: './delivery-address-confirmation.component.html',
})
export class DeliveryAddressConfirmationComponent {
    accountNumber$ = this.activatedRoute.parent.paramMap.pipe(
        map(paramMap => paramMap.get('accountNumber')),
    );
    confirmationMessageTranslationKey$: Observable<string> = this.activatedRoute.url.pipe(
        map(url => url[0].path),
        map(confirmationType => this.getTranslationKeyByConfirmationType(confirmationType)),
    );

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    onNavigateToAccount(accountNumber: string) {
        this.router.navigateByUrl(`account/${accountNumber}/delivery-address-list`);
    }

    getTranslationKeyByConfirmationType(confirmationType: string): string {
        switch (confirmationType) {
            case 'create-confirmation':
                return 'delivery-address-confirmation.createSuccessMessage';
            case 'edit-confirmation':
                return 'delivery-address-confirmation.editSuccessMessage';
            case 'delete-confirmation':
                return 'delivery-address-confirmation.deleteSuccessMessage';
        }
    }
}
