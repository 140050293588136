import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import {
    AccountDTO,
    AuthenticationService,
    ConfigService,
    SalesContextDTO,
    SalesOfficeDTO,
    serviceUrls,
} from '@stobag/mystobag-shared';
import { STOBAG_PERMISSIONS } from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { concat, Observable, take, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { AccountService } from '../../../services/account.service';

enum CompanySettingsTab {
    EmployeeList = 'employee-list',
    absencesList = 'absences-list',
    deliveryAddressList = 'delivery-address-list',
}

interface Tab {
    link: CompanySettingsTab;
    label: string;
    cyAttrName: string;
}

const possibleTabs: Tab[] = [
    {
        link: CompanySettingsTab.EmployeeList,
        label: 'user-list.title',
        cyAttrName: 'company-settings-list-tab',
    },
    {
        link: CompanySettingsTab.absencesList,
        label: 'vacation-list.title',
        cyAttrName: 'vacation-list-tab',
    },
    {
        link: CompanySettingsTab.deliveryAddressList,
        label: 'delivery-address-list.title',
        cyAttrName: 'delivery-address-list-tab',
    },
]

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit {
    allowedPartnerStatuses: string[];
    salesContext: SalesContextDTO;
    salesOffice: SalesOfficeDTO;
    account$: Observable<AccountDTO>;
    activeTab$ = concat(
        this.activatedRoute.firstChild.url.pipe(take(1)),
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
    ).pipe(map(() => this.activatedRoute.firstChild?.snapshot.url[0].path));

    enabledTabs = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private router: Router,
        private subHeaderService: MyStobagSubheaderService,
        configService: ConfigService,
        private ngxPermissionsService: NgxPermissionsService
    ) {
        this.subHeaderService.updatePageName('account.title');
        this.subHeaderService.hideBackButton();
        const vacationListEnabled = configService.getConfig().vacationListEnabled === true;
        this.enabledTabs = possibleTabs
            .filter(tab => CompanySettingsTab.absencesList !== tab.link || vacationListEnabled);
    }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if(STOBAG_PERMISSIONS.some(permission =>this.ngxPermissionsService.getPermissions()[permission])) {
            this.subHeaderService.showBackButton('shared.navigation.account', `/account`, false);
        } else {
            this.subHeaderService.showBackButton('shared.navigation.dashboard', serviceUrls.dashboard, true);
        }
        const accountNumber = this.activatedRoute.snapshot.paramMap.get('accountNumber');
        this.accountService
            .getAllowedPartnerStatuses()
            .subscribe(values => (this.allowedPartnerStatuses = values));
        this.account$ = this.accountService.getAccount(accountNumber).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleHttpErrorResponse(error);
                return throwError(error);
            }),
            tap(account => this.subHeaderService.updatePageName(account.name)),
        );
    }

    transformSalesItem(salesItem: SalesContextDTO | SalesOfficeDTO): string {
        if (salesItem != null) {
            return `${salesItem.code} - ${salesItem.displayName}`;
        }
        return null;
    }

    private handleHttpErrorResponse(error) {
        if (error.status === 403) {
            this.router.navigateByUrl('forbidden');
        } else {
            throw error;
        }
    }
}
