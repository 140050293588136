import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    DialogService,
    MyStobagRedirectService,
    serviceUrls,
    SnackbarType,
} from '@stobag/mystobag-shared';
import { Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Authentication } from '../../../models/authentication/authentication';
import { TokenService } from '../../../services/token.service';

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnDestroy {
    username: string;
    sessionId: string;
    returnUrl: string;
    isOldPasswordVisible = false;
    oldPasswordControl = new FormControl(null, [Validators.required]);

    private subscription = new Subscription();

    constructor(
        activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private tokenService: TokenService,
        private redirectService: MyStobagRedirectService,
    ) {
        this.username = activatedRoute.snapshot.paramMap.get('username');
        this.sessionId = activatedRoute.snapshot.paramMap.get('sessionId');
        this.returnUrl = activatedRoute.snapshot.queryParamMap.get('returnUrl');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setNewPassword(newPassword: string): void {
        if (!newPassword || this.oldPasswordControl.invalid) {
            this.oldPasswordControl.markAsTouched();
            this.oldPasswordControl.updateValueAndValidity();
            return;
        }
        const oldPassword = this.oldPasswordControl.value;
        const authenticationObject = new Authentication(
            this.username,
            oldPassword,
            newPassword,
            this.sessionId,
        );

        this.subscription.add(
            this.tokenService
                .getJwtToken(authenticationObject)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 403) {
                            const errorMsg = this.translateService.instant(
                                'login.expiredForcePasswordChangeMsg',
                            );
                            this.dialogService.openSnackbar(errorMsg, SnackbarType.ERROR, 30);
                        }
                        return throwError(error);
                    }),
                    tap(res => this.handleToken(res.headers.get('Authorization'))),
                )
                .subscribe(),
        );
    }

    cancel(): void {
        this.router.navigateByUrl('login');
    }

    private handleToken(token: string) {
        if (token) {
            localStorage.setItem('token', token);
            if (this.returnUrl) {
                this.redirectService.navigateToExternalUrl(this.returnUrl);
            } else {
                this.redirectService.navigateToService(serviceUrls.dashboard);
            }
        } else {
            const errorMessage = this.translateService.instant('changePassword.unknownError');
            this.dialogService.openSnackbar(errorMessage, SnackbarType.ERROR, 7);
        }
    }
}
