<ng-container *ngIf="vacations$ | async as vacations">
    <div *ngIf="vacations.length === 0" class="space-around">
        {{ 'vacation-list.noEntries' | translate }}
    </div>
    <app-vacation-card
        *ngFor="let vacation of vacations"
        [vacation]="vacation"
        [hasMultipleDeliveryAddresses]="deliveryAddresses?.length > 1"
        (vacationDelete)="onDeleteVacation($event)"></app-vacation-card>
</ng-container>
<div *ngIf="loadError" class="space-around">
    {{ 'vacation-list.vacationLoadError' | translate }}
</div>
