import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, ConfigService } from '@stobag/mystobag-shared';
import { merge, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { LoginVideoConfig } from '../video-player/video-player.component';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
    availableLanguages: Array<{ key: string; label: string }>;
    selectedLanguage: FormControl;

    private subscription = new Subscription();

    constructor(
        configService: ConfigService,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {
        const nullObject = { enabled: false, urls: [] } as LoginVideoConfig;
        const loginVideoConfig = configService.getConfig().loginVideo
            ? configService.getConfig().loginVideo
            : nullObject;
        this.availableLanguages = Object.keys(loginVideoConfig.urls).map(lang => ({
            key: lang,
            label: lang.toUpperCase(),
        }));
    }

    ngOnInit(): void {
        this.selectedLanguage = new FormControl(
            this.translate.currentLang ?? this.translate.defaultLang,
        );
        this.subscription.add(
            merge(
                this.activatedRoute.queryParams.pipe(
                    tap(({ lang }) => {
                        const language =
                            lang ??
                            this.authenticationService.extractLocale() ??
                            this.translate.currentLang ??
                            this.translate.defaultLang;
                        if (this.translate.getLangs().includes(language)) {
                            this.selectedLanguage.setValue(language);
                        }
                    }),
                ),
                this.selectedLanguage.valueChanges.pipe(
                    distinctUntilChanged(),
                    tap(newLang => this.changeLanguage(newLang)),
                ),
            ).subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private changeLanguage(language: string) {
        this.translate.use(language);
    }
}
