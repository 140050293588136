import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export function regexValidator(nameRe: string, error: string): ValidatorFn {
    return (control: AbstractControl): Record<string, string> | null => {
        const regexTest = new RegExp(nameRe).test(control.value);
        return regexTest
            ? null
            : {
                  [error]: control.value,
                  passwordRequirements: control.value,
              };
    };
}

export function passwordMatchValidator(group: FormGroup) {
    const isValid = group.get('newPassword').value === group.get('newPasswordAgain').value;
    return isValid
        ? null
        : {
              passwordMatchError: true,
          };
}
