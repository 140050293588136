import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SalesContextDTO } from '@stobag/mystobag-basket-shared';
import { NotificationSubscriptionSettings } from '@stobag/mystobag-notification-shared';
import {
    ConfigService,
    DialogService,
    LoadingSpinnerService,
    Permission,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { catchError, combineLatest, from, Observable, of, shareReplay,Subject, Subscription, switchMap } from 'rxjs';
import { NotificationType } from 'src/app/enums/notification-type';
import { NotificationSettingsService } from 'src/app/services/notification-settings.service';

export const notificationTypes = [
    NotificationType.LeadAssigned,
    NotificationType.OrderDelayed,
    NotificationType.BasketOrdered,
    NotificationType.ReservationApproved,
    NotificationType.BasketItemIvalidated,
    NotificationType.NewsCreatedByGraphCMS,
];

@Component({
    selector: 'app-notification-settings-card',
    templateUrl: './notification-settings-card.component.html',
    styleUrls: ['./notification-settings-card.component.scss'],
})
export class NotificationSettingsCardComponent implements AfterViewInit, OnDestroy {
    NotificationType = NotificationType;

    @Input() email: string;
    @Input() mobile: string;
    @Input() salesContext: SalesContextDTO;

    settings: Record<string, NotificationSubscriptionSettings>;
    displayedNotificationTypes: NotificationType[];
    emailNotificationsEnabled = false;
    smsNotificationSettingsEnabled = false;
    basketOrderedMessageEnabled = false;
    orderDelayedMessageEnabled = false;
    reservationApprovedEnabled = false;
    canEdit = false;

    subscription = new Subscription();

    private subscriptionSettingsForm = new Subject<FormGroup>();
    subscriptionSettingsForm$: Observable<FormGroup> = this.subscriptionSettingsForm.pipe(shareReplay(1));

    constructor(
        private notificationSettingsService: NotificationSettingsService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private permissionService: NgxPermissionsService,
        private loadingSpinnerService: LoadingSpinnerService,
        configService: ConfigService,
    ) {
        this.emailNotificationsEnabled =
            configService.getConfig().emailNotificationsEnabled === true;
        this.smsNotificationSettingsEnabled =
            configService.getConfig().smsNotificationSettingsEnabled === true;
        this.orderDelayedMessageEnabled =
            configService.getConfig().orderDelayedMessageEnabled === true;
        this.basketOrderedMessageEnabled =
            configService.getConfig().basketOrderedMessageEnabled === true;
        this.reservationApprovedEnabled =
            configService.getConfig().reservationApprovedEnabled === true;
    }

    ngAfterViewInit() {
        this.subscription.add(
            combineLatest([
                from(
                    this.permissionService.hasPermission([
                        ...STOBAG_PERMISSIONS,
                        Permission.DealerAdmin,
                    ]),
                ),
                this.notificationSettingsService.getNotificationSettingsOfUser(this.email).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.error instanceof Error) {
                            console.error('An error occurred:', error.error.message);
                        }
                        return of({});
                    }),
                ),
            ]).subscribe(([canEdit, notificationSettings]) => {
                this.canEdit = canEdit;
                this.settings = notificationSettings;
                this.displayedNotificationTypes = notificationTypes.filter(
                    type =>
                        !(
                            (NotificationType.OrderDelayed === type &&
                                !this.orderDelayedMessageEnabled) ||
                            (NotificationType.BasketOrdered === type &&
                                !this.basketOrderedMessageEnabled) ||
                            (NotificationType.ReservationApproved === type &&
                                !this.reservationApprovedEnabled) ||
                            [
                                NotificationType.NewsCreatedByGraphCMS,
                                NotificationType.BasketItemIvalidated,
                            ].includes(type)
                        ),
                );
                this.subscriptionSettingsForm.next(this.buildForm(notificationSettings, canEdit));
            }),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private buildForm(
        notificationSettings: Record<string, NotificationSubscriptionSettings>,
        canEdit: boolean,
    ): FormGroup {
        const formGroup = new FormGroup({});
        notificationTypes.forEach(notificationType => {
            const innerFormGroup = new FormGroup({
                subscribedToMail: new FormControl(
                    {
                        value: notificationSettings[notificationType]?.subscribedToMail || false,
                        disabled: !canEdit,
                    },
                    Validators.required,
                ),
                subscribedToNotification: new FormControl(
                    {
                        value:
                            notificationSettings[notificationType]?.subscribedToNotification ||
                            false,
                        disabled: !canEdit,
                    },
                    Validators.required,
                ),
                subscribedToSms: new FormControl(
                    {
                        value: notificationSettings[notificationType]?.subscribedToSms || false,
                        disabled: !canEdit || !this.mobile || this.salesContext.code === 1500,
                    },
                    Validators.required,
                ),
            });
            formGroup.addControl(notificationType, innerFormGroup);
        });
        return formGroup;
    }

    saveSettings() {

        this.subscription.add(
            this.subscriptionSettingsForm$.pipe(
                switchMap((subscriptionSettingsForm: FormGroup) => {
                    const requestBody = {
                        userEmail: this.email,
                        settings: subscriptionSettingsForm.getRawValue(),
                    };
                    return this.notificationSettingsService.updateUserNotificationSettings(this.email, requestBody)
                })
            ).subscribe(
                () => {
                    const msg = this.translate.instant(
                        'profile.notificationSettings.updatedSuccessfully',
                    );
                    this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                },
                () => {
                    const msg = this.translate.instant(
                        'profile.notificationSettings.updateError',
                    );
                    this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                },
            )
        );
    }
}
