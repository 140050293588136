import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionCellDef, IconCellDef, TableColumnDef, TextCellDef } from '@stobag/mystobag-shared';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { DeliveryAddressDTO } from '../../../../models/account/delivery-address-dto';
import { AccountService } from '../../../../services/account.service';
import { UserRoleService } from '../../../../services/user-role.service';

@Component({
    selector: 'app-delivery-address-list',
    templateUrl: './delivery-address-list.component.html',
    styleUrls: ['./delivery-address-list.component.scss'],
})
export class DeliveryAddressListComponent implements OnInit {
    columnDefs$: Observable<Array<TableColumnDef<DeliveryAddressDTO>>>;
    addresses$: Observable<DeliveryAddressDTO[]>;
    rolesBelowDealerAdmin$: Observable<string[]>;
    emptyIcon = 'map-pin';

    private accountNumber: string;
    private listRefresh = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private userRoleService: UserRoleService,
    ) {}

    ngOnInit() {
        this.accountNumber = this.route.parent.snapshot.paramMap.get('accountNumber');
        this.addresses$ = this.listRefresh.asObservable().pipe(
            startWith(null),
            switchMap(() => this.accountService.getDeliveryAddresses(this.accountNumber)),
            shareReplay(1),
        );
        this.columnDefs$ = this.addresses$.pipe(
            switchMap(addresses => this.getColumnDefs$(addresses.length === 1)),
        );
        this.rolesBelowDealerAdmin$ = this.userRoleService.getRolesBelowDealerAdmin$();
    }

    navigateToCreate(): void {
        this.router.navigateByUrl(`account/${this.accountNumber}/delivery-address/create`);
    }

    private getColumnDefs$(
        isOnlyOneAddress: boolean,
    ): Observable<Array<TableColumnDef<DeliveryAddressDTO>>> {
        const shouldShowActions$ = this.userRoleService.userHasHigherOrEqualRoleThanDealerAdmin$();
        return shouldShowActions$.pipe(
            map(shouldShowActions => {
                const columnDefs = [
                    {
                        columnDef: 'icon',
                        headerCellDef: '',
                        isIconColumn: true,
                        cellDef: () =>
                            new IconCellDef(
                                'map-pin',
                                {
                                    isOutlined: true,
                                    isRowIcon: true,
                                },
                            ),
                    },
                    {
                        columnDef: 'address',
                        headerCellDef: '',
                        cellDef: ({ fullName, address }) => {
                            const displayName = isOnlyOneAddress ? "" : `${fullName?.trim()  },`;
                            const { street, postalCode, city, houseNumber } = address;
                            const addressText = `${displayName} ${street} ${houseNumber}, ${postalCode} ${city}`;
                            return new TextCellDef(addressText);
                        },
                    },
                ];
                if (shouldShowActions) {
                    const actionColumn = {
                        columnDef: 'action',
                        headerCellDef: '',
                        isActionColumn: true,
                        cellDef: deliveryAddress => {
                            const editUrl = `account/${this.accountNumber}/delivery-address/edit/${deliveryAddress.shipToPartyId}`;
                            const actions = [
                                {
                                    icon: 'pencil',
                                    onClick: event => {
                                        event.preventDefault();
                                        this.router.navigateByUrl(editUrl);
                                    },
                                    redirectUrl: editUrl,
                                },
                            ];

                            if (!isOnlyOneAddress) {
                                const deleteUrl = `account/${this.accountNumber}/delivery-address/delete/${deliveryAddress.shipToPartyId}`;
                                actions.push({
                                    icon: 'trash',
                                    onClick: event => {
                                        event.preventDefault();
                                        this.router.navigateByUrl(deleteUrl);
                                    },
                                    redirectUrl: deleteUrl,
                                });
                            }

                            return new ActionCellDef(actions);
                        },
                    };
                    columnDefs.push(actionColumn);
                }
                return columnDefs;
            }),
        );
    }
}
