<shared-dialog-frame title="profile.changeEmail">
    <form class="form" [formGroup]="formGroup" (keydown.enter)="save()">
        <mat-form-field appearance="standard" fxFill>
            <mat-label>{{ 'changeEmail.email' | translate }}</mat-label>
            <input formControlName="email" matInput cdkFocusInitial/>
            <mat-error *ngIf="formGroup.get('email').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('pattern')">
                {{ 'changeEmail.invalidEmailError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" fxFill>
            <mat-label>{{ 'changeEmail.confirmEmail' | translate }}</mat-label>
            <input formControlName="emailConfirmation" matInput />
            <mat-error *ngIf="formGroup.get('emailConfirmation').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('emailConfirmation').hasError('pattern')">
                {{ 'changeEmail.invalidEmailError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('emailConfirmation').hasError('match')">
                {{ 'changeEmail.matchError' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
    <ng-container actions>
        <button mat-flat-button color="primary" class="primary-button" (click)="save()">
            {{ 'changeEmail.save' | translate }}
        </button>
    </ng-container>
</shared-dialog-frame>
