<shared-confirmation icon="wall-clock">
    <ng-container header>
        <h1>{{ 'delivery-address-confirmation.title' | translate }}</h1>
    </ng-container>
    <ng-container body>
        <div>{{ confirmationMessageTranslationKey$ | async | translate }}</div>
    </ng-container>
    <ng-container actions *ngIf="accountNumber$ | async as accountNumber">
        <button
            mat-stroked-button
            color="secondary"
            class="secondary-button"
            (click)="onNavigateToAccount(accountNumber)">
            {{ 'delivery-address.backToAddresses' | translate }}
        </button>
    </ng-container>
</shared-confirmation>
