<app-content-card>
    <form class="form content-block" fxLayout="column">
        <h1>{{ 'changePassword.title' | translate }}</h1>

        <h3>{{ 'changePassword.oldPassword' | translate }}</h3>
        <mat-form-field fxFlex="auto" appearance="standard">
            <mat-label>{{ 'changePassword.oldPassword' | translate }}</mat-label>
            <shared-icon
                matPrefix
                class="icon-prefix-padding"
                [fontSet]="'material-icons-outlined'"
                icon="lock"></shared-icon>
            <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="isOldPasswordVisible = !isOldPasswordVisible">
                <shared-icon [icon]="isOldPasswordVisible ? 'eye-off' : 'eye'">
                </shared-icon>
            </button>
            <input
                matInput
                type="password"
                [formControl]="oldPasswordControl"
                cdkFocusInitial
                [type]="isOldPasswordVisible ? 'text' : 'password'" />
            <mat-error *ngIf="oldPasswordControl.hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>

        <h3>{{ 'changePassword.newPassword' | translate }}</h3>
        <app-password-change-form (newPassword)="setNewPassword($event)"></app-password-change-form>
        <div fxFlex="auto">
            <div class="textlink" (click)="cancel()">
                {{ 'cancel' | translate }}
            </div>
        </div>
    </form>
</app-content-card>
