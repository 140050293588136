import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MyStobagSubheaderService, PageNameEvent } from '@stobag/mystobag-header';
import { AnalyticsService, ConfigService } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        analyticsService: AnalyticsService,
        private subheaderService: MyStobagSubheaderService,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private dateAdapter: DateAdapter<never>, // Must inject dateAdapter here in order to create the service once
        @Inject(DOCUMENT) private document: Document,
        private configService: ConfigService,
    ) {
        this.subheaderService.updatePageNameByEvent({ pageTitle: 'login.title' } as PageNameEvent);
        analyticsService.init();

        if (this.configService.getConfig().headerV2) {
            this.document.body.classList.add('v2');
        }
    }

    ngOnInit() {
        this.configureCustomMatIcons();
    }

    private configureCustomMatIcons() {
        const staticIconsUrl = '/static/icons/';
        this.addCustomIconToRegistry('ghost', staticIconsUrl);
        this.addCustomIconToRegistry('bronze-partner');
        this.addCustomIconToRegistry('silver-partner');
        this.addCustomIconToRegistry('gold-partner');
    }

    private addCustomIconToRegistry(iconName: string, baseIconUrl = '/authentication/assets/img/') {
        const safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
            `${baseIconUrl}${iconName}.svg`,
        );
        this.matIconRegistry.addSvgIcon(iconName, safeResourceUrl);
    }
}
