import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { NgxDropzoneComponent } from 'ngx-dropzone';
import { AccountService } from 'src/app/services/account.service';

export interface AccountLogoDialogData {
    accountNumber: string;
}

@Component({
    selector: 'app-account-logo-dialog',
    templateUrl: './account-logo-dialog.component.html',
    styleUrls: ['./account-logo-dialog.component.scss'],
})
export class AccountLogoDialogComponent {
    private dropZone: NgxDropzoneComponent;

    @ViewChild('drop') set drop(drop: NgxDropzoneComponent) {
        this.dropZone = drop;
        if (this.dropZone) {
            this.dropZone.showFileSelector = () => {
                // do nothing
            };
        }
    }

    acceptedFileTypes = 'image/jpeg,image/png,image/bmp';
    accountLogoUrl: string;
    readonly logoExtension = '.png';
    logoExists = true;

    optimalWidth = 500;
    optimalHeight = 150;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: AccountLogoDialogData,
        private dialogRef: MatDialogRef<unknown>,
        private configService: ConfigService,
        private accountService: AccountService,
        private dialogService: DialogService,
        private translate: TranslateService,
    ) {
        const bucketName = this.configService.getConfig().logoBucketName;
        this.accountLogoUrl = `https://${bucketName}.s3.eu-central-1.amazonaws.com/${data.accountNumber}${this.logoExtension}`;
    }

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        (this.dropZone._fileInput.nativeElement as HTMLInputElement).click();
    }

    onSelect(event) {
        const image = event.addedFiles[0];
        if (image) {
            this.dialogRef.close(image);
        } else {
            const message = this.translate.instant('account-logo-dialog.userError');
            this.dialogService.openSnackbar(message, SnackbarType.ERROR, 7);
        }
    }
}
