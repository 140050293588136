<shared-dialog-frame title="profile.changePassword">
    <form
        class="form"
        [formGroup]="formGroup"
        fxLayout="column"
        fxLayoutAlign="space-evenly center"
        (keydown.enter)="onChangePassword()">
        <mat-form-field appearance="standard" fxFill>
            <mat-label>{{ 'changePassword.oldPassword' | translate }}</mat-label>
            <shared-icon matPrefix class="icon-prefix-padding" icon="lock"></shared-icon>
            <button
                mat-icon-button
                matSuffix
                type="button"
                class="inline-icon"
                (click)="isOldPasswordVisible = !isOldPasswordVisible">
                <shared-icon [icon]="isOldPasswordVisible ? 'eye-off' : 'eye'"></shared-icon>
            </button>
            <shared-icon
                matSuffix
                class="inline-icon"
                [icon]="
                    formGroup.controls['oldPassword'].hasError('required')
                        ? 'circle-x'
                        : 'circle-check'
                ">
            </shared-icon>
            <input
                matInput
                type="password"
                formControlName="oldPassword"
                cdkFocusInitial
                [type]="isOldPasswordVisible ? 'text' : 'password'" />
            <mat-error *ngIf="formGroup.get('oldPassword').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" fxFill>
            <mat-label>{{ 'changePassword.newPassword' | translate }}</mat-label>
            <shared-icon matPrefix class="icon-prefix-padding" icon="lock"></shared-icon>
            <button
                mat-icon-button
                matSuffix
                type="button"
                class="inline-icon"
                (click)="isNewPasswordVisible = !isNewPasswordVisible">
                <shared-icon [icon]="isNewPasswordVisible ? 'eye-off' : 'eye'"></shared-icon>
            </button>
            <shared-icon
                matSuffix
                sharedTooltipTrigger
                class="inline-icon"
                [icon]="
                    formGroup.controls['newPassword'].hasError('required') ||
                    formGroup.controls['newPassword'].hasError('pattern')
                        ? 'circle-x'
                        : 'circle-check'
                ">
                <shared-tooltip>
                    <div class="password-tooltip">
                        <div>{{ 'changePassword.passwordRequirements.length' | translate }}</div>
                        <div>
                            {{
                                'changePassword.passwordRequirements.smallCaseCharacter' | translate
                            }}
                        </div>
                        <div>
                            {{ 'changePassword.passwordRequirements.capitalCharacter' | translate }}
                        </div>
                        <div>
                            {{ 'changePassword.passwordRequirements.specialCharacter' | translate }}
                        </div>
                        <div>{{ 'changePassword.passwordRequirements.number' | translate }}</div>
                    </div>
                </shared-tooltip>
            </shared-icon>
            <input
                matInput
                type="password"
                formControlName="newPassword"
                [type]="isNewPasswordVisible ? 'text' : 'password'" />
            <mat-error *ngIf="formGroup.get('newPassword').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" fxFill>
            <mat-label>{{ 'changePassword.newPasswordAgain' | translate }}</mat-label>
            <shared-icon matPrefix class="icon-prefix-padding" icon="lock"></shared-icon>
            <button
                mat-icon-button
                matSuffix
                type="button"
                class="inline-icon"
                (click)="isNewPasswordAgainVisible = !isNewPasswordAgainVisible">
                <shared-icon [icon]="isNewPasswordAgainVisible ? 'eye-off' : 'eye'"></shared-icon>
            </button>
            <shared-icon
                matSuffix
                class="inline-icon"
                [icon]="
                    formGroup.controls['oldPassword'].hasError('required') ||
                    !twoPasswordFieldsMatch()
                        ? 'circle-x'
                        : 'circle-check'
                "></shared-icon>
            <input
                matInput
                type="password"
                formControlName="newPasswordAgain"
                [type]="isNewPasswordAgainVisible ? 'text' : 'password'" />
            <mat-error *ngIf="formGroup.get('newPasswordAgain').hasError('required')">
                {{ 'shared.stateChangeMessages.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
    <div actions>
        <button mat-flat-button class="primary-button" (click)="onChangePassword()">
            {{ 'changePassword.changePassword' | translate }}
        </button>
    </div>
</shared-dialog-frame>
