import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@stobag/mystobag-shared';
import { NgxMatIntlTelInputComponent } from '@stobag/shared-mat-intl-tel-input';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { UserService } from '../../../services/user.service';

export interface SelectOption {
    code: string;
    description: string;
}

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, AfterViewInit {
    @Input() userForm: FormGroup;

    @ViewChildren(NgxMatIntlTelInputComponent) phoneInputs: QueryList<NgxMatIntlTelInputComponent>;

    readonly TITLE_CODES = ['0001', '0002', 'Z003'];
    readonly preferredCountries = ['ch', 'fr', 'it', 'nl'];

    tokenChange$: Observable<string>;
    userFunctions$: Observable<SelectOption[]>;
    departments$: Observable<SelectOption[]>;
    contactLanguages$: Observable<SelectOption[]>;

    isContactProfile: boolean;

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
    ) {
        this.isContactProfile = Boolean(this.activatedRoute.snapshot.paramMap.get('contactId'));
    }

    ngOnInit(): void {
        this.tokenChange$ = this.authenticationService
            .getRefreshTokenObservable()
            .pipe(startWith(null));

        this.userFunctions$ = this.tokenChange$.pipe(
            switchMap(() => this.userService.getUserFunctions()),
        );
        this.departments$ = this.tokenChange$.pipe(
            switchMap(() => this.userService.getDepartments()),
        );
        this.contactLanguages$ = this.tokenChange$.pipe(
            switchMap(() => this.userService.getContactLanguages()),
        );
    }

    ngAfterViewInit() {
        // panelClass is converted to generic ng-tns class, so we have to manually add our own class
        // More info: https://github.com/tanansatpal/ngx-mat-intl-tel-input/pull/65
        this.phoneInputs.forEach(
            phoneInput => (phoneInput.matMenu.panelClass = 'ngx-mat-tel-input-overlay-pane'),
        );
    }
}
