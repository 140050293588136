import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { UserRoleService } from '../../../../services/user-role.service';

@Component({
    selector: 'app-vacation',
    templateUrl: './vacation.component.html',
    styles: [
        `
            .create-vacation-icon {
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 24px;
            }
        `,
    ],
})
export class VacationComponent implements OnInit {
    addEventSubject = new Subject<void>();
    rolesBelowDealerAdmin$: Observable<string[]>;

    constructor(private userRoleService: UserRoleService) {}

    onAddVacation() {
        this.addEventSubject.next();
    }

    ngOnInit(): void {
        this.rolesBelowDealerAdmin$ = this.userRoleService.getRolesBelowDealerAdmin$();
    }
}
