<app-content-card>
    <form class="content-block" [formGroup]="passwordResetForm" fxLayout="column">
        <h1>{{ 'passwordResetRequest.title' | translate }}</h1>
        <p>{{ 'passwordResetRequest.description' | translate }}</p>
        <div fxLayout="column">
            <mat-form-field fxFlex="auto" appearance="standard">
                <mat-label>{{ 'passwordResetRequest.email' | translate }}</mat-label>
                <shared-icon
                    matPrefix
                    class="icon-prefix-padding"
                    [fontSet]="'material-icons-outlined'"
                    icon="user"></shared-icon>
                <input
                    matInput
                    id="input-username"
                    type="text"
                    size="30"
                    formControlName="username"
                    [placeholder]="'passwordResetRequest.email' | translate" />
                <mat-error *ngIf="passwordResetForm.get('username').invalid">
                    {{ 'shared.stateChangeMessages.requiredError' | translate }}
                </mat-error>
            </mat-form-field>
            <div fxFlex="auto" fxLayoutAlign="flex-end">
                <button
                    mat-flat-button
                    data-cy="resetPasswordButton"
                    class="primary-button submit-button"
                    (click)="requestConfirmationCode()">
                    {{ 'passwordResetRequest.resetPassword' | translate }}
                </button>
            </div>
        </div>
        <div fxFlex="auto">
            <div class="textlink" (click)="cancel()">{{ 'cancel' | translate }}</div>
        </div>
    </form>
</app-content-card>
