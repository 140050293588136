import { Injectable } from '@angular/core';
import { Permission } from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, map, Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { UserService } from './user.service';

@Injectable()
export class UserRoleService {
    constructor(
        private userService: UserService,
        private ngxPermissionsService: NgxPermissionsService,
    ) {}

    getRolesBelowDealerAdmin$(): Observable<string[]> {
        return this.userService.getRolesBelow(Permission.DealerAdmin).pipe(shareReplay(1));
    }

    userHasHigherOrEqualRoleThanDealerAdmin$(): Observable<boolean> {
        return this.getRolesBelowDealerAdmin$().pipe(
            switchMap(rolesBelowDealerAdmin =>
                from(this.ngxPermissionsService.hasPermission(rolesBelowDealerAdmin)).pipe(
                    map(res => !res),
                ),
            ),
        );
    }
}
