export class Authentication {
    constructor(
        private username: string,
        private password: string,
        private newPassword?: string,
        private forcePasswordChangeSessionId?: string,
    ) {}

    setNewPassword(newPassword: string) {
        this.newPassword = newPassword;
    }

    setForcePasswordChangeSessionId(sessionId: string) {
        this.forcePasswordChangeSessionId = sessionId;
    }
}
