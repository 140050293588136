export enum Role {
    ROLE_STOBAG_SUPERADMIN = 'ROLE_STOBAG_SUPERADMIN',
    ROLE_STOBAG_ADMIN = 'ROLE_STOBAG_ADMIN',
    ROLE_STOBAG_USER = 'ROLE_STOBAG_USER',
    ROLE_DEALER_ADMIN = 'ROLE_DEALER_ADMIN',
    ROLE_DEALER_USER = 'ROLE_DEALER_USER',
    ROLE_DEALER_TECHNICAL = 'ROLE_DEALER_TECHNICAL',
    ROLE_DEALER_MARKETING = 'ROLE_DEALER_MARKETING',
    ROLE_DEALER_BDEALER = 'ROLE_DEALER_BDEALER',
    ROLE_DEALER_SALES = 'ROLE_DEALER_SALES',
    ROLE_SYSTEM = 'ROLE_SYSTEM',
    ROLE_SYSTEM_KLAIBER = 'ROLE_SYSTEM_KLAIBER',
    ROLE_SYSTEM_SAP = 'ROLE_SYSTEM_SAP',
}
