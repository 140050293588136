<form *ngIf="userForm" [formGroup]="userForm" class="form">
    <mat-form-field id="titleCode">
        <mat-label>{{ 'profile.titleCode' | translate }}</mat-label>
        <mat-select
            panelClass="drop-down-filters"
            disableOptionCentering
            formControlName="titleCode"
            data-cy="title-select">
            <mat-option *ngFor="let titleCode of TITLE_CODES" [value]="titleCode">{{
                'titleCodes.' + titleCode | translate
            }}</mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('titleCode').hasError('required')">
            {{ 'shared.stateChangeMessages.requiredError' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field id="firstname">
        <mat-label>{{ 'profile.firstname' | translate }}</mat-label>
        <input matInput type="text" formControlName="firstName" data-cy="firstname-input" />
        <mat-error *ngIf="userForm.get('firstName').hasError('required')">
            {{ 'shared.stateChangeMessages.requiredError' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field id="lastname">
        <mat-label>{{ 'profile.lastname' | translate }}</mat-label>
        <input matInput type="text" formControlName="lastName" data-cy="lastname-input" />
        <mat-error *ngIf="userForm.get('lastName').hasError('required')">
            {{ 'shared.stateChangeMessages.requiredError' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field id="contactLanguage">
        <mat-label>{{ 'profile.communication' | translate }}</mat-label>
        <mat-select
            panelClass="drop-down-filters"
            disableOptionCentering
            formControlName="contactLanguage"
            data-cy="contact-language-select">
            <mat-option
                *ngFor="let contactLanguage of contactLanguages$ | async"
                [value]="contactLanguage.code">
                {{ contactLanguage.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field id="phone">
        <mat-label>{{ 'profile.phone' | translate }}</mat-label>
        <shared-mat-intl-tel-input
            [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            format="international"
            name="phone"
            describedBy="phoneInput"
            formControlName="phone"
            data-cy="phone-input"
            #phone></shared-mat-intl-tel-input>
        <mat-hint>e.g. {{ phone.selectedCountry.placeHolder }}</mat-hint>
        <mat-error *ngIf="userForm.get('phone').errors?.validatePhoneNumber">
            {{ 'profile.phoneFormatMatchError' | translate }}
            {{ phone.selectedCountry.placeHolder }}
        </mat-error>
    </mat-form-field>

    <mat-form-field id="mobile">
        <mat-label>{{ 'profile.mobilePhone' | translate }}</mat-label>
        <shared-mat-intl-tel-input
            [preferredCountries]="preferredCountries"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            format="international"
            name="mobile"
            describedBy="phoneInput"
            formControlName="mobile"
            data-cy="mobile-input"
            #mobile></shared-mat-intl-tel-input>
        <mat-hint>e.g. {{ mobile.selectedCountry.placeHolder }}</mat-hint>
        <mat-error *ngIf="userForm.get('mobile').errors?.validatePhoneNumber">
            {{ 'profile.phoneFormatMatchError' | translate }} +41781234567
        </mat-error>
    </mat-form-field>

    <mat-form-field id="function">
        <mat-label>{{ 'profile.function' | translate }}</mat-label>
        <mat-select
            panelClass="drop-down-filters"
            disableOptionCentering
            formControlName="functionCode"
            data-cy="function-select">
            <mat-option
                *ngFor="let userFunction of userFunctions$ | async"
                [value]="userFunction.code"
                >{{ userFunction.description }}</mat-option
            >
        </mat-select>
    </mat-form-field>

    <mat-form-field id="department">
        <mat-label>{{ 'profile.department' | translate }}</mat-label>
        <mat-select
            panelClass="drop-down-filters"
            disableOptionCentering
            formControlName="departmentCode"
            data-cy="department-select">
            <mat-option *ngFor="let department of departments$ | async" [value]="department.code">{{
                department.description
            }}</mat-option>
        </mat-select>
    </mat-form-field>
</form>
