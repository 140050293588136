import { HttpBackend } from '@angular/common/http';
import {
    i18nMyStobagBasketSharedDE,
    i18nMyStobagBasketSharedEN,
    i18nMyStobagBasketSharedFR,
    i18nMyStobagBasketSharedIT,
    i18nMyStobagBasketSharedNL,
} from '@stobag/mystobag-basket-shared';
import {
    i18nMyStobagHeaderDE,
    i18nMyStobagHeaderEN,
    i18nMyStobagHeaderFR,
    i18nMyStobagHeaderIT,
    i18nMyStobagHeaderNL,
} from '@stobag/mystobag-header';
import {
    ConfigService,
    CustomTranslateLoader,
    i18nMyStobagSharedDE,
    i18nMyStobagSharedEN,
    i18nMyStobagSharedFR,
    i18nMyStobagSharedIT,
    i18nMyStobagSharedNL,
    LanguageObjects,
} from '@stobag/mystobag-shared';

const languageObjects: LanguageObjects = {
    en: [i18nMyStobagHeaderEN, i18nMyStobagSharedEN, i18nMyStobagBasketSharedEN],
    de: [i18nMyStobagHeaderDE, i18nMyStobagSharedDE, i18nMyStobagBasketSharedDE],
    it: [i18nMyStobagHeaderIT, i18nMyStobagSharedIT, i18nMyStobagBasketSharedIT],
    fr: [i18nMyStobagHeaderFR, i18nMyStobagSharedFR, i18nMyStobagBasketSharedFR],
    nl: [i18nMyStobagHeaderNL, i18nMyStobagSharedNL, i18nMyStobagBasketSharedNL],
};

export function translateLoaderFactory(http: HttpBackend, config: ConfigService) {
    return new CustomTranslateLoader(http, config, languageObjects);
}
