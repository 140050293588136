import { Component } from '@angular/core';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
    clickCounter = 0;

    stobagLogin() {
        if (this.clickCounter++ == 8) {
            window.location.href = '/authentication/stobag-login';
        }
    }
}
