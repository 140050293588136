<mat-form-field id="userRole" ngxUiLoaderBlurred loaderId="role-loader" class="role-selector">
    <mat-label>{{ 'profile.role' | translate }}</mat-label>
    <mat-select panelClass="drop-down-filters" disableOptionCentering [formControl]="roleControl">
        <mat-option *ngFor="let role of allowedRoles" [value]="role">{{
            'profile.' + role | translate
        }}</mat-option>
    </mat-select>
    <mat-hint fxLayout="row" fxLayoutAlign="start center">
        <app-user-role-info-hint></app-user-role-info-hint>
    </mat-hint>
    <mat-error *ngIf="roleControl.hasError('required')">
        <app-user-role-info-hint [hasError]="true"></app-user-role-info-hint>
    </mat-error>
</mat-form-field>
