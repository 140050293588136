import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BrowserCheckService,
    ConfigService,
    DialogService,
    MyStobagRedirectService,
    serviceUrls,
    SnackbarType,
} from '@stobag/mystobag-shared';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { Authentication } from '../../../models/authentication/authentication';
import { TokenService } from '../../../services/token.service';
import { LoginVideoConfig } from './video-player/video-player.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    showError = false;
    errorMessage: string;
    returnUrl: string;
    isPasswordVisible = false;
    loginVideoConfig: LoginVideoConfig;
    maintenanceModeOn = false;
    maintenanceUntil: Date;

    private subscription = new Subscription();

    constructor(
        public dialog: MatDialog,
        public translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private redirectService: MyStobagRedirectService,
        private tokenService: TokenService,
        configService: ConfigService,
        private cookieService: CookieService,
        private dialogService: DialogService,
        private browserCheckService: BrowserCheckService,
    ) {
        this.handleCookieToken();
        this.loginForm = new FormGroup({
            username: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
        });

        const nullObject = { enabled: false, urls: [] } as LoginVideoConfig;
        this.loginVideoConfig = configService.getConfig().loginVideo
            ? configService.getConfig().loginVideo
            : nullObject;
        this.maintenanceModeOn = configService.getConfig().maintenanceModeOn;
        this.maintenanceUntil = configService.getConfig().maintenanceModeUntil;
    }

    ngOnInit(): void {
        this.deleteOldTokens();
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (!this.browserCheckService.isBrowserSupported()) {
            this.translate
                .get('unsupportedBrowserMessage', {
                    browsers: this.browserCheckService.supportedBrowsers,
                })
                .subscribe(message =>
                    this.dialogService.openSnackbar(message, SnackbarType.WARNING, 20),
                );
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    onLoginClick() {
        if (this.loginForm.invalid) {
            return;
        }

        const username: string = this.loginForm.get('username').value.trim().replace(/\s/g, '');
        const auth = new Authentication(username, this.loginForm.get('password').value);
        this.subscription.add(
            this.tokenService
                .getJwtToken(auth)
                .pipe(
                    tap(res => {
                        const forcePasswordChange = res.headers.get('Force-Password-Change');
                        if (!forcePasswordChange) {
                            this.handleToken(res.headers.get('Authorization'));
                        }
                    }),
                    filter(res => {
                        const forcePasswordChange = res.headers.get('Force-Password-Change');
                        return Boolean(forcePasswordChange); // is the user forced to change password?
                    }),
                    switchMap(res => {
                        const forcePasswordChangeSessionId = res.headers.get(
                            'Force-Password-Change-Session-Id',
                        );
                        return this.router.navigateByUrl(
                            `/password-change/${username}/${forcePasswordChangeSessionId}${
                                this.returnUrl ? `?returnUrl=${this.returnUrl}` : ''
                            }`,
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.showError = true;
                        if (error.status === 401) {
                            this.errorMessage = 'invalidCredentials';
                        } else if (error.status === 403) {
                            this.errorMessage = 'disabledAccount';
                        } else {
                            this.errorMessage = error.error.message;
                        }
                        return throwError(error);
                    }),
                )
                .subscribe(),
        );
    }

    navigateToPasswordReset(): void {
        this.router.navigate(['password-reset']);
    }

    private handleCookieToken(): void {
        this.deleteOldTokens();
        const accessToken = this.cookieService.get('accessToken');
        if (accessToken) {
            localStorage.setItem('token', `Bearer ${accessToken}`);
            this.redirectService.navigateToService(serviceUrls.dashboard);
        }
    }

    private deleteOldTokens(): void {
        this.cookieService.delete('token', '/', '.stobag.com', true);
        this.cookieService.delete('accessToken', '/', '.stobag.com', true);
        this.cookieService.delete('refreshToken', '/authentication', '.stobag.com', true);
        this.tokenService.deleteOldCookies().subscribe();
    }

    private handleToken(token: string) {
        if (token) {
            localStorage.setItem('token', token);
            if (this.returnUrl) {
                this.redirectService.navigateToExternalUrl(this.returnUrl);
            } else {
                this.redirectService.navigateToService(serviceUrls.dashboard);
            }
        } else {
            this.showError = true;
        }
    }
}
