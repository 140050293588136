import { Component } from '@angular/core';

export enum DeliveryType {
    Truck = 'truck',
    TruckAndPackage = 'truckAndPackage',
    Package = 'package',
}

@Component({
    selector: 'app-delivery-address',
    template: `
        <shared-subheader [secondary]="true"></shared-subheader>
        <div class="content-container">
            <mat-card>
                <router-outlet></router-outlet>
            </mat-card>
        </div>
    `,
    styles: [
        `
            .mat-card {
                margin-bottom: 5rem;
            }
        `,
    ],
})
export class DeliveryAddressComponent {}
