<mat-card *ngIf="isNotOwnProfile">
    <h3>{{ 'user-deletion.title' | translate }}</h3>
    <div class="user-deletion-subtitle">
        {{ 'user-deletion.subtitle' | translate }}
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end flex-end" fxLayoutGap="1.5rem">
        <button
            mat-flat-button
            class="delete-button"
            (click)="deleteUser()"
            data-cy="delete-user-button">
            {{
                (isContactUser ? 'user-deletion.deleteContact' : 'user-deletion.deleteUser')
                    | translate
            }}
        </button>
    </div>
</mat-card>
