import { Component } from '@angular/core';
import { ConfigService } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-user-role-info-dialog',
    templateUrl: './user-role-info-dialog.component.html',
    styleUrls: ['./user-role-info-dialog.component.scss'],
})
export class UserRoleInfoDialogComponent {
    bDealerEnabled = false;
    salesRoleEnabled = false;

    constructor(configService: ConfigService) {
        this.bDealerEnabled = configService.getConfig().bDealerEnabled === true;
        this.salesRoleEnabled = configService.getConfig().salesRoleEnabled === true;
    }
}
