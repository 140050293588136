<mat-card *ngIf="vacation">
    <div class="vacation-content-container">
        <div class="vacation-detail-container">
            <div class="vacation-detail-date vacation-detail-pair">
                <div class="vacation-detail-label">{{ 'vacation-list.date' | translate }}</div>
                <div class="vacation-detail-value">
                    {{
                        (vacation.startDate | sharedDatePipe) +
                            ' - ' +
                            (vacation.endDate | sharedDatePipe)
                    }}
                </div>
            </div>

            <div class="vacation-detail-duration vacation-detail-pair">
                <div class="vacation-detail-label">{{ 'vacation-list.duration' | translate }}</div>
                <div class="vacation-detail-value">
                    {{ vacation.durationInDays }} {{ 'vacation-list.days' | translate }}
                </div>
            </div>

            <div
                class="vacation-detail-affected-addresses vacation-detail-pair"
                *ngIf="hasMultipleDeliveryAddresses">
                <div class="vacation-detail-label">
                    {{
                        (vacation.affectedAddresses?.length > 1
                            ? 'vacation-list.affectedAddresses'
                            : 'vacation-list.affectedAddress'
                        ) | translate
                    }}
                </div>
                <div class="vacation-detail-value">
                    <div *ngFor="let affectedAddress of vacation.affectedAddresses">
                        {{ affectedAddress }}
                    </div>
                </div>
            </div>
        </div>
        <div class="vacation-action" *ngxPermissionsExcept="rolesBelowDealerAdmin$ | async">
            <button
                mat-button
                type="button"
                (click)="deleteVacation()"
                data-cy="delete-vacation-button">
                <shared-icon
                    [fontSet]="'material-icons-outlined'"
                    icon="trash"></shared-icon>
            </button>
        </div>
    </div>
</mat-card>
