import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { ConfigService, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { AccountService } from '../../../../../services/account.service';
import { DeliveryType } from '../delivery-address.component';

@Component({
    selector: 'app-delivery-address-create',
    templateUrl: './delivery-address-edit.component.html',
    styleUrls: ['./delivery-address-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryAddressEditComponent implements OnInit, OnDestroy {
    DeliveryType = DeliveryType;

    accountNumber$ = this.activatedRoute.parent.paramMap.pipe(
        map(paramMap => paramMap.get('accountNumber')),
    );
    shipToPartyId$ = this.activatedRoute.params.pipe(map(({ shipToPartyId }) => shipToPartyId));
    oldDeliveryAddress$ = this.accountNumber$.pipe(
        withLatestFrom(this.activatedRoute.params.pipe(map(({ shipToPartyId }) => shipToPartyId))),
        switchMap(([accountNumber, shipToPartyId]) =>
            this.accountService.getOneDeliveryAddress(accountNumber, shipToPartyId),
        ),
        shareReplay(1),
    );
    formGroup$: Observable<FormGroup> = this.oldDeliveryAddress$.pipe(
        map(oldDeliveryAddress => {
            const formGroup = new FormGroup({
                shipToPartyId: new FormControl(
                    { value: oldDeliveryAddress.shipToPartyId, disabled: true },
                    [Validators.required],
                ),
                companyName: new FormControl({ value: oldDeliveryAddress.name, disabled: true }, [
                    Validators.required,
                ]),
                streetAndNumber: new FormControl(null, [Validators.required]),
                postalCodeAndCity: new FormControl(null, [Validators.required]),
                phone: new FormControl(null, [Validators.required]),
                type: new FormControl(null, [Validators.required]),
                comment: new FormControl(),
            });
            if (this.configService.getConfig().bDealerEnabled === true) {
                formGroup.addControl(
                    'specialShipToPartyType',
                    new FormControl(oldDeliveryAddress.specialShipToPartyType, [
                        Validators.required,
                    ]),
                );
            }
            return formGroup;
        }),
        shareReplay(1),
    );

    private subscription = new Subscription();

    constructor(
        private myStobagSubheaderService: MyStobagSubheaderService,
        private activatedRoute: ActivatedRoute,
        private translationService: TranslateService,
        private router: Router,
        private accountService: AccountService,
        private dialogService: DialogService,
        private configService: ConfigService,
    ) {}

    ngOnInit() {
        const pageName = this.translationService.instant('delivery-address.editTitle');
        const pageDescription = this.translationService.instant('delivery-address.editDescription');
        this.myStobagSubheaderService.updatePageName(pageName, null, null, pageDescription);
        this.subscription.add(
            this.accountNumber$.subscribe({
                next: accountNumber =>
                    this.myStobagSubheaderService.showBackButton(
                        'shared.companySettings',
                        `/account/${accountNumber}/delivery-address-list`,
                        false,
                    ),
            }),
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    cancel() {
        this.accountNumber$
            .pipe(take(1))
            .subscribe(accountNumber =>
                this.router.navigateByUrl(`account/${accountNumber}/delivery-address-list`),
            );
    }

    save() {
        this.formGroup$
            .pipe(
                take(1),
                filter(formGroup => this.checkFormGroupValidity(formGroup)),
                map(formGroup => formGroup.getRawValue()),
                withLatestFrom(this.accountNumber$, this.oldDeliveryAddress$),
                switchMap(([request, accountNumber, oldDeliveryAddress]) =>
                    this.accountService.editDeliveryAddress(
                        accountNumber,
                        oldDeliveryAddress.shipToPartyId,
                        request,
                    ),
                ),
            )
            .subscribe({
                next: () => this.navigateToConfirmationPage(),
                error: () => {
                    const msg = this.translationService.instant('delivery-address.creationError');
                    this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                },
            });
    }

    private navigateToConfirmationPage() {
        this.accountNumber$
            .pipe(take(1))
            .subscribe(accountNumber =>
                window.location.replace(
                    `/account/${accountNumber}/delivery-address/edit-confirmation`,
                ),
            );
    }

    checkFormGroupValidity(formGroup: FormGroup): boolean {
        if (formGroup.invalid) {
            formGroup.markAllAsTouched();
            formGroup.updateValueAndValidity();
            return false;
        }
        return true;
    }
}
