<app-content-card>
	<div class="content-block" fxLayout="column">
		<h1>{{'passwordSet.title' | translate}}</h1>
		<p>{{'passwordSet.description' | translate}}</p>
		<div>
			<button mat-flat-button
					class="primary-button submit-button"
					(click)="backToLogin()">{{ 'backToLogin' | translate }}</button>
		</div>
	</div>
</app-content-card>
