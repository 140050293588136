<form class="form" [formGroup]="formGroup">
    <h3>{{ 'delivery-address.addressData' | translate }}</h3>
    <div class="address-data">
        <mat-form-field>
            <mat-label>{{ 'delivery-address.accountNumber' | translate }}</mat-label>
            <input matInput formControlName="accountNumber" />
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'delivery-address.companyName' | translate }}</mat-label>
            <input matInput formControlName="companyName" />
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="formGroup.get('specialShipToPartyType')">
            <mat-label>{{ 'delivery-address.specialDealerType.label' | translate }}</mat-label>
            <mat-select formControlName="specialShipToPartyType">
                <mat-option value="A_DEALER">{{
                    'delivery-address.specialDealerType.A_DEALER' | translate
                }}</mat-option>
                <mat-option value="B_DEALER">{{
                    'delivery-address.specialDealerType.B_DEALER' | translate
                }}</mat-option>
            </mat-select>
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'delivery-address.streetAndNumber' | translate }}</mat-label>
            <input matInput formControlName="streetAndNumber" />
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'delivery-address.postalCodeAndCity' | translate }}</mat-label>
            <input matInput formControlName="postalCodeAndCity" />
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'delivery-address.phone' | translate }}</mat-label>
            <input matInput formControlName="phone" />
            <mat-error>{{ 'shared.stateChangeMessages.requiredError' | translate }}</mat-error>
        </mat-form-field>
        <!-- TODO remove when b dealer feature flag is removed -->
        <div *ngIf="!formGroup.get('specialShipToPartyType')"></div>
        <div>
            <p>{{ 'delivery-address.addressType' | translate }}</p>
            <mat-radio-group formControlName="type">
                <mat-radio-button [value]="DeliveryType.Truck">
                    {{ 'delivery-address.truck' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="DeliveryType.TruckAndPackage">
                    {{ 'delivery-address.truckAndPackage' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="DeliveryType.Package">
                    {{ 'delivery-address.package' | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="comment">
        <h3>{{ 'delivery-address.comment' | translate }}</h3>
        <mat-form-field fxFill appearance="outline" class="comment-field">
            <textarea
                matInput
                formControlName="comment"
                [placeholder]="'delivery-address.commentPlaceholder' | translate"></textarea>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button color="primary" class="primary-button" (click)="save()">
            {{ 'shared.actions.save' | translate }}
        </button>
    </div>
</form>
