import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
    PasswordResetRequest,
    PasswordResetService,
} from '../../../services/password-reset.service';

@Component({
    selector: 'app-password-reset-request',
    templateUrl: './password-reset-request.component.html',
    styleUrls: ['./password-reset-request.component.scss'],
})
export class PasswordResetRequestComponent implements OnInit {
    passwordResetForm: FormGroup;

    constructor(private router: Router, private passwordResetService: PasswordResetService) {}

    ngOnInit(): void {
        this.passwordResetForm = new FormGroup({
            username: new FormControl('', Validators.required),
        });
    }

    requestConfirmationCode(): void {
        if (this.passwordResetForm.get('username').invalid) {
            return;
        }

        const username = this.passwordResetForm.get('username').value;
        this.passwordResetService
            .requestConfirmationCode({ username } as PasswordResetRequest)
            .subscribe(() => this.router.navigateByUrl(`password-sent?username=${username}`));
    }

    cancel(): void {
        this.router.navigateByUrl('login');
    }
}
