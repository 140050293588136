<shared-search-bar [fieldDefs]="fieldDefs$" (filterChange)="onFilterChange($event)">
	<form [formGroup]="filterForm" class="search-bar-second-row">
		<mat-checkbox formControlName="hideNewAccounts">
			{{'account-list.hideInactiveAccounts' | translate}}
		</mat-checkbox>
		<mat-checkbox formControlName="showOwnDealers" class="showOwnDealers">
			{{'account-list.showManagedDealers' | translate}}
		</mat-checkbox>
	</form>
</shared-search-bar>
