<mat-card *ngIf="subscriptionSettingsForm$ | async as subscriptionSettingsForm">
    <h3>{{ 'profile.notifications' | translate }}</h3>
    <div class="settings-table">
        <form [formGroup]="subscriptionSettingsForm">
            <div class="header row">
                <div class="column">{{ 'profile.notificationSettings.topic' | translate }}</div>
                <div class="column" *ngIf="emailNotificationsEnabled">
                    {{ 'profile.notificationSettings.email' | translate }}
                </div>
                <!-- hidden until DCX-2403 -->
                <div class="column">
                    {{ 'profile.notificationSettings.notification' | translate }}
                </div>
                <div class="column" *ngIf="smsNotificationSettingsEnabled">
                    {{ 'profile.notificationSettings.sms' | translate }}
                </div>
            </div>
            <div class="row" *ngFor="let type of displayedNotificationTypes" [formGroupName]="type">
                <div class="column">{{ 'profile.notificationSettings.' + type | translate }}</div>
                <div class="column action" *ngIf="emailNotificationsEnabled">
                    <mat-checkbox [formControlName]="'subscribedToMail'"></mat-checkbox>
                </div>
                <div class="column action">
                    <mat-checkbox [formControlName]="'subscribedToNotification'"></mat-checkbox>
                </div>
                <div class="column action" *ngIf="smsNotificationSettingsEnabled">
                    <mat-checkbox [formControlName]="'subscribedToSms'"></mat-checkbox>
                </div>
            </div>
        </form>
    </div>
    <div class="profile-settings-action" *ngIf="canEdit">
        <button mat-flat-button class="primary-button" (click)="saveSettings()">
            {{ 'profile.save' | translate }}
        </button>
    </div>
</mat-card>
