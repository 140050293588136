<h1 class="title">{{'delivery-address-delete.title' | translate}}</h1>
<ng-container *ngIf="deliveryAddress$ | async as deliveryAddress">
	<div class="description">{{'delivery-address-delete.description' | translate}}</div>
	<div class="address">
		<h4 class="label-bold">{{'delivery-address-delete.addressData' | translate}}</h4>
		<div class="address-data">
			<span class="label-bold">{{'delivery-address-delete.customerId' | translate}}:</span>
			<span>{{deliveryAddress.shipToPartyId}}</span>

			<span class="label-bold">{{'delivery-address-delete.company' | translate}}:</span>
			<span>{{deliveryAddress.name}}</span>

			<span class="label-bold">{{'delivery-address-delete.streetNumber' | translate}}:</span>
			<span>{{deliveryAddress.address.street}} {{deliveryAddress.address.houseNumber}}</span>

			<span class="label-bold">{{'delivery-address-delete.postalCodeAndCountry' | translate}}:</span>
			<span>{{deliveryAddress.address.postalCode}} {{deliveryAddress.address.country}}</span>

			<span class="label-bold">{{'delivery-address-delete.phone' | translate}}:</span>
			<span>{{deliveryAddress.address.telephoneNumber}}</span>
		</div>
	</div>
	<div class="actions">
		<button class="secondary-button"
				mat-dialog-close
				cdkFocusInitial
				type="button"
				mat-stroked-button
				(click)="navigateBack()">{{ 'shared.actions.cancel' | translate }}</button>
		<button mat-flat-button
				type="button"
				class="primary-button"
				color="warn"
				(click)="requestDeliveryAddressDelete(deliveryAddress.shipToPartyId)">
			{{ 'shared.actions.delete' | translate }}
		</button>
	</div>
</ng-container>
