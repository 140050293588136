import { Component } from '@angular/core';

@Component({
    selector: 'app-vacation-list-standalone-layout',
    template: `<router-outlet></router-outlet>`,
    styles: [
        `
            :host {
                display: block;
                height: 100vh;
                overflow: auto;
                padding: 1.5rem 0;
                background-color: var(--stobag-bg-color);
            }
        `,
    ],
})
export class VacationListStandaloneLayoutComponent {}
