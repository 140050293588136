import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { Authentication } from '../models/authentication/authentication';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    backendUrl = '/authentication/auth';

    constructor(private http: HttpClient, private loadingSpinnerService: LoadingSpinnerService) {}

    getJwtToken(authentication: Authentication): Observable<HttpResponse<unknown>> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<unknown>(this.backendUrl, authentication, { observe: 'response' }),
        );
    }

    deleteOldCookies() {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.delete<unknown>(`/authentication/api/auth/stobag-cookie`, {
                observe: 'response',
            }),
        );
    }
}
