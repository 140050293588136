import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    DialogService,
    Permission,
    SnackbarType,
    STOBAG_PERMISSIONS,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, from, Subscription } from 'rxjs';
import { UserSource } from 'src/app/enums/user-source';
import { UpdateUserRequest } from 'src/app/models/user/update-user-request';
import { ContactService } from 'src/app/services/contact.service';

import { UserDTO } from '../../../../models/user/user-dto';
import { UserService } from '../../../../services/user.service';

@Component({
    selector: 'app-user-details-card',
    templateUrl: './user-details-card.component.html',
    styleUrls: ['./user-details-card.component.scss'],
})
export class UserDetailsCardComponent implements OnInit, OnDestroy {
    UserSource = UserSource;

    @Input() user: UserDTO;
    @Input() isUserReadOnly: boolean;

    userForm: FormGroup;
    hasError = false;
    errorMessage: string;
    canEdit = [...STOBAG_PERMISSIONS, Permission.DealerAdmin];

    private subscription = new Subscription();

    constructor(
        private userService: UserService,
        private contactService: ContactService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private ngxPermissionsService: NgxPermissionsService,
    ) {}

    ngOnInit() {
        this.initForm(this.user, this.isUserReadOnly);
        this.subscription.add(
            from(this.ngxPermissionsService.hasPermission(this.canEdit))
                .pipe(filter(hasPermission => !hasPermission))
                .subscribe(() => {
                    this.userForm.disable();
                }),
        );
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    getAccountFullName(): string | null {
        if (this.user.account) {
            return `${this.user.account.name} ${this.user.account.name2}`;
        }
        return null;
    }

    saveUser(): void {
        if (this.userForm.invalid) {
            this.userForm.markAllAsTouched();
            this.userForm.updateValueAndValidity();
            return;
        }

        const updateUserRequest = this.userForm.getRawValue() as UpdateUserRequest;
        const username = this.user.username;
        const contactObjectId = this.user.contactObjectId;
        const saveUserInPnetOrBoth = username
            ? this.userService.updateUser(username, updateUserRequest)
            : this.contactService.updateContact(contactObjectId, updateUserRequest);
        this.subscription.add(
            saveUserInPnetOrBoth.subscribe(
                () => {
                    const updateSuccessMessage =
                        this.translateService.instant('profile.successUpdate');
                    this.dialogService.openSnackbar(updateSuccessMessage, SnackbarType.SUCCESS, 5);
                },
                (error: HttpErrorResponse) => this.handleError(error),
            ),
        );
    }

    getDealerAccount() {
        return this.user.account
            ? `${this.getAccountFullName()} (${this.user.account.accountNumber})`
            : `${this.getAccountFullName()}`;
    }

    private initForm(user: UserDTO, isFormDisabled = false): void {
        this.userForm = new FormGroup({
            titleCode: new FormControl(
                {
                    value: user.titleCode,
                    disabled: isFormDisabled,
                },
                Validators.required,
            ),
            firstName: new FormControl(
                {
                    value: user.firstName,
                    disabled: isFormDisabled,
                },
                Validators.required,
            ),
            lastName: new FormControl(
                {
                    value: user.lastName,
                    disabled: isFormDisabled,
                },
                Validators.required,
            ),
            contactLanguage: new FormControl({
                value: user.contactLanguage,
                disabled: isFormDisabled,
            }),
            phone: new FormControl({ value: user.phone, disabled: isFormDisabled }),
            mobile: new FormControl({ value: user.mobile, disabled: isFormDisabled }),
            functionCode: new FormControl({ value: user.functionCode, disabled: isFormDisabled }),
            departmentCode: new FormControl({
                value: user.departmentCode,
                disabled: isFormDisabled,
            }),
        });
    }

    private handleError(error: HttpErrorResponse): void {
        const message =
            error.status === 500
                ? this.translateService.instant('profile.serverError')
                : error.error;
        this.dialogService.openSnackbar(message, SnackbarType.ERROR, 5);
    }
}
