<app-account-tab-description>
    <span>{{ 'vacation-list.description' | translate }}</span>
    <button
        mat-flat-button
        class="primary-button"
        (click)="onAddVacation()"
        *ngxPermissionsExcept="rolesBelowDealerAdmin$ | async">
        <shared-icon
            class="create-vacation-icon"
            icon="plus"
            data-cy="add-vacation-button"></shared-icon>
        {{ 'vacation-list.addVacation' | translate }}
    </button>
</app-account-tab-description>
<app-vacation-list [addEvent$]="addEventSubject.asObservable()"></app-vacation-list>
