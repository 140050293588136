<app-account-tab-description>
    <span>{{ 'delivery-address-list.description' | translate }}</span>
    <button *ngxPermissionsExcept="rolesBelowDealerAdmin$ | async" mat-flat-button class="primary-button" (click)="navigateToCreate()">
        <shared-icon
            class="add-delivery-address-icon"
            icon="plus"></shared-icon>
        {{ 'delivery-address-list.addDeliveryAddress' | translate }}
    </button>
</app-account-tab-description>
<shared-table
    [data]="addresses$ | async"
    [columnDefs]="columnDefs$ | async"
    [hideLabels]="true"
    [emptyIcon]="emptyIcon"
    emptyText="delivery-address.noDeliveryAddress"></shared-table>
