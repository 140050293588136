import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator/public-api';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
    AccountDTO,
    AuthenticationService,
    DialogService,
    LoadingSpinnerService,
} from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { AccountFilterRequest } from '../models/account/account-filter-request';
import { AccountSummary } from '../models/account/account-summary';
import { AccountSummaryPagedResponse } from '../models/account/account-summary-paged-response';
import { CreateDeliveryAddressRequest } from '../models/account/create-delivery-address';
import { CreateVacationRequest } from '../models/account/create-vacation-request';
import { DeliveryAddressDTO } from '../models/account/delivery-address-dto';
import { EditDeliveryAddressRequest } from '../models/account/edit-delivery-address';
import { VacationDTO } from '../models/account/vacation-dto';

@Injectable()
export class AccountService {
    backendUrl = `/authentication/api/account`;
    authBackendUrl = `/authentication/api/auth`;

    constructor(
        private http: HttpClient,
        private dialogService: DialogService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
        private loadingSpinnerService: LoadingSpinnerService,
    ) {}

    enableGhostMode(accountName: string, accountNumber: string) {
        const confirmationMessage = this.translate.instant('account.confirmGhost', {
            accountName,
            accountNumber,
        });
        this.dialogService
            .openConfirmationDialog(confirmationMessage)
            .afterClosed()
            .subscribe(confirmation => {
                if (confirmation) {
                    this.authenticationService.enableGhostMode(accountNumber);
                }
            });
    }

    getAllAccountSummary(): Observable<AccountSummary[]> {
        return this.loadingSpinnerService.withLoader(
            this.http.get<AccountSummary[]>(`${this.backendUrl}/withNewStatus`),
            'account-list',
        );
    }

    getFilteredAccountSummaries(
        filterRequest: AccountFilterRequest,
        sort: Sort,
        paging: PageEvent,
    ): Observable<AccountSummaryPagedResponse> {
        let params = new HttpParams();
        params = params.append('page', String(paging?.pageIndex || 0));
        params = params.append('size', String(paging?.pageSize || 20));
        if (sort) {
            params = params.append('sort', `${sort.active  },${  sort.direction}`);
        }

        return this.loadingSpinnerService.withLoader(
            this.http.post<AccountSummaryPagedResponse>(`${this.backendUrl}/filter`, filterRequest, {
                params,
            }),
            'account-list-filter',
        );
    }

    getAllowedPartnerStatuses(): Observable<string[]> {
        return this.http.get<string[]>(`${this.backendUrl}/partner-statuses`);
    }

    getAccount(accountNumber: string): Observable<AccountDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<AccountDTO>(`${this.backendUrl}/${accountNumber}`),
        );
    }

    enableDisableAccount(accountNumber: string): Observable<AccountDTO> {
        return this.http.patch<AccountDTO>(`${this.backendUrl}/change-active-status`, accountNumber);
    }

    getVacationsOfAccount(accountNumber: string): Observable<VacationDTO[]> {
        return this.http.get<VacationDTO[]>(`${this.backendUrl}/${accountNumber}/vacation`);
    }

    addVacations(accountNumber: string, request: CreateVacationRequest): Observable<VacationDTO[]> {
        return this.http.post<VacationDTO[]>(
            `${this.backendUrl}/${accountNumber}/vacation`,
            request,
        );
    }

    removeVacation(accountNumber: string, vacation: VacationDTO): Observable<void> {
        const startDate = new Date(vacation.startDate).toISOString().substring(0, 10);
        const endDate = new Date(vacation.endDate).toISOString().substring(0, 10);
        return this.http.delete<void>(
            `${this.backendUrl}/${accountNumber}/vacation/${startDate}-${endDate}`,
        );
    }

    putLogo(accountNumber: string, image: File): Observable<unknown> {
        const formData = new FormData();
        formData.append('logo', image, image.name);
        const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<unknown>(`${this.backendUrl}/${accountNumber}/logo`, formData, {
                headers,
            }),
        );
    }

    getDeliveryAddresses(accountNumber: string): Observable<DeliveryAddressDTO[]> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<DeliveryAddressDTO[]>(
                `${this.backendUrl}/${accountNumber}/delivery-address`,
            ),
        );
    }

    getOneDeliveryAddress(
        accountNumber: string,
        shipToPartyId: string,
    ): Observable<DeliveryAddressDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<DeliveryAddressDTO>(
                `${this.backendUrl}/${accountNumber}/delivery-address/${shipToPartyId}`,
            ),
        );
    }

    createDeliveryAddress(
        accountNumber: string,
        createDeliveryAddressRequest: CreateDeliveryAddressRequest,
    ): Observable<unknown> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<unknown>(
                `${this.backendUrl}/${accountNumber}/delivery-address`,
                createDeliveryAddressRequest,
            ),
        );
    }

    editDeliveryAddress(
        accountNumber: string,
        shipToPartyId: string,
        editDeliveryAddressRequest: EditDeliveryAddressRequest,
    ): Observable<unknown> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<unknown>(
                `${this.backendUrl}/${accountNumber}/delivery-address/${shipToPartyId}`,
                editDeliveryAddressRequest,
            ),
        );
    }

    deleteDeliveryAddress(accountNumber: string, shipToPartyId: string): Observable<unknown> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.delete<unknown>(
                `${this.backendUrl}/${accountNumber}/delivery-address/${shipToPartyId}`,
            ),
        );
    }

    getAllDistinctCountries(): Observable<string[]> {
        return this.http.get<string[]>(`${this.backendUrl}/countries`);
    }
}
